// react core
import { useContext, useState } from "react";

// material design
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";

// entzy context
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { ActionLoader } from "components/utils/common/CommonLoaders";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell as iconNotifications } from "@fortawesome/pro-solid-svg-icons";

function MemberMessagingSettings(props) {
  const mainContext = useContext(MainContext);
  const roomArea = props.roomArea;
  const roomName = props.roomName;
  const roomId = props.roomId;
  const disabled = props.disabled;

  const [loading, setLoading] = useState(false);
  const [showSettings, setShowSettings] = useState({
    value: false,
    source: "load",
  });

  const handleShowSettings = () => {
    setShowSettings({ value: !showSettings.value, source: "click" });
  };

  const handleMailAlerts = (event) => {
    handleUpdateSettings({
      push: mainContext.state.memberMessaging[roomId].settingsData.push,
      mail: event.target.checked,
      blocked: mainContext.state.memberMessaging[roomId].settingsData.blocked,
      changed: "mail",
    });
  };
  const handlePushAlerts = (event) => {
    handleUpdateSettings({
      push: event.target.checked,
      mail: mainContext.state.memberMessaging[roomId].settingsData.mail,
      blocked: mainContext.state.memberMessaging[roomId].settingsData.blocked,
      changed: "push",
    });
  };
  const handleBlocked = (event) => {
    handleUpdateSettings({
      push: mainContext.state.memberMessaging[roomId].settingsData.push,
      mail: mainContext.state.memberMessaging[roomId].settingsData.mail,
      blocked: event.target.checked,
      changed: "blocked",
    });
  };
  const handleUpdateSettings = async (settings) => {
    setLoading(true);
    const response = await mainContext.prepareUpdateMemberChatSettings({
      RoomArea: roomArea,
      RoomName: roomName,
      Settings: settings,
    });
    if (response.alert) {
      mainContext.updateAlert(response);
    } else {
      mainContext.updateMemberChatSettings({
        roomId: roomId,
        data: response,
      });
    }
    setLoading(false);
  };

  return (
    <Box className="box-default">
      {mainContext.state.memberMessaging[roomId] && (
        <Box className="box-default">
          {mainContext.state.memberMessaging[roomId].settingsHydrated && (
            <Box
              key={
                mainContext.state.memberMessaging[roomId].settingsData.SettingId
              }
              className="box-default nooverflow bg-black"
              sx={{
                mb: configEntzy.APP_SPACING_SM,
                p: configEntzy.APP_SPACING_MD,
                borderRadius: configEntzy.BORDER_SIZE_XL,
                color: "white",
              }}
            >
              {disabled ? (
                <Box className="box-default">
                  <Typography variant="subtitle2" color="divider">
                    <span>
                      <span>Notifications&nbsp;&nbsp;</span>
                      <FontAwesomeIcon icon={iconNotifications} fixedWidth />
                    </span>
                  </Typography>
                </Box>
              ) : (
                <Box
                  className="box-default action-pointer"
                  onClick={handleShowSettings}
                >
                  {mainContext.state.memberMessaging[roomId].settingsData
                    .blocked ? (
                    <Typography variant="subtitle2" color="red">
                      <span>
                        <FontAwesomeIcon icon={iconNotifications} fixedWidth />
                        <span>&nbsp;&nbsp;</span>
                        <span>Blocked</span>
                      </span>
                    </Typography>
                  ) : !mainContext.state.memberMessaging[roomId].settingsData
                      .mail &&
                    !mainContext.state.memberMessaging[roomId].settingsData
                      .push ? (
                    <Typography variant="subtitle2" color="grey">
                      <span>
                        <FontAwesomeIcon icon={iconNotifications} fixedWidth />
                        <span>&nbsp;&nbsp;</span>
                        <span>Notifications (off)</span>
                      </span>
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2" color="secondary">
                      <span>
                        <FontAwesomeIcon icon={iconNotifications} fixedWidth />
                        <span>&nbsp;&nbsp;</span>
                        <span>Notifications (on)</span>
                      </span>
                    </Typography>
                  )}
                </Box>
              )}
              {loading ? (
                <Box className="box-default">
                  <ActionLoader size="sm" />
                </Box>
              ) : (
                <Collapse
                  in={showSettings.value}
                  timeout={{
                    enter: configEntzy.TRANSITIONS.fast,
                    exit: configEntzy.TRANSITIONS.default,
                  }}
                >
                  <Box
                    className="box-default"
                    sx={{ pt: configEntzy.APP_SPACING_MD }}
                  >
                    {!mainContext.state.memberMessaging[roomId].settingsData
                      .blocked && (
                      <Box className="box-default">
                        <Box className="box-default half-width">
                          <FormControlLabel
                            control={
                              <Switch
                                color="highlight"
                                checked={
                                  mainContext.state.memberMessaging[roomId]
                                    .settingsData.push
                                }
                                onChange={handlePushAlerts}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Push"
                            labelPlacement="end"
                          />
                        </Box>
                        <Box className="box-default half-width">
                          <FormControlLabel
                            control={
                              <Switch
                                color="highlight"
                                checked={
                                  mainContext.state.memberMessaging[roomId]
                                    .settingsData.mail
                                }
                                onChange={handleMailAlerts}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Mail"
                            labelPlacement="end"
                          />
                        </Box>
                      </Box>
                    )}
                    <Box
                      className="box-default"
                      sx={{
                        mt: configEntzy.APP_SPACING_MD,
                        pt: configEntzy.APP_SPACING_MD,
                        borderTop: "1px solid #ccc",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            color="highlight"
                            checked={
                              mainContext.state.memberMessaging[roomId]
                                .settingsData.blocked
                            }
                            onChange={handleBlocked}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={
                          mainContext.state.memberMessaging[roomId].settingsData
                            .blocked
                            ? "Member Blocked"
                            : "Block Member"
                        }
                        labelPlacement="end"
                      />
                    </Box>
                  </Box>
                </Collapse>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default MemberMessagingSettings;

// react core
import { Fragment, useContext, useState } from "react";

// material design
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// fonts icons and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose as iconClose } from "@fortawesome/pro-thin-svg-icons";
import { faPlus as iconAdd } from "@fortawesome/pro-regular-svg-icons";

// entzy context and services
import configEntzy from "components/config/ConfigEntzy";
import { EventContext } from "pages/events/EventContext";
import { ActionButton } from "components/utils/common/CommonButtons";
import { TextTag } from "components/utils/common/CommonTags";
import { countriesGetItem } from "models/Country";

// entzy actions
import ActionCoreInfo from "./ActionCoreInfo";
import ActionLaunchpadRunner from "./ActionLaunchpadRunner";
import ActionSharingRider from "./ActionSharingRider";
import ActionSharingRunner from "./ActionSharingRunner";
import ActionDatesRider from "./ActionDatesRider";
import ActionDatesRunner from "./ActionDatesRunner";
import ActionMessagingRider from "./ActionMessagingRider";
import ActionMessagingRunner from "./ActionMessagingRunner";
import ActionLocationsRider from "./ActionLocationsRider";
import ActionLocationsRunner from "./ActionLocationsRunner";
import ActionEntryRider from "./ActionEntryRider";
import ActionEntryRunner from "./ActionEntryRunner";
import ActionMembersRider from "./ActionMembersRider";
import ActionMembersRunner from "./ActionMembersRunner";

function ActionCore(props) {
  const user = props.user;
  const eventContext = useContext(EventContext);
  const [runnerAction, setRunnerAction] = useState(
    props.runnerAction ? true : false
  );
  const [riderAction, setRiderAction] = useState(
    props.riderAction ? true : false
  );
  const [viewerType] = useState(
    eventContext.state.event.manager ? "runner" : "rider"
  );
  const [runnerViewer] = useState(eventContext.state.event.manager);
  const [riderViewer] = useState(!eventContext.state.event.manager);
  const [showInfo, setShowInfo] = useState(false);
  const onChangeCallback = props.onChange;

  // const handleToggleInfo = async () => {
  //   setShowInfo(!showInfo);
  // };
  const handleCloseInfo = async () => {
    setShowInfo(false);
  };
  const handleToggleAction = async ({
    runnerSide,
    riderSide,
    overrideAction,
  }) => {
    if (!user.connected && props.room !== "launchpad") {
      props.drawerConnectToggle();
    } else {
      let newRunnerAction = runnerAction;
      let newRiderAction = riderAction;
      if (overrideAction === true) {
        newRunnerAction = runnerSide && runnerViewer ? true : false;
        newRiderAction = riderSide && riderViewer ? true : false;
      } else {
        newRunnerAction = runnerSide && runnerViewer ? !runnerAction : false;
        newRiderAction = riderSide && riderViewer ? !riderAction : false;
      }
      setRunnerAction(newRunnerAction);
      setRiderAction(newRiderAction);
      if (onChangeCallback)
        onChangeCallback({
          runnerAction: newRunnerAction,
          riderAction: newRiderAction,
        });
    }
  };
  const handleOpenAction = async () => {
    return await handleToggleAction({
      runnerSide: runnerViewer,
      riderSide: riderViewer,
      overrideAction: true,
    });
  };

  const handleCloseAction = async () => {
    setRunnerAction(false);
    setRiderAction(false);
    if (onChangeCallback)
      onChangeCallback({
        runnerAction: false,
        riderAction: false,
      });
  };

  const renderActionButton = ({ runnerSide, riderSide }) => {
    // set button text state with counters
    let text = "";
    let side = runnerSide ? "runner" : "rider";
    if ((runnerSide && runnerAction) || (riderSide && riderAction)) {
      text += "Close";
    } else {
      text +=
        eventContext.state.event.modules[
          eventContext.state.event.moduleSelected
        ].text[side].action;
      if (
        runnerViewer &&
        runnerSide &&
        eventContext.state.event.modules[
          eventContext.state.event.moduleSelected
        ].counters.runner.viewer.actionInfo
      ) {
        text +=
          eventContext.state.event.modules[
            eventContext.state.event.moduleSelected
          ].counters.runner.viewer.actionInfo;
      }
      if (
        riderViewer &&
        riderSide &&
        eventContext.state.event.modules[
          eventContext.state.event.moduleSelected
        ].counters.rider.viewer.actionInfo
      ) {
        text +=
          eventContext.state.event.modules[
            eventContext.state.event.moduleSelected
          ].counters.rider.viewer.actionInfo;
      }
      // override if provided
      if (props.actionText) {
        text = props.actionText;
      }
    }
    const buttonDisabled =
      (runnerSide && riderViewer) ||
      (riderSide && runnerViewer) ||
      props.disabled;
    // output dynamic action button
    return props.mini ? (
      <ActionButton
        variant="contained"
        size="small"
        color={runnerAction || riderAction ? "bright" : "dusk"}
        fullWidth={props.fullWidth}
        disabled={buttonDisabled}
        disableElevation={props.disableElevation}
        borderGlow={buttonDisabled ? false : true}
        text={
          <span>
            <span>{text}</span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            {props.icon && (
              <span>
                <FontAwesomeIcon
                  icon={props.icon}
                  transform="grow-4"
                  fixedWidth
                />
                <FontAwesomeIcon
                  icon={
                    (riderSide && riderAction) || (runnerSide && runnerAction)
                      ? iconClose
                      : iconAdd
                  }
                  transform="shrink-4 right-8"
                  fixedWidth
                />
              </span>
            )}
          </span>
        }
        onClick={
          buttonDisabled
            ? undefined
            : () => handleToggleAction({ runnerSide, riderSide })
        }
      />
    ) : (
      <ActionButton
        variant="contained"
        type="button"
        size="medium"
        color={
          runnerAction || riderAction
            ? "bright"
            : props.color
            ? props.color
            : "primary"
        }
        text={text}
        fullWidth={true}
        disabled={buttonDisabled}
        disableElevation={props.disableElevation}
        borderGlow={buttonDisabled ? false : props.borderGlow ? true : false}
        startIcon={
          riderSide && riderAction ? (
            <FontAwesomeIcon
              icon={iconClose}
              style={{
                fontSize: "inherit",
              }}
              fixedWidth
            />
          ) : null
        }
        endIcon={
          runnerSide && runnerAction ? (
            <FontAwesomeIcon
              icon={iconClose}
              style={{
                fontSize: "inherit",
              }}
              fixedWidth
            />
          ) : null
        }
        onClick={
          buttonDisabled
            ? undefined
            : () => handleToggleAction({ runnerSide, riderSide })
        }
      />
    );
  };

  return (
    <Box className="box-default">
      <Box className="box-default">
        {renderActionButton({
          runnerSide: runnerViewer,
          riderSide: riderViewer,
        })}
      </Box>
      {/* ---- Action Content ---- */}
      <Drawer
        anchor="bottom"
        open={runnerAction || riderAction}
        onClose={handleCloseAction}
      >
        <Container maxWidth="md">
          <Box className="box-default full-height">
            <Box
              className="box-default text-right"
              sx={{
                pt: configEntzy.APP_SPACING_MD2X,
                pr: configEntzy.APP_SPACING_MD,
              }}
            >
              <Typography variant="h3">
                <FontAwesomeIcon
                  icon={iconClose}
                  className="action-pointer"
                  onClick={handleCloseAction}
                />
              </Typography>
            </Box>
            <Box
              className="box-default"
              // className="box-default shadow-default bg-white-t50"
              sx={{
                // borderRadius: configEntzy.BORDER_SIZE_LG,
                mb: configEntzy.APP_SPACING_LG,
              }}
              hidden={!runnerAction && !riderAction}
            >
              <Box
                className="box-default"
                sx={{
                  pb: configEntzy.APP_SPACING_MD,
                  // borderTopLeftRadius: configEntzy.BORDER_SIZE_LG,
                  // borderTopRightRadius: configEntzy.BORDER_SIZE_LG,
                }}
              >
                <Typography variant="h6">
                  {/* <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> */}
                  {
                    eventContext.state.event.modules[props.room].text[
                      viewerType
                    ].title
                  }
                  {/* <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon
                  icon={showInfo ? iconClose : iconInfo}
                  className="action-pointer"
                  onClick={handleToggleInfo}
                  color="divider"
                  transform="up-2"
                />
                {!showInfo && (
                  <FontAwesomeIcon
                    icon={iconPointer}
                    className="action-pointer"
                    onClick={handleToggleInfo}
                    color="divider"
                    transform="shrink-4 right-18 down-2"
                  />
                )}
              </span> */}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mt: configEntzy.APP_SPACING_SM }}
                >
                  {
                    eventContext.state.event.modules[props.room].text[
                      viewerType
                    ].description
                  }
                </Typography>
                {props.room === "dates" && (
                  <Typography
                    variant="body2"
                    sx={{ mt: configEntzy.APP_SPACING_MD }}
                  >
                    <span
                      style={{
                        color: "#f90",
                        padding: "4px",
                        borderRadius: "6px",
                        border: "1px solid #f90",
                      }}
                    >
                      <span>&nbsp;&nbsp;</span>
                      <span>Launching&nbsp;at&nbsp;</span>
                      <span>
                        {eventContext.state.event.calendar.triggers.default}
                      </span>
                      <span>&nbsp;Riders&nbsp;with&nbsp;</span>
                      <span>
                        {eventContext.state.event.calendar.limits.notice}
                      </span>
                      <span>&nbsp;Day</span>
                      <span>
                        {eventContext.state.event.calendar.limits.notice === 1
                          ? ""
                          : "s"}
                      </span>
                      <span>&nbsp;Notice</span>
                      <span>&nbsp;&nbsp;</span>
                    </span>
                  </Typography>
                )}
                {props.room === "locations" && (
                  <Typography
                    variant="body2"
                    sx={{
                      mt: configEntzy.APP_SPACING_MD,
                      mb: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <span
                      style={{
                        color: "#f90",
                        padding: "4px",
                        borderRadius: "6px",
                        border: "1px solid #f90",
                      }}
                    >
                      &nbsp;&nbsp; Eventuator Country is{" "}
                      {
                        countriesGetItem(eventContext.state.event.data.Country)
                          .name
                      }
                      &nbsp;&nbsp;
                    </span>
                  </Typography>
                )}
                {props.room === "messaging" && (
                  <Fragment>
                    <Typography
                      variant="body2"
                      sx={{
                        mt: configEntzy.APP_SPACING_MD,
                        mb: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      <span
                        style={{
                          color: "#f90",
                          padding: "4px",
                          borderRadius: "12px",
                          border: "1px solid #f90",
                        }}
                      >
                        &nbsp;&nbsp; Posts are visible to
                        {eventContext.state.messaging.activeRoom.includes(
                          "main"
                        ) && " | Everyone"}
                        {eventContext.state.messaging.activeRoom.includes(
                          "desk"
                        ) && " | Runners & Private Rider"}
                        {eventContext.state.messaging.activeRoom.includes(
                          "runner"
                        ) && " | Runners"}
                        {eventContext.state.messaging.activeRoom.includes(
                          "rider"
                        ) && " | Riders"}
                        {eventContext.state.messaging.activeRoom.startsWith(
                          "ai"
                        ) && " | AI"}
                        &nbsp;&nbsp;
                      </span>
                    </Typography>
                    <Box className="box-default">
                      <TextTag
                        size="sm"
                        rounded={true}
                        bgColor="primary.main"
                        color="black"
                        textNoCasing={true}
                        text={
                          <span>
                            <span>&nbsp;&nbsp;</span>
                            <span>Posting in Room:</span>
                            <span>&nbsp;&nbsp;</span>
                            <span>
                              {eventContext.state.messaging.activeRoom.replace(
                                "|",
                                " | "
                              )}
                            </span>
                            <span>&nbsp;&nbsp;</span>
                          </span>
                        }
                      />
                    </Box>
                  </Fragment>
                )}
              </Box>
              <Divider />
              <Grow
                in={showInfo}
                timeout={{
                  enter: configEntzy.TRANSITIONS.default,
                  exit: configEntzy.TRANSITIONS.default,
                }}
              >
                <Box
                  className="box-default"
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                    mb: configEntzy.APP_SPACING_MD,
                  }}
                  hidden={!showInfo}
                >
                  <ActionCoreInfo
                    {...props}
                    runnerViewer={runnerViewer}
                    riderViewer={riderViewer}
                    handleCloseAction={handleCloseInfo}
                  />
                </Box>
              </Grow>
              {props.room === "launchpad" && runnerAction && (
                <Box className="box-default">
                  <ActionLaunchpadRunner
                    {...props}
                    handleCloseAction={handleCloseAction}
                    handleOpenAction={handleOpenAction}
                  />
                </Box>
              )}

              {props.room === "sharing" && runnerAction && (
                <Box className="box-default">
                  <ActionSharingRunner
                    {...props}
                    handleCloseAction={handleCloseAction}
                    handleOpenAction={handleOpenAction}
                  />
                </Box>
              )}
              {props.room === "sharing" && riderAction && (
                <Box className="box-default">
                  <ActionSharingRider
                    {...props}
                    handleCloseAction={handleCloseAction}
                    handleOpenAction={handleOpenAction}
                  />
                </Box>
              )}
              {props.room === "dates" && runnerAction && (
                <Box className="box-default">
                  <ActionDatesRunner
                    {...props}
                    handleCloseAction={handleCloseAction}
                    handleOpenAction={handleOpenAction}
                  />
                </Box>
              )}
              {props.room === "dates" && riderAction && (
                <Box className="box-default">
                  <ActionDatesRider
                    {...props}
                    handleCloseAction={handleCloseAction}
                    handleOpenAction={handleOpenAction}
                  />
                </Box>
              )}
              {props.room === "messaging" && runnerAction && (
                <Box className="box-default">
                  <ActionMessagingRunner
                    {...props}
                    handleCloseAction={handleCloseAction}
                    handleOpenAction={handleOpenAction}
                  />
                </Box>
              )}
              {props.room === "messaging" && riderAction && (
                <Box className="box-default">
                  <ActionMessagingRider
                    {...props}
                    handleCloseAction={handleCloseAction}
                    handleOpenAction={handleOpenAction}
                  />
                </Box>
              )}
              {props.room === "locations" && runnerAction && (
                <Box
                  className="box-default"
                  sx={{ mb: configEntzy.APP_SPACING_XL }}
                >
                  <ActionLocationsRunner
                    {...props}
                    handleCloseAction={handleCloseAction}
                    handleOpenAction={handleOpenAction}
                  />
                </Box>
              )}
              {props.room === "locations" && riderAction && (
                <Box
                  className="box-default"
                  sx={{ mb: configEntzy.APP_SPACING_XL }}
                >
                  <ActionLocationsRider
                    {...props}
                    handleCloseAction={handleCloseAction}
                    handleOpenAction={handleOpenAction}
                  />
                </Box>
              )}
              {props.room === "entry" && runnerAction && (
                <Box className="box-default">
                  <ActionEntryRunner
                    {...props}
                    handleCloseAction={handleCloseAction}
                    handleOpenAction={handleOpenAction}
                  />
                </Box>
              )}
              {props.room === "entry" && riderAction && (
                <Box className="box-default">
                  <ActionEntryRider
                    {...props}
                    handleCloseAction={handleCloseAction}
                    handleOpenAction={handleOpenAction}
                  />
                </Box>
              )}
              {props.room === "members" && runnerAction && (
                <Box className="box-default">
                  <ActionMembersRunner
                    {...props}
                    handleCloseAction={handleCloseAction}
                    handleOpenAction={handleOpenAction}
                  />
                </Box>
              )}
              {props.room === "members" && riderAction && (
                <Box className="box-default">
                  <ActionMembersRider
                    {...props}
                    handleCloseAction={handleCloseAction}
                    handleOpenAction={handleOpenAction}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Drawer>
    </Box>
  );
}

export default ActionCore;

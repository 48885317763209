// react core
import { useReducer, useEffect, useCallback } from "react";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

// entzy event child pages
import MenuStart from "components/menu/MenuStart";
import ProfileName from "./profile/ProfileName";
import ProfileAvatar from "./profile/ProfileAvatar";
import PaymentMethods from "./payment/PaymentMethods";
import PaymentHistory from "./payment/PaymentHistory";
import IncomeBank from "./income/IncomeBank";
import IncomeBalance from "./income/IncomeBalance";
import TransactionHistory from "./transaction/TransactionHistory";
import SecurityEmail from "./security/SecurityEmail";
import SecurityPassword from "./security/SecurityPassword";
import SecurityMfa from "./security/SecurityMfa";
import AccountClose from "./account/AccountClose";

// entzy context
import {
  SettingsContext,
  initialState,
  settingsReducer,
  settingsActions,
} from "./SettingsContext";

// entzy config and services
import configEntzy from "components/config/ConfigEntzy";
import { serviceGraphCall, serviceLogError } from "services/graphql/call";
import { PageLoader } from "components/utils/common/CommonLoaders";

// entzy hooks
import {
  userUpdate,
  userUpdateTimestamp,
  userEmailVerify,
  userPasswordUpdate,
  userMfaActivate,
  userMfaDeactivate,
  // userGet,
  userExit,
} from "hooks/identity/identityState";

function SettingsIndex(props) {
  const user = props.user;
  const [state, dispatch] = useReducer(settingsReducer, initialState);

  const preparePullSettings = async () => {
    let response;
    if (user.connected) {
      response = await serviceGraphCall("query", "getUser");
      if (!response.success) {
        response.alert = true;
        return response;
      }
      const identityPackage = {
        settings: {
          data: response.data,
        },
        user: user,
      };
      // fields that always need to be populated
      if (!identityPackage.settings.data.Avatar) {
        identityPackage.settings.data.Avatar = state.constants.emptyStringSet;
      }
      if (!identityPackage.settings.data.PaymentId) {
        identityPackage.settings.data.PaymentId =
          state.constants.emptyStringSet;
      }
      if (!identityPackage.settings.data.PayoutId) {
        identityPackage.settings.data.PayoutId = state.constants.emptyStringSet;
      }
      return identityPackage;
    } else {
      return {
        alert: true,
        message: "Connect for settings access",
      };
    }
  };
  const cbPreparePullSettings = useCallback(preparePullSettings, [
    user,
    state.constants.emptyStringSet,
  ]);

  const preparePullTransactions = async (params) => {
    let response;
    if (user.connected) {
      response = await serviceGraphCall("query", "getUserTransactionList", {
        nextToken: params ? params.nextToken : null,
        limit: configEntzy.PAGE_SIZE_TRANSACTIONS,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      return {
        data: response.data.items,
        nextToken: response.data.nextToken,
        more: params && params.nextToken ? true : false,
      };
    } else {
      return {
        data: [],
      };
    }
  };
  const cbPreparePullTransactions = useCallback(preparePullTransactions, [
    user,
  ]);

  const prepareRefundTransaction = async (payment) => {
    let response, payload, actionType, actionName;
    if (user.connected) {
      actionType = "mutation";
      if (payment.EventId && payment.TicketId && payment.EntryDate) {
        actionName = "triggerReversal";
        payload = {
          EventId: payment.EventId,
          TicketId: payment.TicketId,
          DateId: payment.EntryDate,
        };
      } else {
        actionName = "reverseDirectPayment";
        payload = {
          TransactionId: payment.TransactionId,
        };
      }
      response = await serviceGraphCall(actionType, actionName, payload);
      if (response.success) {
        payment.success = true;
      } else {
        payment.alert = true;
        payment.message = response.message;
      }
      return payment;
    } else {
      return {
        alert: true,
        message: "Please connect to refund payments",
      };
    }
  };

  const prepareUpdateSettings = async (update) => {
    let response, attributes, updateAttributes, exists;
    if (user.connected) {
      // validation actions
      if (update.key === "Name") {
        response = await serviceGraphCall("query", "getUserCheckName", {
          UserName: update.value,
        });
        exists = response.data;
        if (!response.success) {
          response.alert = true;
          return response;
        } else if (exists) {
          return {
            alert: true,
            message: "This name is already in use. Give another one a go.",
          };
        } else if (update.value.includes("entzy") && !user.admin) {
          return {
            alert: true,
            message: "Name cannot contain 'entzy'. Give another one a go.",
          };
        }
      }
      if (update.key === "PayoutId") {
        response = await serviceGraphCall("mutation", "updatePayout", {
          PayoutCode: update.value,
        });
        if (
          response.data &&
          response.data.PayoutId &&
          response.data.PayoutId === "error"
        ) {
          response.success = false;
        }
        if (!response.success) {
          response.alert = true;
          response.message =
            "Unable to connect to the income service at the moment. Give it another go or contact us.";
          return response;
        } else {
          update.value =
            update.value === "delete" ? "delete" : response.data.PayoutId;
        }
      }
      // run user cognito attribute updates if required
      updateAttributes = true;
      if (update.key === "Name") {
        attributes = { "custom:entzy_name": update.value };
      } else if (update.key === "Avatar") {
        attributes = { "custom:entzy_avatar": update.value };
      } else if (update.key === "Email") {
        attributes = {
          "custom:entzy_last_email": userUpdateTimestamp().toString(),
          "custom:entzy_last_email_add": state.user.email,
          email: update.value,
        };
      } else if (update.key === "EmailResend") {
        response = await userEmailVerify(update.value);
        updateAttributes = false;
      } else if (update.key === "EmailVerify") {
        response = await userEmailVerify(update.value, update.verify);
        if (!response.success) {
          response.alert = true;
          if (!response.message) {
            response.message =
              "Unable to verify your new email at the moment. Give it another go or contact us if this persists.";
          }
          update.key = "Email";
          return response;
        }
        attributes = {
          "custom:entzy_last_email": "",
          "custom:entzy_last_email_add": update.value,
        };
      } else if (update.key === "PaymentId") {
        attributes = {
          "custom:entzy_payment_set":
            update.value === state.settings.activePaymentMethod.cardDelete
              ? "false"
              : "true",
        };
      } else if (update.key === "PayoutId") {
        attributes = {
          "custom:entzy_payout_set":
            update.value === "delete" // TODO
              ? "false"
              : "true",
        };
      } else {
        updateAttributes = false;
      }
      if (updateAttributes) {
        response = await userUpdate(attributes);
        if (response.success) {
          update.user = response.data;
        } else {
          response.alert = true;
          if (!response.message) {
            response.message =
              "Unable to update your settings at the moment. Give it another go or contact us if this persists.";
          }
          return response;
        }
      }
      // custom actions
      // password change activity
      if (update.key === "Password") {
        response = await userPasswordUpdate(update.value);
        if (!response.success) {
          response.alert = true;
          if (!response.message) {
            response.message =
              "Unable to update your password at the moment. Give it another go or contact us if this persists.";
          }
          return response;
        }
      }
      // mfa change activity
      if (update.key === "MfaActivate") {
        response = await userMfaActivate(update.value);
        if (!response.success) {
          response.alert = true;
          if (!response.message) {
            response.message =
              "Unable to activate MFA at the moment. Give it another go or contact us if this persists.";
          }
          return response;
        }
      }
      if (update.key === "MfaDeactivate") {
        response = await userMfaDeactivate();
        if (!response.success) {
          response.alert = true;
          if (!response.message) {
            response.message =
              "Unable to deactivate MFA at the moment. Give it another go or contact us if this persists.";
          }
          return response;
        }
      }
      // payment deletion activity
      if (
        update.key === "PaymentId" &&
        update.value === state.settings.activePaymentMethod.cardDelete
      ) {
        response = await serviceGraphCall("mutation", "updatePayment", {
          PaymentToken: "delete",
        });
        if (!response.success) {
          response.alert = true;
          return response;
        }
      }
      // initiate backend sync
      response = await serviceGraphCall("mutation", "syncUser", {
        Action: "profile",
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      // return key value updated
      return update;
    } else {
      return {
        alert: true,
        message: "Connect to make settings updates",
      };
    }
  };

  const prepareIncomeAccountUrl = async (params) => {
    let response;
    if (user.connected) {
      response = await serviceGraphCall("query", "viewPayout");
      if (!response.success) {
        response.alert = true;
        return response;
      }
      const url = response.data;
      if (
        url === "error" ||
        !url.startsWith(configEntzy.STRIPE_CONNECT_URL.substring(0, 30))
      ) {
        serviceLogError("prepareIncomeAccountUrl", url);
        return {
          alert: true,
          message:
            "Unable to generate account connection at the moment. Give it another go or contact us.",
        };
      }
      return url;
    } else {
      return {
        alert: true,
        message: "Connect for settings access",
      };
    }
  };

  const prepareCloseAccount = async (params) => {
    let response;
    if (user.connected) {
      response = await serviceGraphCall("mutation", "closeAccount", {
        Comments: params.feedback,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      await userExit();
      return response;
    } else {
      return {
        alert: true,
        message: "Connect to manage account settings",
      };
    }
  };

  // context value to pass down
  const value = {
    state,
    preparePullSettings: preparePullSettings,
    pullSettings: (identity) => {
      dispatch({ type: settingsActions.PULL_SETTINGS, identity });
    },
    prepareUpdateSettings: prepareUpdateSettings,
    updateSettings: (field) => {
      dispatch({ type: settingsActions.UPDATE_SETTINGS, field });
      dispatch({ type: settingsActions.UPDATE_USER, user: field.user });
      // pass update back up to refresh user
      // props.setUser(field.user);
      // props.userReload();
    },
    preparePullTransactions: preparePullTransactions,
    pullTransactions: (transactions) => {
      dispatch({
        type: settingsActions.PULL_TRANSACTIONS,
        transactions,
      });
    },
    prepareRefundTransaction: prepareRefundTransaction,
    refundTransaction: (transaction) => {
      dispatch({ type: settingsActions.REFUND_TRANSACTION, transaction });
    },
    prepareIncomeAccountUrl: prepareIncomeAccountUrl,
    prepareCloseAccount: prepareCloseAccount,
    updateAlert: (alert) => {
      dispatch({ type: settingsActions.UPDATE_ALERT, alert });
    },
  };

  // initial hydrate of context
  useEffect(() => {
    const hydrateSettings = async () => {
      // const user = await userGet(null, true);
      const identityPackage = await cbPreparePullSettings();
      const transactionList = await cbPreparePullTransactions();
      if (identityPackage.alert) {
        dispatch({
          type: settingsActions.UPDATE_ALERT,
          alert: { show: true, message: identityPackage.message },
        });
      } else {
        dispatch({
          type: settingsActions.UPDATE_USER,
          user: user,
        });
        dispatch({
          type: settingsActions.PULL_SETTINGS,
          identity: identityPackage,
        });
      }
      if (transactionList.alert) {
        dispatch({
          type: settingsActions.UPDATE_ALERT,
          alert: { show: true, message: transactionList.message },
        });
      } else {
        dispatch({
          type: settingsActions.PULL_TRANSACTIONS,
          transactions: transactionList,
        });
      }
    };
    if (!state.settings.hydrated && !state.alert.show) {
      hydrateSettings();
    }
  }, [
    user,
    state.settings.hydrated,
    state.alert.show,
    cbPreparePullSettings,
    cbPreparePullTransactions,
  ]);

  // console.log("[SETTING CONTEXT]", state);

  return (
    <SettingsContext.Provider value={value}>
      <Box className="box-default">
        {!state.settings.hydrated ? (
          <Box
            className="box-default"
            sx={{
              pl: configEntzy.APP_SPACING_XL,
              pr: configEntzy.APP_SPACING_XL,
            }}
          >
            <PageLoader />
          </Box>
        ) : (
          <Box className="box-default">
            {!props.hideMenu && (
              <Box className="box-default">
                <MenuStart {...props} />
              </Box>
            )}
            <Box className="box-default">
              <Container maxWidth="sm">
                {props.page === "/settings/profile/name" ? (
                  <ProfileName {...props} />
                ) : props.page === "/settings/profile/avatar" ? (
                  <ProfileAvatar {...props} />
                ) : props.page === "/settings/payment/methods" ? (
                  <PaymentMethods {...props} />
                ) : props.page === "/settings/payment/history" ? (
                  <PaymentHistory {...props} />
                ) : props.page === "/settings/income/bank" ? (
                  <IncomeBank {...props} />
                ) : props.page === "/settings/income/balance" ? (
                  <IncomeBalance {...props} />
                ) : props.page === "/settings/transaction/history" ? (
                  <TransactionHistory {...props} />
                ) : props.page === "/settings/security/email" ? (
                  <SecurityEmail {...props} />
                ) : props.page === "/settings/security/password" ? (
                  <SecurityPassword {...props} />
                ) : props.page === "/settings/security/mfa" ? (
                  <SecurityMfa {...props} />
                ) : props.page === "/settings/account/close" ? (
                  <AccountClose {...props} />
                ) : (
                  <Box className="box-default"></Box>
                )}
              </Container>
            </Box>
          </Box>
        )}
      </Box>
    </SettingsContext.Provider>
  );
}

export default SettingsIndex;

// react core
import { useState, useEffect } from "react";

// theming
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// imported animations
// import Marquee from "react-fast-marquee";

// material design
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import Zoom from "@mui/material/Zoom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExplosion as iconLaunchedDuo } from "@fortawesome/pro-duotone-svg-icons";
import { faExplosion as iconLaunchedThin } from "@fortawesome/pro-thin-svg-icons";
import { faBurst as iconBurstDuo } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as iconCircleDuo } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as iconCircleReg } from "@fortawesome/pro-solid-svg-icons";
import { faRing as iconRing } from "@fortawesome/pro-duotone-svg-icons";
import { faPeopleGroup as iconBoom } from "@fortawesome/pro-duotone-svg-icons";
import { faPlay as iconGo } from "@fortawesome/pro-thin-svg-icons";
import { faInfoCircle as iconInfo } from "@fortawesome/pro-thin-svg-icons";
import { faSignIn as iconConnect } from "@fortawesome/pro-thin-svg-icons";
import { faTimes as iconClose } from "@fortawesome/pro-thin-svg-icons";
import { faCircleCalendar as iconCalendar } from "@fortawesome/pro-duotone-svg-icons";
import { orange, red } from "@mui/material/colors/";
// import { faAtomAlt as iconStart } from "@fortawesome/pro-thin-svg-icons";
// import { faBurst as iconBurstThin } from "@fortawesome/pro-thin-svg-icons";
// import { faArrowAltRight as iconArrowLeft } from "@fortawesome/pro-solid-svg-icons";
// import { faArrowAltLeft as iconArrowRight } from "@fortawesome/pro-solid-svg-icons";
// import { faCaretRight as iconArrowGoRight } from "@fortawesome/pro-duotone-svg-icons";
// import { faCog as iconSettings } from "@fortawesome/pro-thin-svg-icons";
// import { faMessageHeart as iconSupport } from "@fortawesome/pro-duotone-svg-icons";
// import { faAtomAlt as iconEvents } from "@fortawesome/pro-thin-svg-icons";
// import { faSquare as iconSquare } from "@fortawesome/pro-solid-svg-icons";
// import { faSpeaker as iconSpeaker } from "@fortawesome/pro-duotone-svg-icons";

// entzy config models and components
import configEntzy from "components/config/ConfigEntzy";
import HomeSpotlight from "./HomeSpotlight";
import WidgetHome from "components/event/widgets/WidgetHome";
// import WidgetTimeline from "components/event/widgets/WidgetTimeline";
// import WidgetHomeMenu from "components/event/widgets/WidgetHomeMenu";
import WidgetUserBanner from "components/event/widgets/WidgetUserBanner";
import {
  ActionBoxButton,
  BigButton,
} from "components/utils/common/CommonButtons";
import { TagLine } from "components/utils/common/CommonTags";

// entzy assets
import BadgeGooglePlay from "assets/badges/google-play.png";
import BadgeAppleStore from "assets/badges/apple-store.svg";
import { scrollSnapTop, scrollIntoViewIfNeeded } from "models/Tools";

// entzy hooks
import { useMainRedirect } from "hooks/main/mainRedirect";

function BoomAvatar(props) {
  const [zoom, setZoom] = useState(0);

  // toggle zoom on a 6 second delay for 10 times
  useEffect(() => {
    const timer = setInterval(() => {
      setZoom(zoom + 1);
    }, 6000);
    if (zoom > 20) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [zoom]);

  return (
    <Box
      className="box-default"
      sx={{
        fontSize: configEntzy.FONT_SCALE_MD,
      }}
    >
      <Box className="box-default">
        <Box component="span" className="fa-layers fa-fw fa-9x">
          {/* <FontAwesomeIcon
            icon={iconArrowLeft}
            style={{ opacity: 0.5 }}
            transform="grow-30 left-20"
          />
          <FontAwesomeIcon
            icon={iconArrowRight}
            style={{ opacity: 0.5 }}
            transform="grow-30 right-20"
          /> */}
          <FontAwesomeIcon
            icon={iconBurstDuo}
            style={{ opacity: 0.8, color: red[900] }}
            transform="grow-50 down-2"
          />
          {/* <FontAwesomeIcon
            icon={iconBurstThin}
            style={{ opacity: 0.8, color: red[900] }}
            transform="grow-36 down-2"
          /> */}

          <FontAwesomeIcon
            icon={iconRing}
            style={{ opacity: 0.8, color: red[900] }}
            transform="grow-24 down-2"
          />
          <FontAwesomeIcon
            icon={iconCircleDuo}
            style={{ opacity: 0.8, color: orange[600] }}
            transform="grow-24 down-2"
          />
          <FontAwesomeIcon
            icon={iconCalendar}
            className="fa-beat"
            transform="grow-36 down-2"
            style={{
              "--fa-animation-duration": "2s",
              "--fa-beat-scale": 1.05,
              // opacity: 0.8,
              // color: orange[900],
              color: "purple",
            }}
          />
          <FontAwesomeIcon
            icon={iconCalendar}
            transform="grow-36 down-2"
            style={{
              opacity: 0.8,
              color: "black",
            }}
          />
          <FontAwesomeIcon
            icon={iconCalendar}
            transform="grow-32 down-2"
            color="white"
            // add text outline
            // style={{
            //   opacity: 0.8,
            // }}
            // style={{ color: orange[100] }}
          />
          <FontAwesomeIcon
            icon={iconLaunchedDuo}
            style={{ color: orange[500] }}
          />
          <FontAwesomeIcon
            icon={iconLaunchedThin}
            style={{ color: orange[500] }}
          />
          <FontAwesomeIcon
            icon={iconCircleDuo}
            transform="shrink-3"
            style={{ color: orange[500] }}
          />
          <FontAwesomeIcon
            icon={iconCircleDuo}
            transform="shrink-6"
            style={{ color: orange[700] }}
          />
          <FontAwesomeIcon
            icon={iconCircleReg}
            transform="shrink-9"
            style={{ color: orange[200] }}
          />
          <FontAwesomeIcon
            icon={iconBoom}
            color="black"
            transform="shrink-12"
          />
          {/* <Box component="span" className="fa-layers-text">
            <Typography variant="h2" color="primary" noWrap={true}>
              Runners&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Riders&nbsp;&nbsp;
            </Typography>
          </Box> */}
        </Box>
      </Box>
      <Box
        className="box-default"
        // sx={{
        //   color: orange[500],
        // }}
      >
        <Zoom
          in={
            // zoom is even
            zoom % 2 === 0
          }
          timeout={2000}
        >
          <Box
            className="box-default relative zero-height"
            sx={{
              zIndex: 1,
            }}
          >
            <Typography variant="h2" color="white">
              <span>Eventuate</span>
            </Typography>
          </Box>
        </Zoom>
        <Zoom
          in={
            // zoom is odd
            zoom % 2 !== 0
          }
          timeout={2000}
        >
          <Box
            className="box-default relative zero-height"
            sx={{
              zIndex: 1,
            }}
          >
            <Typography variant="h2" color="white">
              <span>Let's Go</span>
            </Typography>
          </Box>
        </Zoom>
      </Box>
    </Box>
  );
}

function HomeIndexPublic(props) {
  const [start, setStart] = useState(false);

  const handleToggleStart = () => {
    // if (start) scrollSnapTop();
    const element = document.getElementById("anchor-lets-go");
    if (element) scrollIntoViewIfNeeded(element);
    setStart(!start);
  };

  return (
    <Box className="box-default">
      <Box className="box-default">
        <Box
          className="box-default half-width"
          sx={{
            borderRight: "1px solid #444",
          }}
        >
          <Box
            className="box-default"
            sx={{
              height: configEntzy.AVATAR_SIZE_XS,
              borderRight: "2px solid #333",
            }}
          >
            <span>&nbsp;</span>
          </Box>
        </Box>
        <Box
          className="box-default half-width"
          sx={{
            borderLeft: "1px solid #444",
          }}
        >
          <Box
            className="box-default"
            sx={{
              height: configEntzy.AVATAR_SIZE_XS,
              borderLeft: "2px solid #333",
            }}
          >
            <span>&nbsp;</span>
          </Box>
        </Box>
      </Box>
      <Box className="box-default">
        <Box
          className="box-inline action-pointer bg-black-t75 shadow-default"
          sx={{
            p: configEntzy.APP_SPACING_MD,
            borderRadius: configEntzy.BORDER_SIZE_XL,
            border: "1px solid purple",
            // backgroundColor: orange[50],
          }}
          onClick={props.drawerConnectToggle}
        >
          <Box
            className="box-default"
            sx={{
              pb: configEntzy.APP_SPACING_SM2X,
              mb: configEntzy.APP_SPACING_SM2X,
              borderBottom: "1px solid #333",
            }}
          >
            <Typography variant="h6" className="glowing-text" color="white">
              <span>&nbsp;ENTZY&nbsp;</span>
            </Typography>
          </Box>
          <Box className="box-default">
            <Typography variant="h6" color="white">
              <span>&nbsp;The magic social calendar&nbsp;</span>
            </Typography>
            {/* <Typography variant="h6" color="orange">
              <span>&nbsp;for real time eventuating&nbsp;</span>
            </Typography> */}
          </Box>
        </Box>
      </Box>

      <Box
        className="box-default"
        sx={{
          mt: configEntzy.APP_SPACING_LG,
        }}
      >
        <Typography
          variant="h6"
          color={orange[600]}
          sx={{
            pb: configEntzy.APP_SPACING_MD,
          }}
        >
          Getting together is our super power
        </Typography>
        <TagLine text="💡" bookends={true} />
        <TagLine text="Ideas are the magic sauce" />
      </Box>
      <Box
        className="box-default"
        // sx={{
        //   mt: configEntzy.APP_SPACING_MD,
        // }}
      >
        {/* <TagLine text="Generate ideas for the calendar" /> */}
        {/* <TagLine
                    text={configEntzy.URL_POINTERS.BOT}
                    color="orange"
                    size="large"
                  /> */}
        {/* <TagLine text="Bring People Together" />
                  <TagLine text="Make a Little Magic" /> */}
      </Box>

      <Box
        className="box-default"
        sx={{
          minHeight:
            configEntzy.SPOTLIGHT_CARDS.length > 0
              ? configEntzy.AVATAR_CONTAINER_XS2X
              : "0px",
        }}
      >
        <HomeSpotlight {...props} />
      </Box>
      <Box
        id="anchor-lets-go"
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_HL,
        }}
      >
        <Container maxWidth="lg">
          <Box className="box-default">
            <Grow in={!start} timeout={1000}>
              <Box className="box-default" hidden={start}>
                <Box
                  className="box-default action-pointer"
                  sx={{
                    mt: configEntzy.APP_SPACING_LG,
                    pt: configEntzy.APP_SPACING_XL,
                    pb: configEntzy.APP_SPACING_HL,
                  }}
                  onClick={handleToggleStart}
                >
                  <BoomAvatar />
                </Box>
                <Box className="box-default">
                  <Typography
                    variant="h4"
                    color={orange[600]}
                    sx={{
                      mt: configEntzy.APP_SPACING_HL,
                      pt: configEntzy.APP_SPACING_XL,
                      pb: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    Make Something Happen
                  </Typography>
                  <TagLine text="Bring People Together" bookends={true} />
                </Box>
                {/* <Box
                  className="box-default"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <TagLine text="Launch Event Ideas On Demand" />
                </Box>
                <Box
                  className="box-default"
                  sx={{
                    mt: configEntzy.APP_SPACING_LG,
                  }}
                >
                  <Container maxWidth="xs">
                    <Typography variant="subtitle2" color={orange[600]}>
                      Propose an idea.
                    </Typography>
                    <Typography variant="subtitle2" color={orange[600]}>
                      Launch ticketed meets. Repeatedly.
                    </Typography>
                    <Typography variant="subtitle2" color={orange[600]}>
                      That trigger in real time. Only when everyone matches.
                    </Typography>
                  </Container>
                </Box> */}
              </Box>
            </Grow>
            <Grow in={start} timeout={1000}>
              <Box className="box-default" hidden={!start}>
                <Box className="box-default">
                  <Box
                    className="box-inline right"
                    sx={{
                      mt: configEntzy.APP_SPACING_NLG2X,
                      width: configEntzy.AVATAR_SIZE_LG,
                    }}
                  >
                    <BigButton
                      size="lg"
                      icon={iconClose}
                      iconColor="white"
                      bgColor="transparent"
                      textColor="white"
                      text="Close"
                      onClick={handleToggleStart}
                    />
                  </Box>
                </Box>
                <Box
                  className="box-default relative pop-up"
                  sx={{ mt: configEntzy.APP_SPACING_NHL }}
                >
                  <Box className="box-inline left">
                    <ActionBoxButton
                      variant="contained"
                      size="large"
                      type="button"
                      text="About Entzy"
                      circular={true}
                      bgColor="black"
                      borderColor="purple"
                      color="white"
                      onClick={() => props.menuSelectById("about")}
                      topIcon={
                        <FontAwesomeIcon
                          icon={iconInfo}
                          className="fa-beat"
                          style={{
                            "--fa-animation-duration": "1.5s",
                            "--fa-beat-scale": 1.1,
                          }}
                          color="orange"
                          size="3x"
                          fixedWidth
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box className="box-default zero-height">
                  <FontAwesomeIcon
                    icon={iconBurstDuo}
                    size="10x"
                    style={{ opacity: 0.8, color: red[900] }}
                    transform="grow-50 down-7"
                  />
                </Box>

                <Box
                  className="box-default relative pop-up"
                  sx={{ pt: configEntzy.APP_SPACING_MD }}
                >
                  <Box className="box-inline">
                    <ActionBoxButton
                      variant="contained"
                      size="large"
                      type="button"
                      text="Have a play"
                      circular={true}
                      bgColor="black"
                      borderColor="purple"
                      color="white"
                      onClick={() => {
                        scrollSnapTop();
                        props.navigate("/eventuators");
                      }}
                      topIcon={
                        <FontAwesomeIcon
                          icon={iconGo}
                          className="fa-beat"
                          style={{
                            "--fa-animation-duration": "1.5s",
                            "--fa-beat-scale": 1.1,
                          }}
                          color="orange"
                          size="3x"
                          fixedWidth
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box
                  className="box-default relative pop-up"
                  sx={{ pt: configEntzy.APP_SPACING_MD }}
                >
                  <Box className="box-inline right">
                    <ActionBoxButton
                      variant="contained"
                      size="large"
                      type="button"
                      text="Connect"
                      circular={true}
                      bgColor="black"
                      borderColor="purple"
                      color="white"
                      onClick={() => props.drawerConnectToggle()}
                      topIcon={
                        <FontAwesomeIcon
                          icon={iconConnect}
                          className="fa-beat"
                          style={{
                            "--fa-animation-duration": "1.5s",
                            "--fa-beat-scale": 1.1,
                          }}
                          color="orange"
                          size="3x"
                          fixedWidth
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Grow>
          </Box>
        </Container>
      </Box>

      <Box
        className="box-default footer-height"
        sx={{
          mt: configEntzy.APP_SPACING_HL2X,
        }}
      >
        <Box className="box-inline">
          <Box
            className="box-default bg-black-t50"
            sx={{
              pt: configEntzy.APP_SPACING_MD2X,
              pb: configEntzy.APP_SPACING_MD2X,
              pl: configEntzy.APP_SPACING_LG,
              pr: configEntzy.APP_SPACING_LG,
              borderRadius: configEntzy.BORDER_SIZE_XL,
            }}
          >
            <Box
              className="box-default half-width text-right"
              sx={{ pr: configEntzy.APP_SPACING_MD2X }}
            >
              <a
                href={
                  "https://apps.apple.com/us/app/entzy/id1543725422?ct=" +
                  configEntzy.APP_DOMAIN
                }
                target="_blank"
                rel="noopener noreferrer"
                className="image-link"
              >
                <img
                  alt="Download on the App Store"
                  src={BadgeAppleStore}
                  className="app-badge-apple"
                />
              </a>
            </Box>
            <Box
              className="box-default half-width text-left"
              sx={{ pl: configEntzy.APP_SPACING_MD }}
            >
              <a
                href={
                  "https://play.google.com/store/apps/details?id=com.entzy.twa&utm_source=" +
                  configEntzy.APP_DOMAIN
                }
                target="_blank"
                rel="noopener noreferrer"
                className="image-link"
              >
                <img
                  alt="Get it on Google Play"
                  src={BadgeGooglePlay}
                  className="app-badge-google"
                />
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function HomeIndexConnected(props) {
  return (
    <Box className="box-default">
      {/* --------- timeline --------- */}
      {/* <Box className="box-default">
        <WidgetTimeline {...props} />
      </Box> */}
      {/* --------- user avatar banner --------- */}
      <Box className="box-default">
        <WidgetUserBanner {...props} />
      </Box>
      {/* --------- home content --------- */}
      <Box
        className="box-default"
        sx={{
          p: configEntzy.APP_SPACING_MD,
          borderTop: "1px solid #333",
        }}
      >
        <Container maxWidth="lg" disableGutters>
          <WidgetHome {...props} />
        </Container>
      </Box>
    </Box>
  );
}

function HomeIndex(props) {
  // capture redirect on connect
  useMainRedirect(props);

  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      <Box className="box-default">
        {props.user.connected ? (
          <HomeIndexConnected {...props} />
        ) : (
          <HomeIndexPublic {...props} />
        )}
      </Box>
    </ThemeProvider>
  );
}

export default HomeIndex;

// react core
import { useState, Fragment } from "react";

// aws
import { Auth } from "aws-amplify";

// material theme
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt as iconSignIn } from "@fortawesome/pro-duotone-svg-icons";
import { faCaretLeft as iconBack } from "@fortawesome/pro-duotone-svg-icons";
import { faClose as iconClose } from "@fortawesome/pro-duotone-svg-icons";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";
import ConnectProviders from "./ConnectProviders";
import ConnectDirect from "./ConnectDirect";
import ConnectRegister from "./ConnectRegister";
import ConnectForgot from "./ConnectForgot";
import { ActionLoader } from "components/utils/common/CommonLoaders";

function ConnectBox(props) {
  const [user] = useState(props.user);
  const [section, setSection] = useState(null);
  const [connecting, setConnecting] = useState(null);

  const handleSection = async (section) => {
    setSection(section);
    setConnecting(null);
  };

  const handleBoxClose = async (action) => {
    setConnecting(null);
    setSection(null);
    props.drawerConnectToggle(false);
    props.navigate("/");
    props.userReload();
    // props.navigate(0);
    // props.sectionReload(["header", "content"]);
  };

  const handleIdentityProvider = async (provider) => {
    if (provider === "Entzy") {
      setSection("direct");
    } else {
      setSection("federated");
      setConnecting(
        provider.replace("LoginWith", "").replace("SignInWith", "")
      );
      props.menuSelectById("connecting:" + provider);
      // handleConnect(provider, "signin");
    }
  };

  const handleConnect = async (provider, action, email, pass, pass2) => {
    const providerName = provider
      .replace("LoginWith", "")
      .replace("SignInWith", "");
    setConnecting(providerName);

    let results;
    if (providerName === "Entzy") {
      switch (action) {
        case "signin":
          results = await Auth.signIn(email, pass);
          if (
            results.challengeName !== "SMS_MFA" &&
            results.challengeName !== "SOFTWARE_TOKEN_MFA"
          ) {
            handleBoxClose();
          }
          break;
        case "mfasignin":
          results = await Auth.confirmSignIn(
            pass2.user,
            pass2.code,
            pass2.user.challengeName
          );
          handleBoxClose();
          break;
        case "signup":
          results = await Auth.signUp(email, pass);
          break;
        case "resend":
          results = await Auth.resendSignUp(email);
          break;
        case "confirm":
          results = await Auth.confirmSignUp(email, pass);
          break;
        case "forgot":
          results = await Auth.forgotPassword(email);
          break;
        case "reset":
          results = await Auth.forgotPasswordSubmit(email, pass, pass2);
          break;
        case "signout":
          results = await Auth.signOut();
          handleBoxClose();
          break;
        default:
          results = { message: "No action taken" };
          break;
      }
    } else {
      switch (action) {
        case "signin":
          results =
            provider === "Hosted"
              ? Auth.federatedSignIn()
              : Auth.federatedSignIn({ provider });
          break;
        case "signout":
          results = await Auth.signOut();
          handleBoxClose();
          break;
        default:
          results = { message: "No action taken" };
          break;
      }
    }
    return results;
  };

  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      <Box
        className="box-default"
        sx={{ p: configEntzy.APP_SPACING_MD, color: "white" }}
      >
        <Box className="box-default">
          <Box className="box-default q3-width text-left">
            <Typography variant="h6" noWrap={true}>
              <span
                className="action-pointer"
                onClick={() => props.drawerConnectToggle(false)}
              >
                <FontAwesomeIcon icon={iconSignIn} />
                &nbsp;&nbsp;CONNECT
              </span>
            </Typography>
          </Box>
          <Box className="box-default quarter-width text-right">
            <Typography variant="h6" noWrap={true}>
              <span
                className="action-pointer"
                onClick={() => props.drawerConnectToggle(false)}
              >
                <FontAwesomeIcon icon={iconClose} transform="grow-4" />
              </span>
            </Typography>
          </Box>

          <Box className="box-default">
            <Box
              className="box-default"
              sx={{ p: configEntzy.APP_SPACING_MD }}
              hidden={user.connected || section !== null}
            >
              <Typography variant="subtitle2">Plugging you in.</Typography>
              <Typography variant="subtitle1">
                Select your preferred connection method
              </Typography>
            </Box>
            <Box className="box-default">
              <Container maxWidth="sm">
                {section === "direct" ? (
                  <Fragment>
                    <Box
                      className="box-default text-left upper-case action-pointer"
                      sx={{
                        pt: configEntzy.APP_SPACING_MD,
                        pb: configEntzy.APP_SPACING_MD,
                      }}
                      onClick={() => handleSection(null)}
                    >
                      <Typography variant="subtitle2" noWrap={true}>
                        <FontAwesomeIcon icon={iconBack} />
                        &nbsp;&nbsp;Back
                      </Typography>
                    </Box>
                    <ConnectDirect
                      handleConnect={handleConnect}
                      handleSection={handleSection}
                    />
                  </Fragment>
                ) : section === "register" ? (
                  <Fragment>
                    <Box
                      className="box-default text-left upper-case action-pointer"
                      sx={{
                        pt: configEntzy.APP_SPACING_MD,
                        pb: configEntzy.APP_SPACING_MD,
                      }}
                      onClick={() => handleSection("direct")}
                    >
                      <Typography variant="subtitle2" noWrap={true}>
                        <FontAwesomeIcon icon={iconBack} />
                        &nbsp;&nbsp;Back
                      </Typography>
                    </Box>
                    <ConnectRegister
                      handleConnect={handleConnect}
                      handleSection={handleSection}
                    />
                  </Fragment>
                ) : section === "forgot" ? (
                  <Fragment>
                    <Box
                      className="box-default text-left upper-case action-pointer"
                      sx={{
                        pt: configEntzy.APP_SPACING_MD,
                        pb: configEntzy.APP_SPACING_MD,
                      }}
                      onClick={() => handleSection("direct")}
                    >
                      <Typography variant="subtitle2" noWrap={true}>
                        <FontAwesomeIcon icon={iconBack} />
                        &nbsp;&nbsp;Back
                      </Typography>
                    </Box>
                    <ConnectForgot
                      handleConnect={handleConnect}
                      handleSection={handleSection}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    {user.connected ? (
                      <Fragment>
                        <Box
                          className="box-default"
                          sx={{ mt: configEntzy.APP_SPACING_XL }}
                        >
                          <Button
                            variant="outlined"
                            size="large"
                            type="button"
                            onClick={() => handleConnect("Any", "signout")}
                            disabled={connecting !== null}
                          >
                            Disconnect
                          </Button>
                        </Box>
                        <Box
                          className="box-default"
                          sx={{
                            mt: configEntzy.APP_SPACING_MD,
                            p: configEntzy.APP_SPACING_LG,
                          }}
                          hidden={!connecting}
                        >
                          <ActionLoader />
                        </Box>
                        <Box
                          className="box-default"
                          sx={{ mt: configEntzy.APP_SPACING_LG }}
                        >
                          <Typography variant="h6" noWrap={true}>
                            You are connected
                          </Typography>
                        </Box>
                        <Box className="box-default">
                          <Typography
                            variant="h6"
                            sx={{ mt: configEntzy.APP_SPACING_XL }}
                          >
                            @{user.name}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ mt: configEntzy.APP_SPACING_MD }}
                          >
                            {user.email}
                          </Typography>
                        </Box>
                      </Fragment>
                    ) : connecting ? (
                      <Fragment>
                        <Box
                          className="box-default"
                          sx={{
                            mt: configEntzy.APP_SPACING_XL,
                            p: configEntzy.APP_SPACING_LG,
                          }}
                        >
                          <ActionLoader />
                        </Box>
                        <Box
                          className="box-default"
                          sx={{ mt: configEntzy.APP_SPACING_MD }}
                        >
                          <Typography variant="h6" noWrap={true}>
                            Connecting via {connecting}
                          </Typography>
                        </Box>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Box
                          className="box-default"
                          sx={{
                            mt: configEntzy.APP_SPACING_MD,
                            borderRadius: "5px",
                            overflow: "hidden",
                          }}
                        >
                          <ConnectProviders
                            handleIdentityProvider={handleIdentityProvider}
                          />
                        </Box>
                        {/* <Box
                        className="box-default"
                        sx={{
                          mt: configEntzy.APP_SPACING_LG,
                        }}
                      >
                        <Typography variant="subtitle2" noWrap={true}>
                          Connect via Web 3
                        </Typography>
                      </Box>
                      <Box
                        className="box-default"
                        sx={{
                          mt: configEntzy.APP_SPACING_MD,
                          p: configEntzy.APP_SPACING_LG,
                          backgroundColor: "divider",
                        }}
                      >
                        <Typography
                          variant="body1"
                          className="content-disabled"
                          noWrap={true}
                        >
                          <em>Wallet Connect</em>
                        </Typography>
                        <Typography
                          variant="body1"
                          className="content-disabled"
                          noWrap={true}
                        >
                          <em>Coming soon</em>
                        </Typography>
                      </Box> */}
                        {/* <Box className="box-default" sx={{ mt: configEntzy.APP_SPACING_XL }}>
                      <Typography variant="h6" noWrap={true}>
                        Select your Login Provider
                      </Typography>
                    </Box> */}
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Container>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ConnectBox;

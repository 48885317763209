// react core
import { Suspense, useContext, useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

// swipeable touch handlers
import PullToRefresh from "react-simple-pull-to-refresh";
import { useSwipeable } from "react-swipeable";

// date handling
import dayjs from "dayjs";

// entzy components
import ErrorBoundary from "components/main/MainError";

// material theme
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import Slide from "@mui/material/Slide";
import Collapse from "@mui/material/Collapse";
import Drawer from "@mui/material/Drawer";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBiking as iconRiding } from "@fortawesome/pro-duotone-svg-icons";
import { faCreditCardAlt as iconTransact } from "@fortawesome/pro-thin-svg-icons";
// import { faGrid as iconLaunchpadMain } from "@fortawesome/pro-regular-svg-icons";
// import { faCommentAlt as iconMessages } from "@fortawesome/pro-regular-svg-icons";
// import { faChevronCircleLeft as iconModuleLeft } from "@fortawesome/pro-solid-svg-icons";
// import { faChevronCircleRight as iconModuleRight } from "@fortawesome/pro-solid-svg-icons";
import { faCircle as iconCircle } from "@fortawesome/pro-solid-svg-icons";
import { faFaceThinking as iconAlert } from "@fortawesome/pro-thin-svg-icons";
import { faEllipsisStrokeVertical as iconMenuDown } from "@fortawesome/pro-regular-svg-icons";
import { faChevronUp as iconMenuUp } from "@fortawesome/pro-regular-svg-icons";
import { faPlus as iconAdd } from "@fortawesome/pro-regular-svg-icons";
import { faTimes as iconClose } from "@fortawesome/pro-regular-svg-icons";
// import { faCheckCircle as iconCheck } from "@fortawesome/pro-duotone-svg-icons";
// import { faCircleChevronUp as iconMenuBarUp } from "@fortawesome/pro-solid-svg-icons";
// import { faCircleChevronDown as iconMenuBarDown } from "@fortawesome/pro-solid-svg-icons";

// entzy config and context
import configEntzy from "components/config/ConfigEntzy";
// import EventMap from "./EventMap";

import EventTicker from "./EventTicker";
import EventTitleTicker from "./EventTitleTicker";
import WidgetLaunchCounter from "components/event/widgets/WidgetLaunchCounter";
import WidgetWelcomeSplash from "components/event/widgets/WidgetWelcomeSplash";
import ActionLaunchpadPin from "components/event/actions/ActionLaunchpadPin";
import ActionLaunchpadRunner from "components/event/actions/ActionLaunchpadRunner";
import ActionMembersRunner from "components/event/actions/ActionMembersRunner";
// import RoomRunnerZone from "components/event/rooms/RoomRunnerZone";

import MainCallbackLoader from "components/main/MainCallbackLoader";
import { MenuEventBar } from "components/menu/MenuEvent";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { EVENT_MODULES, renderEventRoom } from "models/Structure";
import {
  PageLoader,
  ActionAlert,
  PullLoader,
  PullPrompt,
} from "components/utils/common/CommonLoaders";
// import { TextTag } from "components/utils/common/CommonTags";
import { ActionButton } from "components/utils/common/CommonButtons";

// entzy hooks
import { useMediaImage } from "hooks/media/mediaImage";
import { useMainRedirect } from "hooks/main/mainRedirect";

// entzy assets
import DefaultBanner from "assets/banners/crowd-t50.png";

function EventRunner(props) {
  const location = useLocation();

  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);
  const user = props.user;
  const eventParts = props.eventParts;
  const eventNavigate = props.navigate;
  const eventUrl = eventContext.state.event.data.Url;

  const counterMessages = mainContext.state.notifications.data.messages.filter(
    (obj) =>
      obj.MessageUrl.endsWith(configEntzy.URL_POINTERS.MAIN + eventUrl) &&
      !obj.MessageRead
  ).length;
  const counterLaunches = mainContext.state.notifications.data.activity.filter(
    (obj) =>
      obj.MessageUrl.endsWith(configEntzy.URL_POINTERS.MAIN + eventUrl) &&
      !obj.MessageRead
  ).length;

  const [hydratingEvent, setHydratingEvent] = useState(false);
  const [slideDirection, setSlideDirection] = useState("left");
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuBarOpen, setMenuBarOpen] = useState(false);
  const [inviteMembers, setInviteMembers] = useState(false);

  // derive active modules
  const ACTIVE_MODULES = EVENT_MODULES.filter((obj) => obj.active);
  const CORE_MODULES = EVENT_MODULES.filter((obj) =>
    user.connected
      ? obj.active && !obj.secondaryNav
      : eventContext.state.event.manager
      ? obj.active && !obj.secondaryNav
      : obj.active && !obj.secondaryNav && obj.visitingRider
  );
  const selectedIndex = ACTIVE_MODULES.findIndex(
    (obj) => obj.id === eventContext.state.event.moduleSelected
  );
  const prevModule = ACTIVE_MODULES[selectedIndex > 0 ? selectedIndex - 1 : 0];
  const nextModule =
    ACTIVE_MODULES[
      selectedIndex < ACTIVE_MODULES.length - 1 ? selectedIndex + 1 : 0
    ];
  const startModule = CORE_MODULES[0].id;
  const endModule = CORE_MODULES[CORE_MODULES.length - 1].id;

  // handle module switching
  const handleModuleSwitch = (direction, eventData) => {
    if (direction === -1 && prevModule.id === startModule) {
      setMenuOpen(false);
    }
    if (direction === 1 && nextModule) {
      if (eventContext.state.event.moduleSelected !== endModule) {
        setSlideDirection("left");
        eventContext.focusModule(nextModule.id);
      }
    } else if (direction === -1 && prevModule) {
      if (eventContext.state.event.moduleSelected !== startModule) {
        setSlideDirection("right");
        eventContext.focusModule(prevModule.id);
      }
    }
  };
  const handleToggleMenu = () => {
    if (eventContext.state.event.moduleSelected === startModule) {
      setMenuOpen(false);
    } else {
      setMenuOpen(!menuOpen);
    }
  };

  // handle view toggles
  const handleToggleMenuBar = () => {
    setMenuBarOpen(!menuBarOpen);
  };
  const handleToggleInviteMembers = () => {
    setInviteMembers(!inviteMembers);
  };

  const handleRefresh = async () => {
    if (user.connected) {
      eventContext.setHydrated(false);
      const response = await Promise.all([
        mainContext.preparePullNotifications({
          Category: null,
        }),
      ]);
      if (response.filter((item) => item.alert).length > 0) {
        mainContext.updateAlert({
          alert: true,
          message:
            "Hmm unable to refresh eventuator content on that try. Give it another go or contact us if this problem persists.",
        });
        return true;
      } else {
        mainContext.pullNotifications(response[0]);
        return true;
      }
    } else {
      return true;
    }
  };

  const openTransact = async () => {
    if (user.connected) {
      mainContext.setPayDirectViewer({
        open: mainContext.state.payDirect.viewer.open ? false : true,
        recipient:
          configEntzy.URL_POINTERS.MAIN + eventContext.state.event.data.Url,
        currency: eventContext.state.event.data.Currency,
      });
    } else {
      mainContext.updateAlert({
        show: true,
        message: "You must be connected to transact",
      });
    }
  };

  // create callback functions for context actions
  const cbPrepareJoinEvent = useCallback(
    async (eventData) => {
      return eventContext.prepareJoinEvent(eventData);
    },
    [eventContext]
  );
  const cbPrepareStartEvent = useCallback(
    async (eventData) => {
      return eventContext.prepareStartEvent(eventData);
    },
    [eventContext]
  );
  const cbStartEvent = useCallback(
    (eventContent, startData, startType) => {
      return eventContext.startEvent(eventContent, startData, startType);
    },
    [eventContext]
  );
  const cbUpdateAlert = useCallback(
    (alertData) => {
      return eventContext.updateAlert(alertData);
    },
    [eventContext]
  );
  const cbFocusModule = useCallback(
    (module) => {
      return eventContext.focusModule(module);
    },
    [eventContext]
  );

  // effect to close menu on module switch
  useEffect(() => {
    if (menuOpen && eventContext.state.event.moduleSelected === startModule) {
      setMenuOpen(false);
    }
  }, [eventContext.state.event.moduleSelected, menuOpen, startModule]);

  // effect on every page render to check for start actions
  useEffect(() => {
    const hydrateEvent = async () => {
      setHydratingEvent(true);
      const eventContent = await cbPrepareJoinEvent({
        name: eventParts.name,
      });
      if (eventContent.alert) {
        cbUpdateAlert(eventContent);
      } else {
        const startData = await cbPrepareStartEvent(eventContent.data);
        if (eventParts.module) {
          cbFocusModule(eventParts.module);
          if (eventParts.module !== "launchpad") {
            eventNavigate(configEntzy.URL_POINTERS.MAIN + eventParts.name, {});
          }
        }
        cbStartEvent(eventContent, startData, "rejoin");
      }
      // reset hydrating event state after a short delay
      setTimeout(() => {
        setHydratingEvent(false);
      }, 1000);
    };

    const hydrateReceivedEvent = async () => {
      // hydrate event from location state
      cbStartEvent(
        location.state.params[0],
        location.state.params[1],
        location.state.params[2]
      );
      // clear location state after callback
      location.state = {};
      eventNavigate(location.pathname, {});
    };

    if (location.state && location.state.startEvent) {
      hydrateReceivedEvent();
    } else if (
      !hydratingEvent &&
      !eventContext.state.event.hydrated &&
      !eventContext.state.event.exploring &&
      !eventContext.state.alert.show
    ) {
      hydrateEvent();
    } else if (
      !hydratingEvent &&
      eventContext.state.event.hydrated &&
      !eventContext.state.alert.show &&
      user.connected &&
      dayjs().diff(eventContext.state.event.expires, "minutes") > 0
    ) {
      hydrateEvent();
    }
  }, [
    hydratingEvent,
    user.connected,
    eventContext.state.event.hydrated,
    eventContext.state.event.exploring,
    eventContext.state.event.expires,
    eventContext.state.alert.show,
    location,
    eventParts,
    eventNavigate,
    cbPrepareJoinEvent,
    cbPrepareStartEvent,
    cbStartEvent,
    cbUpdateAlert,
    cbFocusModule,
  ]);

  // load google maps api
  useEffect(() => {
    if (!window.google) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://maps.googleapis.com/maps/api/js?libraries=places,marker&key=" +
        configEntzy.APP_MAPS_PUBLIC_KEY +
        "&callback=Function.prototype&loading=async";
      const headScript = document.getElementsByTagName("script")[0];
      headScript.parentNode.insertBefore(script, headScript);
    }
  }, []);

  // if not connected capture url for redirect on connect
  useMainRedirect(props);

  // load page images
  const eventLogo = useMediaImage(
    eventContext.state.event.hydrated
      ? {
          user: user,
          context: mainContext,
          image: {
            id: "event:logo:" + eventContext.state.event.data.EventId,
            key: eventContext.state.event.data.Logo,
          },
        }
      : null
  );

  // swipe handlers for module switching
  const swipeHandlers = useSwipeable({
    // onSwiped: (eventData) => console.log("User Swiped!", eventData),
    onSwipedLeft: (eventData) => handleModuleSwitch(1, eventData),
    onSwipedRight: (eventData) => handleModuleSwitch(-1, eventData),
    // onSwipedUp: (eventData) => console.log("User onSwipedUp!", eventData),
    // onSwipedDown: (eventData) => console.log("User onSwipedDown!", eventData),
    // onSwipeStart: (eventData) => console.log("User onSwipeStart!", eventData),
    // onSwiping: (eventData) => console.log("User onSwiping!", eventData),
    // onTap: (eventData) => console.log("User onTap!", eventData),
    // onTouchStartOrOnMouseDown: (eventData) =>
    //   console.log("User onTouchStartOrOnMouseDown!", eventData),
    // onTouchEndOrOnMouseUp: (eventData) =>
    //   console.log("User onTouchEndOrOnMouseUp!", eventData),
  });

  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      {eventContext.state.event.hydrated && eventLogo.ready ? (
        <Box
          id="anchor-event-runner"
          className={
            "box-default" +
            (eventContext.state.event.data.Active ? "" : " bg-black-t10")
          }
          sx={{ pb: configEntzy.APP_SPACING_HL }}
        >
          {/* Event Banner Logo Wrapper */}
          <Box className="box-default relative">
            <Box
              className="box-default"
              sx={{
                "&::before": {
                  content: `''`,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundImage:
                    eventLogo.hydrated && eventLogo.images.lg
                      ? "url(" + eventLogo.images.lg.data + ")"
                      : "url(" + DefaultBanner + ")",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "repeat-y",
                  opacity: 0.5,
                  borderBottom: "1px solid #999",
                },
              }}
            >
              <PullToRefresh
                onRefresh={handleRefresh}
                refreshingContent={<PullLoader />}
                pullingContent={<PullPrompt />}
              >
                <Box className="box-default">
                  {/* header content */}
                  <Box
                    className="box-default"
                    sx={{
                      pb: configEntzy.APP_SPACING_LG,
                    }}
                  >
                    {eventContext.state.alert.show && (
                      <Box
                        className="box-default"
                        sx={{
                          p: configEntzy.APP_SPACING_MD,
                        }}
                      >
                        <ActionAlert
                          severity="warning"
                          message={eventContext.state.alert.message}
                          marginTop={true}
                          onClose={eventContext.updateAlert}
                        />
                      </Box>
                    )}
                    {/* Event ticker */}
                    <Collapse in={menuBarOpen}>
                      <Box className="box-default">
                        <EventTicker {...props} />
                      </Box>
                    </Collapse>
                    {/* <Box
                      className="box-default"
                      sx={{
                        pt: configEntzy.APP_SPACING_MD,
                        pl: configEntzy.APP_SPACING_SM,
                        pr: configEntzy.APP_SPACING_SM,
                      }}
                    >
                      <MenuEventBar
                        {...props}
                        filters={{
                          mini: true,
                          onlyModules:
                            eventContext.state.event.moduleSelected ===
                              "launchpad" ||
                            !eventContext.state.event.accessGranted
                              ? ["exit"]
                              : ["menu", "exit"],
                        }}
                        // menuAction={handleToggleMenu}
                      />
                    </Box> */}
                    {/* Menu and launch counter */}
                    {/* {eventContext.state.event.moduleSelected !== "menu" && (
                    )} */}

                    <Box
                      // className="box-abs full-width relative pop-up"
                      className="box-default"
                      sx={{
                        mt: configEntzy.APP_SPACING_MD2X,
                      }}
                    >
                      <Container maxWidth="lg" disableGutters>
                        {/* <Box className="box-default end-width">
                          <Box
                            className="box-inline left action-pointer"
                            onClick={handleToggleMenuBar}
                            sx={{
                              // pt: configEntzy.APP_SPACING_MD2X,
                              pl: configEntzy.APP_SPACING_MD2X,
                            }}
                          >
                            <Typography variant="subtitle2">
                              <span className="fa-layers fa-3x fa-fw">
                                <FontAwesomeIcon
                                  icon={iconCircle}
                                  color="black"
                                  // transform="shrink-2"
                                />
                                <FontAwesomeIcon
                                  icon={menuBarOpen ? iconMenuUp : iconMenuDown}
                                  color="white"
                                  transform="shrink-8"
                                />
                                {counterMessages > 0 && (
                                  <Fragment>
                                    <FontAwesomeIcon
                                      icon={iconMessages}
                                      color="orange"
                                      transform="shrink-8 right-10"
                                    />
                                    <span
                                      className="fa-layers-counter fa-layers-top-right"
                                      style={{
                                        backgroundColor: "red"
                                          ? "red"
                                          : "white",
                                        color: "white",
                                        border: "1px solid red",
                                      }}
                                    >
                                      {counterMessages}
                                    </span>
                                  </Fragment>
                                )}
                              </span>
                            </Typography>
                          </Box>
                        </Box> */}

                        <Grow in={!menuBarOpen} timeout={1000}>
                          <Box className="box-default" hidden={menuBarOpen}>
                            <MenuEventBar
                              {...props}
                              filters={{
                                mini: true,
                                // core: true,
                                circle: true,
                                shadow: true,
                                hideText: true,
                                smallIcons: true,
                              }}
                            />
                          </Box>
                        </Grow>

                        {/* <Grow in={menuBarOpen} timeout={500}>
                          <Box className="box-default" hidden={!menuBarOpen}>
                            <Box
                              className="box-inline right action-pointer"
                              onClick={() =>
                                eventContext.exitEvent({
                                  mainContext,
                                  eventContext,
                                })
                              }
                              sx={{
                                pr: configEntzy.APP_SPACING_MD2X,
                              }}
                            >
                              <Typography variant="subtitle2">
                                <span className="fa-layers fa-3x fa-fw">
                                  <FontAwesomeIcon
                                    icon={iconCircle}
                                    color="white"
                                    style={{
                                      opacity: 0.2,
                                    }}
                                  />
                                  <FontAwesomeIcon
                                    icon={iconClose}
                                    color="white"
                                    transform="shrink-6"
                                  />
                                </span>
                              </Typography>
                            </Box>
                          </Box>
                        </Grow> */}
                      </Container>
                    </Box>

                    {/* Event title */}
                    <Box
                      className="box-default"
                      // sx={{
                      //   pt: configEntzy.APP_SPACING_SM,
                      // }}
                    >
                      <EventTitleTicker {...props} />
                    </Box>

                    <Box className="box-default">
                      <Container maxWidth="lg">
                        <Box
                          className="box-default"
                          sx={{
                            width: "30%",
                            pt: configEntzy.APP_SPACING_LG,
                          }}
                        >
                          <Box className="box-default half-width">
                            {/* {user.connected &&
                            eventContext.state.event.manager && (
                              <Box
                                className="box-inline"
                                sx={{
                                  pl: configEntzy.APP_SPACING_SM,
                                }}
                              >
                                <RoomRunnerZone {...props} />
                              </Box>
                            )} */}
                            <Typography variant="subtitle2">
                              <span>&nbsp;</span>
                            </Typography>
                          </Box>
                          <Box
                            className="box-default half-width"
                            // sx={{
                            //   pl: configEntzy.APP_SPACING_MD,
                            // }}
                          >
                            <Box
                              className="box-default action-pointer text-right"
                              sx={{
                                pt: configEntzy.APP_SPACING_MD,
                                // pl: configEntzy.APP_SPACING_MD,
                                pb: configEntzy.APP_SPACING_MD,
                              }}
                              onClick={handleToggleMenuBar}
                            >
                              <Typography variant="subtitle2">
                                <span className="fa-layers fa-3x fa-fw">
                                  <FontAwesomeIcon
                                    icon={iconCircle}
                                    color="black"
                                    // transform="shrink-2"
                                  />
                                  <FontAwesomeIcon
                                    icon={
                                      menuBarOpen ? iconMenuUp : iconMenuDown
                                    }
                                    color="white"
                                    transform="shrink-8"
                                  />
                                  {/* {counterMessages > 0 && (
                                      <Fragment>
                                        <FontAwesomeIcon
                                          icon={iconMessages}
                                          color="orange"
                                          transform="shrink-8 right-10"
                                        />
                                        <span
                                          className="fa-layers-counter fa-layers-top-right"
                                          style={{
                                            backgroundColor: "red"
                                              ? "red"
                                              : "white",
                                            color: "white",
                                            border: "1px solid red",
                                          }}
                                        >
                                          {counterMessages}
                                        </span>
                                      </Fragment>
                                    )} */}
                                </span>
                              </Typography>
                            </Box>
                          </Box>
                          {/* <Box
                              className="box-default action-pointer text-left"
                              sx={{
                                pt: configEntzy.APP_SPACING_MD,
                                pl: configEntzy.APP_SPACING_MD,
                                pb: configEntzy.APP_SPACING_MD,
                                visibility:
                                  eventContext.state.event.moduleSelected ===
                                    startModule ||
                                  !eventContext.state.event.accessGranted ||
                                  menuBarOpen
                                    ? "hidden"
                                    : "visible",
                              }}
                              onClick={() =>
                                eventContext.focusModule("launchpad")
                              }
                            >
                              <Box className="box-default">
                                <Typography variant="subtitle2">
                                  <span className="fa-layers fa-fw fa-3x">
                                    <FontAwesomeIcon
                                      icon={iconCircle}
                                      color="black"
                                    />
                                    <FontAwesomeIcon
                                      icon={iconLaunchpadMain}
                                      color="white"
                                      transform="shrink-8"
                                    />
                                    <span className="fa-layers-text">
                                      <TextTag
                                        text={<span>&nbsp;Back&nbsp;</span>}
                                        bgColor="black"
                                        color="white"
                                        rounded={true}
                                        shadowLight={true}
                                      />
                                    </span>
                                  </span>
                                </Typography>
                              </Box>
                            </Box> */}
                          {/* 
                          <Box className="box-default half-width">
                            <Box
                              className="box-default action-pointer text-left"
                              sx={{
                                pt: configEntzy.APP_SPACING_MD,
                                pl: configEntzy.APP_SPACING_MD,
                                pb: configEntzy.APP_SPACING_MD,
                                visibility:
                                  eventContext.state.event.moduleSelected ===
                                    startModule ||
                                  !eventContext.state.event.accessGranted
                                    ? "hidden"
                                    : "visible",
                              }}
                              onClick={() => handleModuleSwitch(-1)}
                            >
                              <Typography variant="subtitle2">
                                <span className="fa-layers fa-fw fa-3x">
                                  <FontAwesomeIcon
                                    icon={iconCircle}
                                    color="white"
                                    transform="shrink-4"
                                  />
                                  <FontAwesomeIcon
                                    icon={iconModuleLeft}
                                    color="black"
                                    transform="shrink-2"
                                  />
                                </span>
                              </Typography>
                            </Box>
                          </Box>
                           */}
                        </Box>
                        <Box
                          className="box-default"
                          sx={{
                            width: "40%",
                          }}
                        >
                          <WidgetLaunchCounter
                            {...props}
                            counterMessages={counterMessages}
                            counterLaunches={counterLaunches}
                          />
                        </Box>
                        <Box
                          className="box-default"
                          sx={{
                            width: "30%",
                            pt: configEntzy.APP_SPACING_LG,
                          }}
                        >
                          <Box
                            className="box-default half-width"
                            // sx={{
                            //   pr: configEntzy.APP_SPACING_MD,
                            // }}
                          >
                            <Box
                              className="box-default action-pointer text-left"
                              sx={{
                                pt: configEntzy.APP_SPACING_MD,
                                // pr: configEntzy.APP_SPACING_MD,
                                pb: configEntzy.APP_SPACING_MD,
                              }}
                              onClick={openTransact}
                            >
                              <Typography variant="subtitle2">
                                <span className="fa-layers fa-3x fa-fw">
                                  <FontAwesomeIcon
                                    icon={iconCircle}
                                    color="black"
                                  />
                                  <FontAwesomeIcon
                                    icon={iconTransact}
                                    color="white"
                                    transform="shrink-8"
                                  />
                                </span>
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="box-default half-width">
                            <Box
                              className="box-default action-pointer"
                              sx={{
                                pt: configEntzy.APP_SPACING_MD,
                                // pr: configEntzy.APP_SPACING_MD,
                                pb: configEntzy.APP_SPACING_MD,
                              }}
                            >
                              <Typography variant="subtitle2">
                                <span>&nbsp;</span>
                              </Typography>
                            </Box>
                          </Box>

                          {/* 
                          <Box className="box-default half-width">
                            <Box
                              className="box-default action-pointer text-right"
                              sx={{
                                pt: configEntzy.APP_SPACING_MD,
                                pr: configEntzy.APP_SPACING_MD,
                                pb: configEntzy.APP_SPACING_MD,
                                visibility:
                                  !eventContext.state.event.accessGranted ||
                                  eventContext.state.event.moduleSelected ===
                                    "messaging"
                                    ? "hidden"
                                    : "visible",
                              }}
                              onClick={() =>
                                eventContext.focusModule("messaging")
                              }
                            >
                              <Typography variant="subtitle2">
                                <span className="fa-layers fa-fw fa-3x">
                                  <FontAwesomeIcon
                                    icon={iconCircle}
                                    color="black"
                                    transform="shrink-2"
                                  />
                                  <FontAwesomeIcon
                                    icon={iconMessages}
                                    color="white"
                                    transform="shrink-8"
                                  />
                                  <span
                                    className="fa-layers-counter fa-layers-top-right"
                                    style={{
                                      backgroundColor:
                                        counterMessages > 0 ? "red" : "white",
                                      color:
                                        counterMessages > 0 ? "white" : "black",
                                      border:
                                        counterMessages > 0
                                          ? "1px solid red"
                                          : "1px solid black",
                                    }}
                                  >
                                    {counterMessages}
                                  </span>
                                </span>
                              </Typography>
                            </Box>
                          </Box>
                            */}
                          {/*
                          <Box className="box-default half-width">
                            <Box
                              className="box-default action-pointer text-right"
                              sx={{
                                pt: configEntzy.APP_SPACING_MD,
                                pr: configEntzy.APP_SPACING_MD,
                                pb: configEntzy.APP_SPACING_MD,
                                visibility:
                                  eventContext.state.event.moduleSelected ===
                                    endModule ||
                                  !eventContext.state.event.accessGranted
                                    ? "hidden"
                                    : "visible",
                              }}
                              onClick={() => handleModuleSwitch(1)}
                            >
                              <Typography variant="subtitle2">
                                <span className="fa-layers fa-fw fa-3x">
                                  <FontAwesomeIcon
                                    icon={iconCircle}
                                    color="white"
                                    transform="shrink-4"
                                  />
                                  <FontAwesomeIcon
                                    icon={iconModuleRight}
                                    color="black"
                                    transform="shrink-2"
                                  />
                                </span>
                              </Typography>
                            </Box>
                          </Box>
                           */}
                        </Box>
                      </Container>
                    </Box>
                    {user.connected && !eventContext.state.event.pinned && (
                      <Box
                        className="box-default"
                        sx={{
                          pt: configEntzy.APP_SPACING_MD,
                          pl: configEntzy.APP_SPACING_LG,
                          pr: configEntzy.APP_SPACING_LG,
                          pb: configEntzy.APP_SPACING_MD,
                        }}
                        hidden={eventContext.state.event.pinned === null}
                      >
                        <ActionLaunchpadPin {...props} banner={true} />
                      </Box>
                    )}
                    {/* Riding tag */}
                    {user.connected && (
                      <Box className="box-default zero-height">
                        <Container maxWidth="lg" disableGutters>
                          <Box
                            className="box-inline right"
                            sx={{
                              width: "14%",
                              // pt: configEntzy.APP_SPACING_SM3X,
                            }}
                          >
                            {!eventContext.state.event.manager &&
                              eventContext.state.event.pinned && (
                                <Typography variant="subtitle2">
                                  <span className="fa-layers fa-fw">
                                    <FontAwesomeIcon
                                      icon={iconRiding}
                                      transform="grow-6 down-10"
                                    />
                                    {/* <FontAwesomeIcon
                                      icon={iconCheck}
                                      transform="shrink-2 left-30 down-10"
                                      color="green"
                                    /> */}
                                  </span>
                                </Typography>
                              )}
                          </Box>
                        </Container>
                      </Box>
                    )}
                  </Box>
                </Box>
              </PullToRefresh>

              {/* end banner and header content */}
            </Box>
          </Box>

          {/* View dependent landing splashes or menu guide */}
          {eventContext.state.event.accessGranted &&
          eventContext.state.event.moduleSelected === "menu" ? (
            <Box
              id="anchor-menu-guide"
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_SM,
              }}
            >
              {/* <EventMap {...props} /> */}
            </Box>
          ) : (
            !eventContext.state.event.accessGranted && (
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_MD2X,
                  mb: configEntzy.APP_SPACING_MD2X,
                  p: configEntzy.APP_SPACING_MD,
                }}
              >
                <WidgetWelcomeSplash {...props} />
              </Box>
            )
          )}

          {/* Event Modules */}
          {eventContext.state.event.accessGranted && (
            <Box className="box-default nooverflow">
              <Container maxWidth="md" disableGutters>
                {EVENT_MODULES.map((module, index) => {
                  return (
                    <Box
                      key={"module-" + module.id}
                      className="box-default"
                      // hidden={
                      //   eventContext.state.event.moduleSelected !== module.id
                      // }
                    >
                      <Slide
                        in={
                          eventContext.state.event.moduleSelected === module.id
                        }
                        direction={slideDirection}
                        // timeout={{
                        //   enter: configEntzy.TRANSITIONS.default,
                        //   exit: configEntzy.TRANSITIONS.default,
                        //   // onEnter={resetOverlay}
                        //   // onEntered={contentLoaded}
                        // }}
                      >
                        <Box
                          className="box-default"
                          sx={{
                            pl: configEntzy.APP_SPACING_MD,
                            pr: configEntzy.APP_SPACING_MD,
                          }}
                        >
                          {/* render module content */}
                          {eventContext.state.event.moduleSelected ===
                            module.id && (
                            <Box className="box-default">
                              <ErrorBoundary>
                                <Suspense fallback={<PageLoader />}>
                                  <Box
                                    className="box-default"
                                    sx={{
                                      pb: configEntzy.APP_SPACING_MD,
                                    }}
                                    {...swipeHandlers}
                                  >
                                    {renderEventRoom({
                                      id: module.id,
                                      user: props.user,
                                      userReload: props.userReload,
                                      page: props.page,
                                      searchParams: props.searchParams,
                                      navigate: props.navigate,
                                      drawerConnectToggle:
                                        props.drawerConnectToggle,
                                      handleModuleSwitch: handleModuleSwitch,
                                      handleToggleMenu: handleToggleMenu,
                                      menuOpen: menuOpen,
                                      menuSelectById: props.menuSelectById,
                                    })}
                                  </Box>
                                </Suspense>
                              </ErrorBoundary>
                            </Box>
                          )}
                        </Box>
                      </Slide>
                    </Box>
                  );
                })}
              </Container>
            </Box>
          )}

          {/* Fixed footer menu */}
          {/* <Box
            className={
              "box-fixed bottom left full-width pop-top " +
              (menuBarOpen ? "bg-dusk" : "")
            }
            hidden={eventContext.state.event.exploring}
          >
            <Box
              className="box-default action-pointer"
              onClick={handleToggleMenuBar}
            >
              <span
                className={
                  "fa-layers fa-fw " + (menuBarOpen ? "fa-2x" : "fa-3x")
                }
              >
                <FontAwesomeIcon
                  icon={iconCircle}
                  transform="grow-2 up-8"
                  color="black"
                />
                <FontAwesomeIcon
                  icon={menuBarOpen ? iconMenuBarDown : iconMenuBarUp}
                  transform="up-8"
                  color="white"
                />
              </span>
            </Box>
            <Grow in={menuBarOpen} timeout={500}>
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD,
                  pb: configEntzy.APP_SPACING_LG,
                  pl: configEntzy.APP_SPACING_MD,
                  pr: configEntzy.APP_SPACING_MD,
                }}
                hidden={!menuBarOpen}
              >
                <MenuEventBar
                  {...props}
                  filters={{
                    mini: true,
                    core: true,
                    circle: true,
                    shadow: true,
                  }}
                />
              </Box>
            </Grow>
          </Box> */}

          {/* Connection prompt for guest runners */}
          <Box
            className="box-fixed bottom-left full-width pop-top"
            sx={{
              p: configEntzy.APP_SPACING_MD,
              textAlign: "left",
            }}
            hidden={!eventContext.state.event.exploring}
          >
            <Container maxWidth="xs" disableGutters>
              <ActionAlert
                severity="info"
                title="Explorer mode"
                message="This is a simulated play area and settings are not saved. Tap here to connect to run your own eventuator."
                onClick={props.drawerConnectToggle}
              />
            </Container>
          </Box>

          {/* Observables idle alert */}
          <Box
            className="box-fixed top-left full-width pop-max"
            sx={{
              p: configEntzy.APP_SPACING_LG,
              mt: configEntzy.APP_SPACING_MD,
              textAlign: "left",
            }}
            hidden={
              !user.connected ||
              mainContext.state.observablesTracker.active ||
              eventContext.state.observablesTracker.active
            }
          >
            <Container maxWidth="md" disableGutters>
              <ActionAlert
                severity="info"
                title="Sleeping"
                message={
                  <span>
                    <span>Move around to wake things up</span>
                    <br />
                    <span>Sleep mode entered after</span>
                    <span>&nbsp;</span>
                    <span>{configEntzy.IDLE_TIMEOUT_MINS}</span>
                    <span>&nbsp;</span>
                    <span>minutes of inactivity</span>
                  </span>
                }
              />
            </Container>
          </Box>
        </Box>
      ) : (
        <Box className="box-default full-height">
          {eventContext.state.alert.show ? (
            <Box
              className="box-pointer"
              sx={{
                p: configEntzy.APP_SPACING_MD,
                textAlign: "center",
              }}
            >
              {/* Any hydrate failures */}
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_LG2X,
                }}
              >
                <Typography variant="h2">
                  <FontAwesomeIcon
                    icon={iconAlert}
                    size="2x"
                    transform="up-10"
                  />
                </Typography>
                <Typography variant="h2">
                  {eventContext.state.alert.message.includes("could not find")
                    ? "Eventuator Not Found"
                    : "Eventuator Could Not Start"}
                </Typography>
              </Box>
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_LG,
                }}
              >
                <Box className="box-inline">
                  <ActionAlert
                    severity="error"
                    // title="Eventuator Alert"
                    message={eventContext.state.alert.message}
                    marginTop={true}
                    onClose={() => {
                      eventContext.updateAlert();
                      props.navigate("/");
                    }}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <PageLoader />
          )}
        </Box>
      )}
      {props.user.connected && (
        <Drawer
          anchor="bottom"
          open={mainContext.state.actionDrawer.open}
          onClose={() => {
            mainContext.setActionDrawer({
              open: false,
              params: {},
            });
          }}
          keepMounted
        >
          <Container maxWidth="md">
            <Box id="achor-event-actions" className="box-default full-height">
              <Box
                className="box-default text-right action-pointer"
                sx={{
                  pt: configEntzy.APP_SPACING_MD2X,
                  pr: configEntzy.APP_SPACING_MD,
                }}
                onClick={() => {
                  mainContext.setActionDrawer({
                    open: false,
                    params: {},
                  });
                }}
              >
                <Typography variant="h3">
                  <FontAwesomeIcon icon={iconClose} />
                </Typography>
              </Box>
              <Box
                className="box-default"
                sx={{
                  pl: configEntzy.APP_SPACING_MD,
                  pr: configEntzy.APP_SPACING_MD,
                }}
              >
                {mainContext.state.actionDrawer.params.room === "launchpad" && (
                  <Box className="box-default">
                    {mainContext.state.actionDrawer.params.section ===
                    "members" ? (
                      <Box className="box-default">
                        <Box className="box-default">
                          <Typography variant="h6" color="primary">
                            Eventuator Members
                          </Typography>
                          <Typography variant="subtitle1" color="white">
                            <em>Manage runners and riders</em>
                          </Typography>
                        </Box>
                        <Box
                          className="box-default"
                          sx={{
                            p: configEntzy.APP_SPACING_MD,
                          }}
                        >
                          <ActionButton
                            color="primary"
                            size="small"
                            text={inviteMembers ? "Close" : "Invite Members"}
                            endIcon={
                              inviteMembers ? (
                                <FontAwesomeIcon icon={iconClose} fixedWidth />
                              ) : (
                                <FontAwesomeIcon icon={iconAdd} fixedWidth />
                              )
                            }
                            onClick={handleToggleInviteMembers}
                          />
                        </Box>
                        {inviteMembers ? (
                          <ActionMembersRunner {...props} />
                        ) : (
                          <ActionLaunchpadRunner
                            {...props}
                            section={
                              mainContext.state.actionDrawer.params.section
                            }
                          />
                        )}
                      </Box>
                    ) : (
                      <Box className="box-default">
                        <ActionLaunchpadRunner
                          {...props}
                          section={
                            mainContext.state.actionDrawer.params.section
                          }
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Container>
        </Drawer>
      )}
      <MainCallbackLoader />
    </ThemeProvider>
  );
}

export default EventRunner;

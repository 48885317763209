// react core
import { useEffect, useState } from "react";

// aws tools
import { Auth } from "aws-amplify";

// device detection
// import { isIOS } from "react-device-detect";

// material design
import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowAltRight as iconArrow } from "@fortawesome/pro-regular-svg-icons";
import { faMobileSignalOut as iconDevice } from "@fortawesome/pro-regular-svg-icons";
import { faArrowRightArrowLeft as iconConnecting } from "@fortawesome/pro-regular-svg-icons";
import { faCloudCheck as iconExternalSite } from "@fortawesome/pro-regular-svg-icons";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";
// import {
//   ActionLoader,
//   ActionAlert,
// } from "components/utils/common/CommonLoaders";
import { ActionButton } from "components/utils/common/CommonButtons";

function ConnectFlow(props) {
  const user = props.user;
  const location = props.location;
  const navigate = props.navigate;
  const drawerConnectToggle = props.drawerConnectToggle;
  const flow = location.state ? location.state.flow : "";
  const init = location.search.replace("?flow=", "");

  // const [safariPrompt, setSafariPrompt] = useState(false);
  // const [safariFlow, setSafariFlow] = useState("");
  // const [loading, setLoading] = useState(false);
  const [openerUrl, setOpenerUrl] = useState(null);

  const basePath = "/connecting";
  const stripeConnectURL =
    configEntzy.STRIPE_CONNECT_URL +
    "?client_id=" +
    configEntzy.STRIPE_CONNECT_ID +
    "&redirect_uri=" +
    encodeURIComponent(configEntzy.STRIPE_CONNECT_REDIRECT_URL) +
    "&stripe_user[email]=" +
    encodeURIComponent(user.email) +
    "&suggested_capabilities[]=card_payments";

  // const safariConfirmPrompt = async () => {
  //   setSafariPrompt(false);
  //   setLoading(true);
  //   return await Auth.federatedSignIn({ provider: safariFlow });
  // };

  // use effect to capture initial flow and navigate to url without query string
  useEffect(() => {
    const initFlow = async () => {
      navigate(basePath, { replace: true, state: { flow: init } });
    };
    if (init && init.length > 0) {
      initFlow();
    }
  }, [init, navigate]);

  useEffect(() => {
    const startFlow = async () => {
      // open the appropriate external link flow
      // where required on ios pause and prompt user
      // for safari default browser requirement until alternative solution
      if (flow === "StripeConnect") {
        setOpenerUrl(stripeConnectURL);
        window.open(stripeConnectURL, "_blank", "noopener,noreferrer");
        // window.location.replace(stripeConnectURL);
      } else if (flow === "SignInWithApple") {
        await Auth.federatedSignIn({ provider: flow });
      } else if (flow === "Google") {
        await Auth.federatedSignIn({ provider: flow });
        // if (isIOS) {
        //   drawerConnectToggle(false);
        //   setSafariFlow(flow);
        //   setSafariPrompt(true);
        // } else {
        //   await Auth.federatedSignIn({ provider: flow });
        // }
      } else if (flow === "LoginWithAmazon") {
        await Auth.federatedSignIn({ provider: flow });
        // if (isIOS) {
        //   drawerConnectToggle(false);
        //   setSafariFlow(flow);
        //   setSafariPrompt(true);
        // } else {
        //   await Auth.federatedSignIn({ provider: flow });
        // }
      } else if (flow === "googleCalendar") {
        // local environment can use the main google redirect base url
        // deployed environments use url that cloudfront listens for server side redirects to accommodate iOS webview issues
        const oauth2Endpoint =
          configEntzy.APP_ENV === "local"
            ? "https://accounts.google.com/o/oauth2/v2/auth"
            : configEntzy.APP_URL + "/google/oauth2/authorize";
        const scope =
          "email profile openid https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events";
        const state =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
        const clientId = configEntzy.APP_GOOGLE_CLIENT_ID;
        const redirectUri = configEntzy.APP_URL;

        let googleCalendarURL = oauth2Endpoint;
        googleCalendarURL += `?client_id=${clientId}`;
        googleCalendarURL += `&redirect_uri=${encodeURIComponent(redirectUri)}`;
        googleCalendarURL += `&scope=${encodeURIComponent(scope)}`;
        googleCalendarURL += `&state=${state}`;
        googleCalendarURL += `&response_type=code`;
        googleCalendarURL += `&prompt=consent`;
        googleCalendarURL += `&access_type=offline`;
        googleCalendarURL += `&include_granted_scopes=true`;
        googleCalendarURL += `&enable_granular_consent=true`;
        googleCalendarURL += `&gsiwebsdk=3`;

        setOpenerUrl(googleCalendarURL);
        window.location.href = googleCalendarURL;

        // window.open(googleCalendarURL, "_self", "noopener,noreferrer");
        // window.open(googleCalendarURL, "_blank", "noopener,noreferrer");
        // window.open(googleCalendarURL, "_blank");
        // window.location.replace(googleCalendarURL);
      }
    };
    if (flow && flow.length > 0) {
      navigate(basePath, {}); // clear location state before starting flow
      setOpenerUrl(null);
      startFlow();
    }
  }, [flow, stripeConnectURL, navigate, drawerConnectToggle]);

  return (
    <Box
      className="box-default bg-black-t25 full-height"
      sx={{ pt: configEntzy.APP_SPACING_HL }}
    >
      {/* {loading && (
        <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_LG }}>
          <ActionLoader />
        </Box>
      )} */}
      {/* {safariPrompt && (
        <Box className="box-default">
          <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_LG }}>
            <Typography variant="h4" noWrap={true}>
              Connecting via{" "}
              {safariFlow.replace("SignInWith", "").replace("LoginWith", "")}
            </Typography>
            <Typography variant="h4" noWrap={true}>
              iOS Browser Check
            </Typography>
          </Box>
          <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_LG }}>
            <Container maxWidth="xs">
              <ActionAlert
                severity="info"
                title="Safari Required"
                message="This connection flow on iOS devices requires Safari set as your default browser (only while connecting). If you use Chrome or any other default then temporarily switch first to continue."
              />
            </Container>
          </Box>
          <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_LG }}>
            <ActionButton
              variant="contained"
              text="Confirm and Continue"
              onClick={safariConfirmPrompt}
            />
          </Box>
        </Box>
      )} */}

      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_LG2X,
          pb: configEntzy.APP_SPACING_LG,
          borderRadius: configEntzy.BORDER_SIZE_XL,
          color: "white",
        }}
      >
        <Typography variant="h5">
          <FontAwesomeIcon
            icon={iconDevice}
            size="3x"
            color="orange"
            fixedWidth
          />
          <span>&nbsp;&nbsp;</span>
          <FontAwesomeIcon
            icon={iconConnecting}
            size="3x"
            className="fa-beat"
            transform="shrink-5"
            style={{
              "--fa-animation-duration": "1.5s",
              "--fa-beat-scale": 1.2,
            }}
            fixedWidth
          />
          <span>&nbsp;&nbsp;&nbsp;</span>
          <FontAwesomeIcon
            icon={iconExternalSite}
            size="3x"
            color="orange"
            fixedWidth
          />
        </Typography>
        <Typography variant="h5" sx={{ mt: configEntzy.APP_SPACING_LG }}>
          Connecting...
        </Typography>
      </Box>
      {openerUrl && (
        <Box className="box-default">
          <Box className="box-default">
            <Typography variant="subtitle2">
              If you are not redirected
            </Typography>
            <Typography variant="subtitle2">
              automatically please tap below
            </Typography>
          </Box>
          <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_LG }}>
            <a
              href={openerUrl}
              className="image-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ActionButton variant="contained" text="Continue" size="medium" />
            </a>
          </Box>
        </Box>
      )}
      <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_LG3X }}>
        <ActionButton
          variant="contained"
          text="Cancel"
          color="bright"
          size="small"
          onClick={() => props.navigate("/")}
        />
      </Box>
    </Box>
  );
}

export default ConnectFlow;

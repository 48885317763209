import { useContext, useEffect } from "react";

// entzy context and services
import { MainContext } from "components/main/MainContext";

// entzy assets
import imageFallback from "assets/banners/crowd-t50.png";

export const useMediaImage = (params) => {
  const mainContext = useContext(MainContext);

  const imageData = params
    ? mainContext.state.interaction.images.find(
        (obj) => obj.id === params.image.id
      ) || { create: true }
    : { create: false };

  // refresh image interaction if key has changed
  if (imageData.key && imageData.key !== params.image.key) {
    imageData.create = true;
  }

  useEffect(() => {
    let didCancel = false;
    const createInteraction = async () => {
      if (!didCancel && imageData.create) {
        const imageInteraction = await mainContext.prepareInteractWithImage({
          id: params.image.id,
          key: params.image.key,
          user: params.user,
        });
        if (imageInteraction.alert) {
          didCancel = true;
        } else {
          imageInteraction.data.ready = true;
          imageInteraction.data.fallback = imageFallback;
          mainContext.interactWithImage(imageInteraction);
        }
      }
    };
    if (params) {
      createInteraction();
    } else {
      didCancel = true;
    }
    return () => {
      didCancel = true;
    };
  }, [mainContext, params, imageData.create]);

  return imageData;
};

// USAGE
// const image = useMediaImage(user, context, "event-logo");

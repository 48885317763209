// react core
import { useContext, useState, useEffect, useCallback, Fragment } from "react";

// google calendar auth
// import { useGoogleLogin } from "@react-oauth/google";

// date handling
import dayjs from "dayjs";

// imported animations
import Marquee from "react-fast-marquee";

// material design
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// entzy context and components
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import InputForm from "components/input/InputForm";
import {
  ActionBoxButton,
  BigButton,
} from "components/utils/common/CommonButtons";
import {
  ActionCheck,
  ActionLoader,
} from "components/utils/common/CommonLoaders";
import { TextTag } from "components/utils/common/CommonTags";
import { jsonTryParse } from "models/Tools";
// import {
//   DateTickingHeader,
//   DateTickingProgressBar,
//   DateTickingBreakdown,
// } from "components/event/lists/ListDates";
import RoomLaunchpadUpdates from "components/event/rooms/RoomLaunchpadUpdates";
import { RoomDatesChartItem } from "components/event/rooms/RoomDatesChart";

// entzy cookie handling
import { serviceCookieGet, serviceCookieClear } from "services/graphql/call";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExplosion as iconLaunch } from "@fortawesome/pro-regular-svg-icons";
import { faChartSimpleHorizontal as iconTicking } from "@fortawesome/pro-duotone-svg-icons";
import { faChartLineDown as iconExpired } from "@fortawesome/pro-duotone-svg-icons";
import { faDoorOpen as iconEntry } from "@fortawesome/pro-regular-svg-icons";
import { faNote as iconDirect } from "@fortawesome/pro-regular-svg-icons";
import { faLink as iconExternal } from "@fortawesome/pro-regular-svg-icons";
import { faExplosion as iconLoading } from "@fortawesome/pro-duotone-svg-icons";
import { faSignalStream as iconListener } from "@fortawesome/pro-thin-svg-icons";
import { faCalendarDay as iconCalendar } from "@fortawesome/pro-thin-svg-icons";
import { faMapPin as iconLocation } from "@fortawesome/pro-solid-svg-icons";
import { faPlusSquare as iconAdd } from "@fortawesome/pro-solid-svg-icons";
import { faCircle as iconCircle } from "@fortawesome/pro-solid-svg-icons";
// import { faUpload as iconPush } from "@fortawesome/pro-solid-svg-icons";
// import { faDownload as iconPull } from "@fortawesome/pro-solid-svg-icons";
import { faLink as iconLink } from "@fortawesome/pro-solid-svg-icons";
import { faUnlink as iconUnlink } from "@fortawesome/pro-solid-svg-icons";
import { faEdit as iconEdit } from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt as iconDelete } from "@fortawesome/pro-solid-svg-icons";
import { faExternalLinkAlt as iconGo } from "@fortawesome/pro-regular-svg-icons";
import { faRefresh as iconRefresh } from "@fortawesome/pro-regular-svg-icons";
import { faClose as iconClose } from "@fortawesome/pro-regular-svg-icons";
import { faChevronRight as iconIncrement } from "@fortawesome/pro-thin-svg-icons";
import { faChevronLeft as iconDecrement } from "@fortawesome/pro-thin-svg-icons";
import { faChevronDown as iconDrop } from "@fortawesome/pro-thin-svg-icons";
import { faCog as iconCalendarSettings } from "@fortawesome/pro-solid-svg-icons";
import { faCalendarDays as iconCompactViewON } from "@fortawesome/pro-solid-svg-icons";
import { faCalendarLines as iconCompactViewOFF } from "@fortawesome/pro-solid-svg-icons";
import { faGoogle as iconGoogle } from "@fortawesome/free-brands-svg-icons";
import { faApple as iconApple } from "@fortawesome/free-brands-svg-icons";

function EventCalendar(props) {
  const user = props.user;
  const limit = 500;
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);
  const eventView = props.eventView;

  const [viewDate, setViewDate] = useState(
    dayjs(mainContext.state.viewer.calendar.viewDate, "YYYY-MM-DD")
  );
  const [expandView, setExpandView] = useState(props.calendarView);
  const [timelineEntryView, setTimelineEntryView] = useState(null);
  const [timelineEntryAdd, setTimelineEntryAdd] = useState(null);
  const [timelineEntryEdit, setTimelineEntryEdit] = useState(null);
  const [loadingEntry, setLoadingEntry] = useState(null);
  const [loadingCalendar, setLoadingCalendar] = useState(false);

  const [showMonthSelector, setShowMonthSelector] = useState(false);
  const [showCalendarSelector, setShowCalendarSelector] = useState(false);
  const [showEventCompare, setShowEventCompare] = useState(false);

  const [dialog, setDialog] = useState({
    visible: false,
  });

  const getExternalEntryProvider = (entry) => {
    const provider = entry.EventUrl
      ? entry.EventUrl.startsWith("https://www.google.com")
        ? "google"
        : entry.EventUrl.startsWith("https://www.apple.com")
        ? "apple"
        : "entzy"
      : "entzy";
    return provider;
  };
  const getExternalEntryDescription = (entry) => {
    let description = entry.Description ? entry.Description : "";
    if (
      ["runner-launch", "rider-launch", "runner-event", "rider-event"].includes(
        entry.Category
      )
    ) {
      description += "\n";
      description += configEntzy.APP_URL;
      description += configEntzy.URL_POINTERS.MAIN;
      description += entry.EventUrl;
    }
    return description;
  };

  const activateCalendar = async (provider, code) => {
    let response;
    setLoadingCalendar(true);
    response = await mainContext.prepareOauthFlow({
      provider,
      code,
    });
    if (response.success) {
      const data = jsonTryParse(response.data, {
        error: "Calendar login failed for provider " + provider,
      });
      if (data.error) {
        response.alert = true;
        response.message = data.error;
        mainContext.updateAlert(response);
      } else {
        mainContext.setToken({
          name: provider + "AccessToken",
          value: data.access_token,
        });
        mainContext.setToken({
          name: provider + "TokenExpires",
          value: dayjs().add(data.expires_in, "seconds"),
        });
        mainContext.resetViewerCalendar();
        mainContext.updateExternalCalendar({
          name: provider,
          connected: true,
        });
        // reset any alerts and refresh calendar
        mainContext.updateAlert();
        response = await mainContext.preparePullViewerCalendar({
          viewDate: viewDate,
          limit: limit,
          nextToken: null,
          noCache: true,
          tokenOverrides: {
            [provider + "AccessToken"]: data.access_token,
          },
        });
        if (response.alert) {
          mainContext.updateAlert(response);
        } else {
          mainContext.pullViewerCalendar(response);
        }
      }
    } else {
      response.alert = true;
      response.message = "Calendar login error for provider " + provider;
      mainContext.updateAlert(response);
    }
    setLoadingCalendar(false);
  };
  const cbActivateCalendar = useCallback(activateCalendar, [
    viewDate,
    mainContext,
  ]);

  const googleLogin = () => props.menuSelectById("connecting:googleCalendar");

  // const googleLogin = useGoogleLogin({
  //   clientId: configEntzy.APP_GOOGLE_CLIENT_ID,
  //   scope:
  //     "email profile openid https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events",
  //   flow: "auth-code",
  //   ux_mode: "redirect",
  //   redirect_uri: configEntzy.APP_URL,
  //   onSuccess: async (tokenResponse) => {
  //     const code = tokenResponse.code;
  //     activateCalendar("google", code);
  //   },
  //   onError: (error) => {
  //     mainContext.updateAlert({
  //       alert: true,
  //       message: "Google login error. Please try again.",
  //     });
  //   },
  // });

  // effect to listen for google calendar cookie after redirect flow
  useEffect(() => {
    let cookie, provider, code, state, connected;
    cookie = serviceCookieGet(configEntzy.CALENDAR_OAUTH_COOKIE);
    if (cookie && !loadingCalendar) {
      cookie = jsonTryParse(cookie, {});
      provider = cookie.provider;
      code = cookie.code;
      state = mainContext.state.viewer.calendar.external.find(
        (obj) => obj.name === provider
      );
      connected = state ? state.connected : false;
      if (provider && code && !connected) {
        serviceCookieClear(configEntzy.CALENDAR_OAUTH_COOKIE);
        cbActivateCalendar(provider, code);
      }
    }
  }, [loadingCalendar, mainContext.state.viewer.calendar, cbActivateCalendar]);

  const handleAddExternalCalendar = async (calendar) => {
    if (calendar.name === "google") googleLogin();
  };
  const handleConfirmRemoveExternalCalendar = async (
    event,
    calendar,
    confirm
  ) => {
    if (confirm === true) {
      setDialog({ visible: false });
      return await handleRemoveExternalCalendar(calendar);
    } else if (confirm === false) {
      setDialog({ visible: false });
    } else {
      setDialog({
        visible: true,
        title: "Confirm Remove Calendar",
        description:
          "Are you sure you want to disconnect " +
          calendar.name +
          " calendar? You can reconnect it any time.",
        cancel: (e) => handleConfirmRemoveExternalCalendar(e, calendar, false),
        confirm: (e) => handleConfirmRemoveExternalCalendar(e, calendar, true),
      });
    }
  };
  const handleRemoveExternalCalendar = async (calendar) => {
    setLoadingCalendar(true);
    const response = await mainContext.prepareOauthFlow({
      provider: calendar.name,
      refreshAction: "delete",
    });
    if (response.success) {
      const data = jsonTryParse(response.data, {
        error: "Calendar disconnect failed. Give it another go.",
      });
      if (data.error) {
        response.alert = true;
        response.message = data.error;
        mainContext.updateAlert(response);
      } else {
        mainContext.setToken({
          name: calendar.name + "AccessToken",
          value: null,
        });
        mainContext.setToken({
          name: calendar.name + "TokenExpires",
          value: null,
        });
        mainContext.resetViewerCalendar();
        calendar.connected = false;
        mainContext.updateExternalCalendar(calendar);
        await handlePullCalendar(viewDate, {
          noCache: true,
          tokenOverrides: {
            [calendar.name + "AccessToken"]: "null",
          },
        });
      }
    } else {
      response.alert = true;
      response.message = "Calendar disconnect error. Give it another go.";
      mainContext.updateAlert(response);
    }
  };

  const handleExternalEntryUpdate = async (calendar, update) => {
    setLoadingEntry(update.entry.DateId);
    // when unlinking target the external entry
    if (update.entry.UnlinkId) {
      update.entry.SourceId = update.entry.DateId;
      update.entry.DateId = update.entry.UnlinkId;
    }
    // prepare to capture final month data as output
    let response, externalEntryId, externalEntryUrl, finalMonthData;
    // check for any paired direct entries
    const monthData =
      mainContext.state.viewer.calendar[viewDate.format("YYYY-MM")].data;
    const pairedInternalEntries = monthData
      .filter((day) => day.DateKey === update.entry.DateKey)[0]
      .Entries.filter(
        (entry) =>
          entry.Category !== "external-entry" &&
          (entry.DateId === update.entry.DateId ||
            (entry.DateMeta && entry.DateMeta.includes(update.entry.DateId)))
      );
    // provide any custom description updates for external entries
    update.entry.ExternalDescription = getExternalEntryDescription(
      update.entry
    );
    // update the external calendar entry
    response = await mainContext.prepareUpdateExternalCalendarEntry({
      calendar: calendar,
      delete: update.delete,
      update: update.update,
      create: update.create,
      entry: update.entry,
    });
    if (response.alert) {
      setLoadingEntry(null);
      mainContext.updateAlert(response);
      return response;
    }
    externalEntryId = update.delete ? null : response.entry.DateId;
    externalEntryUrl = update.delete ? null : response.entry.EventUrl;
    // create first iteration of udpated month data
    response = await mainContext.prepareUpdateCalendarMonthData(response);
    finalMonthData = response.monthData;
    // then update the paired direct entries
    for (let entry of pairedInternalEntries) {
      const dateMeta = entry.DateMeta ? jsonTryParse(entry.DateMeta, {}) : {};
      dateMeta[calendar.name + "ExternalEntryId"] = externalEntryId;
      dateMeta[calendar.name + "ExternalEntryUrl"] = externalEntryUrl;
      response = await mainContext.prepareUpdateCalendarEntry({
        update: true,
        entry: {
          DateKey: entry.DateKey,
          DateId: entry.DateId,
          Description: entry.Description ? entry.Description : "",
          DateMeta: dateMeta,
        },
      });
      if (response.alert) {
        setLoadingEntry(null);
        mainContext.updateAlert(response);
        return response;
      }
      // feed last iteration month data into next iteration
      response = await mainContext.prepareUpdateCalendarMonthData({
        ...response,
        monthData: finalMonthData,
      });
      finalMonthData = response.monthData;
    }
    // make final update to month data
    mainContext.updateCalendarMonthData(finalMonthData);
    setLoadingEntry(null);
  };

  const handleShowMonthSelector = () => {
    setShowMonthSelector(!showMonthSelector);
  };

  const handleShowCalendarSelector = () => {
    setShowCalendarSelector(!showCalendarSelector);
  };

  const handleCompactView = () => {
    mainContext.updateViewerTrail({
      calendarCompactView: !mainContext.state.viewer.trail.calendarCompactView,
    });
  };

  const handlePullCalendar = async (newDate, options) => {
    setLoadingCalendar(true);
    const response = await mainContext.preparePullViewerCalendar({
      viewDate: newDate,
      limit: limit,
      nextToken: null,
      ...options,
    });
    if (response.alert) {
      mainContext.updateAlert(response);
    } else {
      mainContext.pullViewerCalendar(response);
    }
    setLoadingCalendar(false);
  };

  const handleTimelineEntryView = (dateId) => {
    setTimelineEntryView(timelineEntryView === dateId ? null : dateId);
  };
  const handleTimelineEntryAdd = (dateKey) => {
    setTimelineEntryAdd(timelineEntryAdd === dateKey ? null : dateKey);
  };
  const handleEditEntryView = (dateId) => {
    setTimelineEntryEdit(timelineEntryEdit === dateId ? null : dateId);
  };

  const handleTimelineEntryUpdate = async (update) => {
    setLoadingEntry(
      update.entry.DateId ? update.entry.DateId : update.entry.DateKey
    );
    let response, finalMonthData;
    response = await mainContext.prepareUpdateCalendarEntry({
      delete: update.delete,
      update: update.update,
      create: update.create,
      entry: update.entry,
    });
    if (response.alert) {
      setLoadingEntry(null);
      mainContext.updateAlert(response);
      return response;
    }
    response = await mainContext.prepareUpdateCalendarMonthData(response);
    finalMonthData = response.monthData;
    // check for any paired external entries for updates and deletes
    if (!update.create) {
      const monthData =
        mainContext.state.viewer.calendar[viewDate.format("YYYY-MM")].data;
      const changedItem = monthData
        .filter((day) => day.DateKey === update.entry.DateKey)[0]
        .Entries.filter((entry) => entry.DateId === update.entry.DateId)[0];
      const dateMeta = changedItem.DateMeta
        ? jsonTryParse(changedItem.DateMeta, {})
        : {};
      for (let calendar of mainContext.state.viewer.calendar.external.filter(
        (obj) => obj.connected
      )) {
        const externalEntryId = dateMeta[calendar.name + "ExternalEntryId"];
        if (externalEntryId) {
          update.entry.ExternalDescription = getExternalEntryDescription(
            update.entry
          );
          response = await mainContext.prepareUpdateExternalCalendarEntry({
            update: update.update,
            delete: update.delete,
            calendar: calendar,
            entry: {
              DateKey: update.entry.DateKey,
              DateId: externalEntryId,
              Description: update.entry.Description
                ? update.entry.Description
                : "",
              ExternalDescription: update.entry.ExternalDescription,
            },
          });
          if (response.alert) {
            setLoadingEntry(null);
            mainContext.updateAlert(response);
            return response;
          }
          // feed last iteration month data into next iteration
          response = await mainContext.prepareUpdateCalendarMonthData({
            ...response,
            monthData: finalMonthData,
          });
          if (response.alert) {
            setLoadingEntry(null);
            mainContext.updateAlert(response);
            return response;
          }
          finalMonthData = response.monthData;
        }
      }
    }
    // make final update to month data
    mainContext.updateCalendarMonthData(finalMonthData);
    setLoadingEntry(null);
    setTimelineEntryAdd(null);
    return response;
  };
  const handleTimelineEntryCreate = async (data) => {
    const description = data.filter((obj) => obj.id === "entry-description")[0]
      .value;
    const update = {
      create: true,
      entry: {
        DateKey: timelineEntryAdd,
        Description: description,
      },
    };
    return await handleTimelineEntryUpdate(update);
  };
  const handleTimelineEntryEdit = async (data) => {
    const description = data.filter((obj) => obj.id === "entry-description")[0]
      .value;
    const update = {
      update: true,
      entry: {
        DateKey: timelineEntryEdit.split(":")[0],
        DateId: timelineEntryEdit,
        Description: description,
      },
    };
    return await handleTimelineEntryUpdate(update);
  };
  const handleEntryDelete = async (event, data, confirm) => {
    if (confirm === true) {
      return await handleTimelineEntryUpdate({
        delete: true,
        entry: {
          DateKey: data.DateKey,
          DateId: data.DateId,
        },
      });
    } else if (confirm === false) {
      setDialog({ visible: false });
    } else {
      setDialog({
        visible: true,
        title: "Confirm Remove Entry",
        description:
          "Are you sure you want to permanently remove this calendar entry? This action cannot be undone.",
        cancel: (e) => handleEntryDelete(e, data, false),
        confirm: (e) => handleEntryDelete(e, data, true),
      });
    }
  };

  const handleDateSelect = (dateKey) => {
    const newDate = dayjs(dateKey, "YYYY-MM-DD");
    setViewDate(newDate);
    if (eventView) {
      setShowEventCompare(true);
    }
    handlePullCalendar(newDate);
  };
  const handleChangeMonth = (fieldId, selection) => {
    const newDate = viewDate.add(selection.value, "month");
    setViewDate(newDate);
    handlePullCalendar(newDate);
  };
  const handleDecrementMonth = () => {
    const newDate = viewDate.add(-1, "month");
    setViewDate(newDate);
    handlePullCalendar(newDate);
  };
  const handleIncrementMonth = () => {
    const newDate = viewDate.add(1, "month");
    setViewDate(newDate);
    handlePullCalendar(newDate);
  };
  const handleExpandView = () => {
    const newView = !expandView;
    setExpandView(newView);
    props.handleCalendarView(newView);
  };
  const handleShowEventCompare = () => {
    setShowEventCompare(!showEventCompare);
  };

  const handleNavigateEvent = async (url, newTab) => {
    if (newTab) {
      window.open(url, "_blank", "noopener noreferrer");
    } else {
      mainContext.setCallbackLoader(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      const eventContent = await eventContext.prepareJoinEvent({
        name: url,
      });
      if (eventContent.alert) {
        mainContext.updateAlert({
          show: true,
          message: eventContent.message,
        });
      }
      const startData = await eventContext.prepareStartEvent(eventContent.data);
      const startPayload = {
        startEvent: true,
        params: [eventContent, startData, "join"],
      };
      // mainContext.updateViewerTrail({
      //   sourcePage: "home",
      //   joinTab: 1,
      //   joinSubTab: tab,
      // });
      mainContext.selectMenu("e");
      props.navigate(configEntzy.URL_POINTERS.MAIN + url, {
        state: startPayload,
      });
      mainContext.setCallbackLoader(false);
      // window.location.href =
      //   configEntzy.APP_URL + configEntzy.URL_POINTERS.MAIN + url;
      // props.navigate(configEntzy.URL_POINTERS.MAIN + url);
      // window.location.reload();
    }
  };

  const generateMonthsSelectable = (activeDate) => {
    const selectableList = [];
    let value, date, selectable;
    Array.from(Array(12 * 4).keys()).forEach((index) => {
      selectable = {};
      value = -12 + index;
      date = activeDate.add(value, "month").format("MMM YYYY");
      selectable.id = "month-" + value;
      selectable.name = date;
      selectable.value = value;
      selectable.display = date;
      selectable.icon = iconCalendar;
      selectableList.push(selectable);
    });
    return selectableList;
  };

  const handleRefreshView = () => {
    handlePullCalendar(viewDate, { noCache: true });
  };

  const renderEventUrl = (item) => {
    switch (item.Category) {
      case "direct-entry":
        return (
          <Fragment>
            <span style={{ color: "orange" }}>
              {configEntzy.URL_POINTERS.BOT_STYLED_HELPER}
              &nbsp;&nbsp;
            </span>
            {!mainContext.state.viewer.trail.calendarCompactView && (
              <span style={{ color: "orange" }}>
                <em>Direct Entry</em>
              </span>
            )}
          </Fragment>
        );
      case "external-entry":
        const provider = getExternalEntryProvider(item);
        return (
          <Fragment>
            {provider === "google" ? (
              <span style={{ color: "orange" }}>
                <FontAwesomeIcon icon={iconGoogle} transform="shrink-4" />
                <span>/&nbsp;&nbsp;</span>
                {!mainContext.state.viewer.trail.calendarCompactView && (
                  <span style={{ color: "orange" }}>
                    <em>Google Entry</em>
                  </span>
                )}
              </span>
            ) : provider === "apple" ? (
              <span style={{ color: "orange" }}>
                <FontAwesomeIcon icon={iconApple} transform="shrink-4" />
                <span>/&nbsp;&nbsp;</span>
                {!mainContext.state.viewer.trail.calendarCompactView && (
                  <span style={{ color: "orange" }}>
                    <em>Apple Entry</em>
                  </span>
                )}
              </span>
            ) : (
              <span>
                {!mainContext.state.viewer.trail.calendarCompactView && (
                  <span style={{ color: "orange" }}>
                    <em>External Entry</em>
                  </span>
                )}
              </span>
            )}
          </Fragment>
        );
      default:
        return (
          <Fragment>
            /e/<span className="lower-case">{item.EventUrl}</span>
          </Fragment>
        );
    }
  };
  const renderCategoryText = (item) => {
    switch (item.Category) {
      case "direct-entry":
        return item.Description ? item.Description : "Empty Entry";
      case "external-entry":
        return item.Description ? item.Description : "Empty Entry";
      case "runner-launch":
        return "Date trigger!\nStart preparing for run date coming up...";
      case "rider-launch":
        return "Ticket trigger!\nGet ready for ride date coming up...";
      case "runner-event":
        return "Date is ON. Showtime.";
      case "rider-event":
        return "Date is ON. Time for entry.";
      default:
        return item.Category.replace("-", " ");
    }
  };
  const renderCategory = (item, modal) => {
    const compactMax = 20;

    if (["direct-entry", "external-entry"].includes(item.Category)) {
      return (
        <Fragment>
          <span className="text-paragraph">
            {!item.Description ? (
              "Empty entry"
            ) : mainContext.state.viewer.trail.calendarCompactView && !modal ? (
              <span>
                {item.Description.length > compactMax
                  ? item.Description.substr(0, compactMax) + "..."
                  : item.Description}
              </span>
            ) : (
              item.Description
            )}
          </span>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <span className="text-paragraph">{renderCategoryText(item)}</span>
      </Fragment>
    );
  };

  const renderDateInfo = (item) => {
    const spanDelim = " > ";
    switch (item.Category) {
      case "runner-launch":
        return (
          <Fragment>
            <span>
              {dayjs(item.DateKey, "YYYY-MM-DD").format("DD MMM") +
                spanDelim +
                dayjs(item.entryDate, "YYYY-MM-DD").format("DD MMM YYYY")}
            </span>
          </Fragment>
        );
      case "rider-launch":
        return (
          <Fragment>
            <span>
              {dayjs(item.DateKey, "YYYY-MM-DD").format("DD MMM") +
                spanDelim +
                dayjs(item.entryDate, "YYYY-MM-DD").format("DD MMM YYYY")}
            </span>
          </Fragment>
        );
      case "runner-event":
        return (
          <Fragment>
            <span>
              {dayjs(item.DateKey, "YYYY-MM-DD").format("DD MMM YYYY")}
            </span>
          </Fragment>
        );
      case "rider-event":
        return (
          <Fragment>
            <span>
              {dayjs(item.DateKey, "YYYY-MM-DD").format("DD MMM YYYY")}
            </span>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            {dayjs(item.DateKey, "YYYY-MM-DD").format("DD MMM YYYY")}
          </Fragment>
        );
    }
  };

  const renderDateType = (item) => {
    switch (item.Category) {
      case "runner-launch":
        return (
          <Fragment>
            {item.noticeDays >= 0 ? (
              <span>
                Triggered @ {item.noticeDays} day{item.noticeDays !== 1 && "s"}
              </span>
            ) : (
              <span>Launch</span>
            )}
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span>
              <FontAwesomeIcon
                icon={iconLaunch}
                style={{ color: "orange" }}
                fixedWidth
              />
            </span>
          </Fragment>
        );
      case "rider-launch":
        return (
          <Fragment>
            {item.noticeDays >= 0 ? (
              <span>
                Triggered @ {item.noticeDays} day{item.noticeDays !== 1 && "s"}
              </span>
            ) : (
              <span>Launch</span>
            )}
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span>
              <FontAwesomeIcon
                icon={iconLaunch}
                style={{ color: "orange" }}
                fixedWidth
              />
            </span>
          </Fragment>
        );
      case "runner-event":
        return (
          <Fragment>
            <span>Run Date</span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span>
              <FontAwesomeIcon icon={iconEntry} fixedWidth />
            </span>
          </Fragment>
        );
      case "rider-event":
        return (
          <Fragment>
            <span>Entry Date</span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span>
              <FontAwesomeIcon icon={iconEntry} fixedWidth />
            </span>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <span>&nbsp;</span>
          </Fragment>
        );
    }
  };

  const renderOfferInfo = (item) => {
    switch (item.Category) {
      case "runner-launch":
        return (
          <Fragment>
            <span>&nbsp;</span>
          </Fragment>
        );
      case "rider-launch":
        return (
          <Fragment>
            <span>
              {item.TicketQuantity} x {item.TicketDescription}
            </span>
          </Fragment>
        );
      case "runner-event":
        return (
          <Fragment>
            <span>&nbsp;</span>
          </Fragment>
        );
      case "rider-event":
        return (
          <Fragment>
            <span>
              {item.TicketQuantity} x {item.TicketDescription}
            </span>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <span>&nbsp;</span>
          </Fragment>
        );
    }
  };

  const RenderTimeLineItem = (props) => {
    const item = props.entry;
    const entries = props.entries;
    const meta = item && item.DateMeta ? jsonTryParse(item.DateMeta, {}) : {};
    const entryDate = meta.EntryDate ? meta.EntryDate : "2000-01-01";
    const noticeDays = item
      ? dayjs(entryDate, "YYYY-MM-DD").diff(
          dayjs(item.DateKey, "YYYY-MM-DD"),
          "days"
        )
      : 0;
    if (item) {
      item.entryDate = entryDate;
      item.noticeDays = noticeDays;
    }
    // check meta for entries paired to external calendars
    const pairedMeta = {};
    pairedMeta.active = false;
    mainContext.state.viewer.calendar.external
      .filter((obj) => obj.connected)
      .forEach((calendar) => {
        const externalEntryId = meta[calendar.name + "ExternalEntryId"] || null;
        const externalEntryUrl =
          meta[calendar.name + "ExternalEntryUrl"] || null;
        pairedMeta[calendar.name] = { externalEntryId, externalEntryUrl };
        pairedMeta.active = externalEntryId ? true : pairedMeta.active;
      });
    // check external DateIds in all entries to see if paired to a source
    const pairedEntries = entries.filter((entry) => {
      return entry.DateMeta && entry.DateMeta.includes(item.DateId);
    });
    if (pairedEntries.length > 0) {
      pairedMeta.active = true;
    }
    // create a friendly date display
    const dateDisplay = dayjs(item.DateKey, "YYYY-MM-DD").format(
      "ddd DD MMM YYYY"
    );

    const ItemContent = (props) => {
      const compactView =
        mainContext.state.viewer.trail.calendarCompactView && !props.modal;
      return (
        <Box className="box-default">
          {item && (
            <Box
              className={
                "box-default rounded text-left custom-scrollbar " +
                (pairedMeta.active
                  ? "shadow-highlight bg-black-t75"
                  : item.Category.includes("launch")
                  ? "shadow-basic"
                  : "bg-white-t50") +
                (compactView ? " text-fade" : "")
              }
              sx={{
                p: compactView
                  ? configEntzy.APP_SPACING_SM2X
                  : configEntzy.APP_SPACING_MD,
                // minWidth: configEntzy.AVATAR_SIZE_MD,
                maxHeight: compactView
                  ? configEntzy.AVATAR_CONTAINER_MD
                  : configEntzy.AVATAR_CONTAINER_XL,
                borderTopLeftRadius: configEntzy.BORDER_SIZE_XL,
                borderTopRightRadius: configEntzy.BORDER_SIZE_XL,
                borderBottomLeftRadius: compactView
                  ? 0
                  : configEntzy.BORDER_SIZE_XL,
                borderBottomRightRadius: compactView
                  ? 0
                  : configEntzy.BORDER_SIZE_XL,
                backgroundColor: !item
                  ? "transparent"
                  : item.Category.includes("launch")
                  ? "transparent"
                  : item.Category.includes("runner")
                  ? "secondary.light"
                  : item.Category.includes("rider")
                  ? "tertiary.main"
                  : item.Category.includes("external")
                  ? "white"
                  : "black",
                color: !item
                  ? "white"
                  : item.Category.includes("launch")
                  ? "white"
                  : item.Category.includes("runner")
                  ? "orange"
                  : item.Category.includes("rider")
                  ? "orange"
                  : item.Category.includes("external")
                  ? "black"
                  : "white",
                overflowX: "hidden",
                overflowY: "auto",
              }}
              hidden={item.Category.includes("external") && pairedMeta.active}
            >
              {/* header */}
              <Box
                className="box-default action-pointer"
                onClick={() => handleTimelineEntryView(item.DateId)}
              >
                <Typography
                  variant="body2"
                  sx={{
                    pb: configEntzy.APP_SPACING_SM,
                    borderBottom: "1px solid #ccc",
                  }}
                  noWrap
                >
                  {renderEventUrl(item)}
                  {meta.Color && (
                    <FontAwesomeIcon
                      icon={iconCircle}
                      color={meta.Color}
                      className="fa-pull-right"
                      fixedWidth
                    />
                  )}
                </Typography>
              </Box>
              {/* main content */}
              <Box
                className="box-default action-pointer"
                onClick={() => handleTimelineEntryView(item.DateId)}
                sx={{
                  pb: configEntzy.APP_SPACING_SM,
                }}
              >
                <Typography
                  variant={compactView ? "body2" : "subtitle2"}
                  sx={{
                    mt: configEntzy.APP_SPACING_SM,
                  }}
                  noWrap={compactView ? true : false}
                >
                  {renderCategory(item, props.modal)} {renderOfferInfo(item)}
                </Typography>
              </Box>
              {/* footer and navigation buttons */}
              <Box className="box-default" hidden={compactView}>
                {meta.Location && !compactView && (
                  <Box
                    className="box-default shadow-light"
                    sx={{
                      mt: configEntzy.APP_SPACING_MD2X,
                      p: configEntzy.APP_SPACING_SM,
                      borderRadius: configEntzy.BORDER_SIZE_LG,
                    }}
                  >
                    <Box className="box-default end-width">
                      <Typography variant="body1">
                        <FontAwesomeIcon icon={iconLocation} fixedWidth />
                      </Typography>
                    </Box>
                    <Box className="box-default nt-width">
                      <Typography variant="body1">{meta.Location}</Typography>
                    </Box>
                  </Box>
                )}
                <Box
                  className="box-default"
                  sx={{
                    pt: compactView
                      ? configEntzy.APP_SPACING_MD
                      : configEntzy.APP_SPACING_MD2X,
                  }}
                  hidden={loadingEntry === item.DateId}
                >
                  {[
                    "runner-launch",
                    "rider-launch",
                    "runner-event",
                    "rider-event",
                    "external-entry",
                  ].includes(item.Category) && (
                    <Box
                      className={compactView ? "box-default" : "box-inline"}
                      sx={{
                        mt: compactView
                          ? configEntzy.APP_SPACING_SM
                          : configEntzy.APP_SPACING_SM3X,
                        mr: compactView ? 0 : configEntzy.APP_SPACING_SM3X,
                        display: item.Category.includes("external")
                          ? "inline-block"
                          : eventView &&
                            eventContext.state.event.data.Url === item.EventUrl
                          ? "none"
                          : "inline-block",
                      }}
                    >
                      <TextTag
                        textNoCasing={true}
                        rounded={true}
                        bgColor="primary.main"
                        color="black"
                        fullWidth={true}
                        text={
                          <span>
                            {item.Category.includes("external") ? (
                              <span>Jump&nbsp;&nbsp;</span>
                            ) : (
                              <span>Open&nbsp;&nbsp;</span>
                            )}
                            <FontAwesomeIcon
                              icon={iconGo}
                              className="fa-pull-right"
                              transform="down-4"
                              fixedWidth
                            />
                          </span>
                        }
                        size={compactView ? "sm" : "md"}
                        onClick={() =>
                          handleNavigateEvent(
                            item.EventUrl,
                            item.Category.includes("external") ? true : false
                          )
                        }
                      />
                    </Box>
                  )}
                  {item.Category.startsWith("direct") && (
                    <Box
                      className={compactView ? "box-default" : "box-inline"}
                      sx={{
                        mt: compactView
                          ? configEntzy.APP_SPACING_SM
                          : configEntzy.APP_SPACING_SM3X,
                        mr: compactView ? 0 : configEntzy.APP_SPACING_SM3X,
                      }}
                    >
                      <TextTag
                        textNoCasing={true}
                        rounded={true}
                        bgColor="secondary.main"
                        color="black"
                        fullWidth={true}
                        text={
                          <span>
                            <span>Edit&nbsp;&nbsp;</span>
                            <FontAwesomeIcon
                              icon={
                                timelineEntryEdit === item.DateId
                                  ? iconClose
                                  : iconEdit
                              }
                              className="fa-pull-right"
                              transform="down-4"
                              fixedWidth
                            />
                          </span>
                        }
                        size={compactView ? "sm" : "md"}
                        onClick={() => handleEditEntryView(item.DateId)}
                      />
                    </Box>
                  )}
                  <Box
                    className={compactView ? "box-default" : "box-inline"}
                    sx={{
                      mt: compactView
                        ? configEntzy.APP_SPACING_SM
                        : configEntzy.APP_SPACING_SM3X,
                      mr: compactView ? 0 : configEntzy.APP_SPACING_SM3X,
                    }}
                  >
                    <TextTag
                      textNoCasing={true}
                      rounded={true}
                      bgColor="danger.light"
                      color="black"
                      fullWidth={true}
                      text={
                        <span>
                          <span>Delete&nbsp;&nbsp;</span>
                          <FontAwesomeIcon
                            icon={iconDelete}
                            className="fa-pull-right"
                            transform="down-4"
                            fixedWidth
                          />
                        </span>
                      }
                      size={compactView ? "sm" : "md"}
                      onClick={() => {
                        handleTimelineEntryView(null);
                        item.Category.startsWith("external")
                          ? handleExternalEntryUpdate(
                              {
                                name: getExternalEntryProvider(item),
                              },
                              {
                                entry: item,
                                delete: true,
                              }
                            )
                          : handleEntryDelete(null, item, null);
                      }}
                    />
                  </Box>
                  {!item.Category.startsWith("external") &&
                    mainContext.state.viewer.calendar.external
                      .filter((obj) => obj.connected)
                      .map((calendar) => {
                        const externalEntryId =
                          pairedMeta[calendar.name].externalEntryId;
                        const externalEntryUrl =
                          pairedMeta[calendar.name].externalEntryUrl;
                        return (
                          <Box
                            key={calendar.name}
                            className={
                              compactView ? "box-default" : "box-inline"
                            }
                            sx={{
                              mt: compactView
                                ? configEntzy.APP_SPACING_SM
                                : configEntzy.APP_SPACING_SM3X,
                              mr: compactView
                                ? 0
                                : configEntzy.APP_SPACING_SM3X,
                            }}
                          >
                            <Box
                              className={
                                compactView ? "box-default" : "box-inline"
                              }
                            >
                              <TextTag
                                textNoCasing={true}
                                rounded={true}
                                bgColor={
                                  externalEntryId
                                    ? "danger.light"
                                    : "success.main"
                                }
                                color="black"
                                fullWidth={true}
                                text={
                                  <span>
                                    <span>
                                      <FontAwesomeIcon
                                        icon={
                                          calendar.name === "google"
                                            ? iconGoogle
                                            : calendar.name === "apple"
                                            ? iconApple
                                            : iconEntry
                                        }
                                        transform="shrink-4"
                                      />
                                      /&nbsp;
                                    </span>
                                    <span>
                                      {externalEntryId ? "Unlink" : "Link"}
                                      &nbsp;&nbsp;
                                    </span>
                                    <FontAwesomeIcon
                                      icon={
                                        externalEntryId ? iconUnlink : iconLink
                                      }
                                      className="fa-pull-right"
                                      transform="down-4"
                                      fixedWidth
                                    />
                                  </span>
                                }
                                size={compactView ? "sm" : "md"}
                                onClick={() => {
                                  handleExternalEntryUpdate(calendar, {
                                    entry: externalEntryId
                                      ? {
                                          ...item,
                                          UnlinkId: externalEntryId,
                                        }
                                      : {
                                          ...item,
                                          Description: renderCategoryText(item),
                                        },
                                    delete: externalEntryId ? true : false,
                                    create: externalEntryId ? false : true,
                                  });
                                }}
                              />
                            </Box>
                            {externalEntryUrl && (
                              <Box
                                className={
                                  compactView ? "box-default" : "box-inline"
                                }
                                sx={{
                                  ml: compactView
                                    ? 0
                                    : configEntzy.APP_SPACING_SM3X,
                                }}
                              >
                                <TextTag
                                  textNoCasing={true}
                                  rounded={true}
                                  bgColor="black"
                                  color="white"
                                  shadow={true}
                                  fullWidth={true}
                                  text={
                                    <span>
                                      <span>Jump&nbsp;&nbsp;</span>
                                      <FontAwesomeIcon
                                        icon={iconGo}
                                        className="fa-pull-right"
                                        transform="down-4"
                                        fixedWidth
                                      />
                                    </span>
                                  }
                                  size={compactView ? "sm" : "md"}
                                  onClick={() =>
                                    handleNavigateEvent(externalEntryUrl, true)
                                  }
                                />
                              </Box>
                            )}
                          </Box>
                        );
                      })}
                </Box>
                {!compactView &&
                  mainContext.state.viewer.calendar.external
                    .filter((obj) => obj.connected)
                    .map((calendar) => {
                      const externalEntryId =
                        pairedMeta[calendar.name].externalEntryId;
                      return (
                        <Box key={calendar.name} className="box-default">
                          {externalEntryId && (
                            <Typography
                              variant="body2"
                              sx={{
                                mt: configEntzy.APP_SPACING_MD,
                              }}
                            >
                              <em>
                                <span style={{ color: "orange" }}>
                                  Paired entry with {calendar.name} calendar
                                </span>
                              </em>
                            </Typography>
                          )}
                        </Box>
                      );
                    })}
                <Box
                  className="box-default"
                  hidden={loadingEntry === item.DateId || compactView}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      mt: configEntzy.APP_SPACING_MD2X,
                    }}
                  >
                    <span
                      className="bg-black-t50 bdp2 rounded"
                      style={{
                        color: "white",
                      }}
                    >
                      &nbsp;{renderDateInfo(item)}&nbsp;
                    </span>
                    <span style={{ float: "right" }}>
                      {renderDateType(item)}
                    </span>
                  </Typography>
                </Box>
              </Box>
              {loadingEntry === item.DateId && (
                <Box
                  className="box-default text-center"
                  sx={{
                    pt: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <ActionLoader size={compactView ? "sm" : "md"} />
                </Box>
              )}
            </Box>
          )}
        </Box>
      );
    };

    return (
      <Box className="box-default">
        <ItemContent />
        <Modal
          open={timelineEntryView === item.DateId && !loadingEntry}
          onClose={() => handleTimelineEntryView(item.DateId)}
        >
          <Box
            className="box-abs bg-black-t90 text-center"
            sx={{
              width: configEntzy.AVATAR_CONTAINER_XL,
              maxWidth: "100%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              mt: configEntzy.APP_SPACING_MD,
              p: configEntzy.APP_SPACING_MD2X,
              borderRadius: configEntzy.BORDER_SIZE_XL,
            }}
          >
            <Box
              className="box-default"
              sx={{
                pb: configEntzy.APP_SPACING_MD,
              }}
            >
              <Box className="box-default quarter-width">
                <Typography variant="h6">
                  <span>&nbsp;</span>
                </Typography>
              </Box>
              <Box className="box-default half-width">
                <Typography variant="h6" color="orange">
                  {dateDisplay}
                </Typography>
              </Box>
              <Box
                className="box-default quarter-width text-right action-pointer"
                onClick={() => handleTimelineEntryView(item.DateId)}
              >
                <Typography variant="h6">
                  <FontAwesomeIcon
                    icon={iconClose}
                    transform="grow-4"
                    fixedWidth
                  />
                </Typography>
              </Box>
            </Box>
            <ItemContent modal={true} />
          </Box>
        </Modal>

        <Modal
          open={timelineEntryEdit === item.DateId && !loadingEntry}
          onClose={() => handleEditEntryView(item.DateId)}
        >
          <Box
            className="box-abs bg-black-t90 text-center"
            sx={{
              width: configEntzy.AVATAR_CONTAINER_XL,
              maxWidth: "100%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              mt: configEntzy.APP_SPACING_MD,
              pt: configEntzy.APP_SPACING_MD2X,
              pl: configEntzy.APP_SPACING_MD2X,
              pr: configEntzy.APP_SPACING_MD2X,
              borderRadius: configEntzy.BORDER_SIZE_XL,
            }}
          >
            <Box
              className="box-default"
              sx={{
                pb: configEntzy.APP_SPACING_MD,
              }}
            >
              <Box className="box-default quarter-width">
                <Typography variant="h6">
                  <span>&nbsp;</span>
                </Typography>
              </Box>
              <Box className="box-default half-width">
                <Typography variant="h6" color="orange">
                  Edit {dayjs(item.DateKey, "YYYY-MM-DD").format("DD MMM")}
                </Typography>
              </Box>
              <Box
                className="box-default quarter-width text-right action-pointer"
                onClick={() => handleEditEntryView(item.DateId)}
              >
                <Typography variant="h6">
                  <FontAwesomeIcon
                    icon={iconClose}
                    transform="grow-4"
                    fixedWidth
                  />
                </Typography>
              </Box>
            </Box>
            <Box className="box-default">
              <InputForm
                navigate={props.navigate}
                fields={[
                  {
                    id: "entry-description",
                    type: "text-multiline",
                    label: "Edit entry details",
                    value: item.Description,
                    required: true,
                    maxLength: 1000,
                    disabled: !user.connected,
                    // startAdornment: (
                    //   <FontAwesomeIcon icon={iconText} color="primary" fixedWidth />
                    // ),
                  },
                ]}
                submitText="Update Entry"
                disabledSubmit={!user.connected}
                callback={handleTimelineEntryEdit}
              />
            </Box>
          </Box>
        </Modal>
      </Box>
    );
  };

  const RenderTimeLine = () => {
    const today = dayjs().format("YYYY-MM-DD");
    return (
      <Box className="box-default">
        <Grid container>
          {mainContext.state.viewer.calendar[
            viewDate.format("YYYY-MM")
          ].data.map((item, index) => {
            // when viewing calendar inside event enrich with event data
            if (eventView) {
              item.EventView = {
                Launched:
                  eventContext.state.event.calendar.dates.launched.all.items.filter(
                    (obj) => obj.DateKey === item.DateKey
                  ),
                Ticking:
                  eventContext.state.event.calendar.dates.combined.active.filter(
                    (obj) => obj.id === item.DateKey && !obj.isExpired
                  ),
                Expired:
                  eventContext.state.event.calendar.dates.combined.active.filter(
                    (obj) => obj.id === item.DateKey && obj.isExpired
                  ),
              };
            } else {
              item.EventView = {
                Launched: [],
                Ticking: [],
                Expired: [],
              };
            }
            return (
              <Grid
                item
                key={item.DateKey}
                xs={
                  expandView
                    ? mainContext.state.viewer.trail.calendarCompactView
                      ? 4
                      : 12
                    : undefined
                }
                sm={
                  expandView
                    ? mainContext.state.viewer.trail.calendarCompactView
                      ? 3
                      : 12
                    : undefined
                }
                sx={{
                  p: configEntzy.APP_SPACING_SM,
                  borderRight: expandView ? "none" : "1px solid #ccc",
                  color: "white",
                }}
                onClick={
                  expandView ? undefined : () => handleExpandView(item.DateKey)
                }
              >
                <Box
                  className={
                    "box-default action-pointover" +
                    (expandView ? " text-left" : "")
                  }
                  sx={{
                    p: expandView ? configEntzy.APP_SPACING_SM2X : 0,
                    borderBottom:
                      expandView && item.DateKey !== today
                        ? "1px solid #ccc"
                        : undefined,
                    border:
                      expandView && item.DateKey === today
                        ? "1px dashed #f90"
                        : undefined,
                    // borderRadius:
                    //   expandView && item.DateKey === today
                    //     ? configEntzy.BORDER_SIZE_XL
                    //     : undefined,
                    backgroundColor:
                      item.DateKey === today ? "dusk.main" : "transparent",
                  }}
                  onClick={() => handleTimelineEntryAdd(item.DateKey)}
                >
                  <Typography variant="body2">
                    <span>&nbsp;</span>
                    <span>
                      {mainContext.state.viewer.trail.calendarCompactView &&
                      expandView
                        ? dayjs(item.DateKey, "YYYY-MM-DD").format("dd DD")
                        : item.DateDisplay}
                    </span>
                    <span>&nbsp;</span>
                    {expandView && (
                      <span style={{ float: "right", color: "orange" }}>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span>
                          <FontAwesomeIcon
                            icon={
                              timelineEntryAdd === item.DateKey
                                ? iconClose
                                : iconAdd
                            }
                            transform="grow-4"
                            fixedWidth
                          />
                        </span>
                      </span>
                    )}
                    {expandView &&
                      !mainContext.state.viewer.trail.calendarCompactView &&
                      item.Entries.length === 0 &&
                      item.EventView.Launched.length === 0 &&
                      item.EventView.Ticking.length === 0 &&
                      item.EventView.Expired.length === 0 && (
                        <span style={{ float: "right", color: "#ccc" }}>
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <span>
                            <em>No activity</em>
                          </span>
                        </span>
                      )}
                    {item.Direct.length > 0 && (
                      <span
                        style={{ float: "right", color: "orange" }}
                        hidden={
                          mainContext.state.viewer.trail.calendarCompactView &&
                          expandView
                        }
                      >
                        <span>&nbsp;</span>
                        <span>
                          <FontAwesomeIcon icon={iconDirect} fixedWidth />
                        </span>
                        <span>&nbsp;{item.Direct.length}&nbsp;</span>
                      </span>
                    )}
                    {item.External.length > 0 && (
                      <span
                        style={{ float: "right", color: "orange" }}
                        hidden={
                          mainContext.state.viewer.trail.calendarCompactView &&
                          expandView
                        }
                      >
                        <span>&nbsp;</span>
                        <span>
                          <FontAwesomeIcon icon={iconExternal} fixedWidth />
                        </span>
                        <span>&nbsp;{item.External.length}&nbsp;</span>
                      </span>
                    )}
                    {item.Events.length > 0 && (
                      <span
                        style={{ float: "right", color: "orange" }}
                        hidden={
                          mainContext.state.viewer.trail.calendarCompactView &&
                          expandView
                        }
                      >
                        <span>&nbsp;</span>
                        <span>
                          <FontAwesomeIcon icon={iconEntry} fixedWidth />
                        </span>
                        <span>&nbsp;{item.Events.length}&nbsp;</span>
                      </span>
                    )}
                    {item.Launches.length > 0 && (
                      <span
                        style={{ float: "right", color: "orange" }}
                        hidden={
                          mainContext.state.viewer.trail.calendarCompactView &&
                          expandView
                        }
                      >
                        <span>&nbsp;</span>
                        <span>
                          <FontAwesomeIcon icon={iconLaunch} fixedWidth />
                        </span>
                        <span>&nbsp;{item.Launches.length}&nbsp;</span>
                      </span>
                    )}
                    {item.EventView.Launched.length > 0 && (
                      <span
                        style={{ float: "right", color: "orange" }}
                        hidden={
                          mainContext.state.viewer.trail.calendarCompactView ||
                          eventView
                        }
                      >
                        <span>&nbsp;</span>
                        <span>
                          <FontAwesomeIcon icon={iconLaunch} fixedWidth />
                        </span>
                        <span>
                          &nbsp;{item.EventView.Launched[0].Quantity}
                          &nbsp;
                        </span>
                      </span>
                    )}
                    {item.EventView.Ticking.length > 0 && (
                      <span
                        style={{ float: "right", color: "orange" }}
                        hidden={
                          mainContext.state.viewer.trail.calendarCompactView ||
                          eventView
                        }
                      >
                        <span>&nbsp;</span>
                        <span>
                          <FontAwesomeIcon icon={iconTicking} fixedWidth />
                        </span>
                        <span>
                          &nbsp;{item.EventView.Ticking[0].progress.count}
                          &nbsp;
                        </span>
                      </span>
                    )}
                    {item.EventView.Expired.length > 0 && (
                      <span
                        style={{ float: "right", color: "gray" }}
                        hidden={
                          mainContext.state.viewer.trail.calendarCompactView ||
                          eventView
                        }
                      >
                        <span>&nbsp;</span>
                        <span>
                          <FontAwesomeIcon icon={iconExpired} fixedWidth />
                        </span>
                        <span>
                          &nbsp;{item.EventView.Expired[0].progress.count}
                          &nbsp;
                        </span>
                      </span>
                    )}
                  </Typography>
                </Box>
                <Modal
                  open={timelineEntryAdd === item.DateKey && !loadingEntry}
                  onClose={() => handleTimelineEntryAdd(item.DateKey)}
                >
                  <Box
                    className="box-abs bg-black-t90 text-center"
                    sx={{
                      width: configEntzy.AVATAR_CONTAINER_XL,
                      maxWidth: "100%",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      mt: configEntzy.APP_SPACING_MD,
                      pt: configEntzy.APP_SPACING_MD2X,
                      pl: configEntzy.APP_SPACING_MD2X,
                      pr: configEntzy.APP_SPACING_MD2X,
                      borderRadius: configEntzy.BORDER_SIZE_XL,
                    }}
                  >
                    <Box className="box-default">
                      <Box className="box-default quarter-width">
                        <Typography variant="h6">
                          <span>&nbsp;</span>
                        </Typography>
                      </Box>
                      <Box className="box-default half-width">
                        <Typography variant="h6" color="orange">
                          {item.DateDisplay}
                        </Typography>
                      </Box>
                      <Box
                        className="box-default quarter-width text-right action-pointer"
                        onClick={() => handleTimelineEntryAdd(item.DateKey)}
                      >
                        <Typography variant="h6">
                          <FontAwesomeIcon
                            icon={iconClose}
                            transform="grow-4"
                            fixedWidth
                          />
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      className="box-default"
                      sx={{
                        pb: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      <Typography variant="subtitle2" color="white">
                        Create Quick Manual Entry
                      </Typography>
                    </Box>
                    <Box className="box-default">
                      <InputForm
                        navigate={props.navigate}
                        fields={[
                          {
                            id: "entry-description",
                            type: "text-multiline",
                            label: "Enter entry details",
                            value: "",
                            required: true,
                            maxLength: 1000,
                            disabled: !user.connected,
                            // startAdornment: (
                            //   <FontAwesomeIcon icon={iconText} color="primary" fixedWidth />
                            // ),
                          },
                        ]}
                        submitText="Create Entry"
                        disabledSubmit={!user.connected}
                        callback={handleTimelineEntryCreate}
                      />
                    </Box>
                  </Box>
                </Modal>
                {loadingEntry === item.DateKey && (
                  <Box
                    className="box-default text-center"
                    sx={{
                      pt: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <ActionLoader
                      size={
                        mainContext.state.viewer.trail.calendarCompactView
                          ? "sm"
                          : "md"
                      }
                    />
                  </Box>
                )}
                {expandView && (
                  <Box className="box-default">
                    <Box className="box-default">
                      {item.Entries.map((entry, index) => {
                        return (
                          <Box
                            key={entry.DateId}
                            className="box-default"
                            sx={{
                              pt: configEntzy.APP_SPACING_SM2X,
                            }}
                          >
                            <RenderTimeLineItem
                              entry={entry}
                              entries={item.Entries}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                    {eventView && (
                      <Box className="box-default">
                        <Box className="box-default">
                          {item.EventView.Ticking.map((item, index) => {
                            return (
                              <Box
                                key={item.id}
                                className="box-default"
                                sx={{
                                  pt: configEntzy.APP_SPACING_SM2X,
                                }}
                              >
                                <RoomDatesChartItem
                                  {...props}
                                  item={item}
                                  eventView={eventView}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                        <Box className="box-default">
                          {item.EventView.Expired.map((item, index) => {
                            return (
                              <Box
                                key={item.id}
                                className="box-default"
                                sx={{
                                  pt: configEntzy.APP_SPACING_SM2X,
                                }}
                              >
                                <RoomDatesChartItem
                                  {...props}
                                  item={item}
                                  eventView={eventView}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                        <Box className="box-default">
                          {item.EventView.Launched.map((item, index) => {
                            return (
                              <Box
                                key={item.DateKey}
                                className="box-default nooverflow"
                                sx={{
                                  mt: configEntzy.APP_SPACING_SM2X,
                                  borderRadius: configEntzy.BORDER_SIZE_XL,
                                }}
                              >
                                <ActionBoxButton
                                  text={
                                    "Tickets Ready! (" + item.Quantity + ")"
                                  }
                                  subText="Jump to Eventuated"
                                  bgColor="primary.main"
                                  color="black"
                                  disableElevation={true}
                                  endIcon={<FontAwesomeIcon icon={iconGo} />}
                                  onClick={() =>
                                    eventContext.focusModule("entry")
                                  }
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  return (
    <Box
      className="box-default bg-black-t25"
      sx={{
        p: configEntzy.APP_SPACING_MD,
        borderRadius: configEntzy.BORDER_SIZE_XL,
      }}
    >
      <Box
        className="box-default bg-white-t25"
        sx={{
          minHeight: configEntzy.AVATAR_SIZE_XS,
          pt: expandView ? configEntzy.APP_SPACING_MD : 0,
          pb: expandView ? configEntzy.APP_SPACING_MD2X : 0,
          borderRadius: expandView
            ? configEntzy.BORDER_SIZE_LG
            : configEntzy.BORDER_SIZE_MD,
        }}
      >
        {eventView && (
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD2X,
              pb: configEntzy.APP_SPACING_MD,
              color: "white",
            }}
          >
            <RoomLaunchpadUpdates
              {...props}
              dark={true}
              calendarView={true}
              calendarDateSelect={handleDateSelect}
            />

            <Box
              className="box-default"
              sx={{
                p: configEntzy.APP_SPACING_MD2X,
              }}
            >
              <BigButton
                text={showEventCompare ? undefined : "Compare in your Calendar"}
                subText={
                  showEventCompare ? undefined : "See Detailed Offer Activity"
                }
                bgColor="transparent"
                color="primary.main"
                icon={showEventCompare ? iconClose : iconCalendar}
                onClick={handleShowEventCompare}
              />
            </Box>
            <Box
              className="box-default"
              onClick={handleShowEventCompare}
              hidden={showEventCompare}
            >
              <Typography variant="h6" color="primary">
                <FontAwesomeIcon icon={iconDrop} size="4x" fixedWidth />
              </Typography>
            </Box>

            <Box
              className="box-default"
              hidden={eventView && !showEventCompare}
            >
              <Box
                className="box-default"
                // sx={{
                //   pt: configEntzy.APP_SPACING_MD2X,
                // }}
              >
                <Divider
                  sx={{
                    "&::before, &::after": {
                      borderColor: "#aaa",
                    },
                  }}
                >
                  <Chip
                    variant="outlined"
                    size="large"
                    sx={{
                      p: configEntzy.APP_SPACING_SM,
                      fontWeight: "600",
                      fontSize: configEntzy.FONT_SCALE_SM,
                      backgroundColor: "black",
                      color: "white",
                    }}
                    label={<span>Showing Activity in your Calendar</span>}
                  />
                </Divider>
              </Box>
              {/* <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD,
                  pl: configEntzy.APP_SPACING_SM2X,
                  pr: configEntzy.APP_SPACING_SM2X,
                }}
              >
                <Typography variant="subtitle1" color="secondary">
                  <em>Review date offer & launch activity below</em>
                </Typography>
                <Typography variant="subtitle1" color="secondary">
                  <em>When ready place your offers above</em>
                </Typography>
              </Box> */}
            </Box>
          </Box>
        )}

        <Box className="box-default" hidden={eventView && !showEventCompare}>
          <Box
            className="box-default"
            sx={{
              // borderBottom: expandView ? "none" : "1px solid #ccc",
              color: "white",
            }}
          >
            {mainContext.state.viewer.calendar.hydrated &&
            mainContext.state.viewer.calendar[viewDate.format("YYYY-MM")] &&
            mainContext.state.viewer.calendar[viewDate.format("YYYY-MM")]
              .hydrated ? (
              <Box className="box-default">
                <Box className="box-default" hidden={!expandView}>
                  <Box
                    className="box-default"
                    sx={{
                      p: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <Box
                      className="box-inline left text-left action-pointer"
                      onClick={handleDecrementMonth}
                    >
                      <Typography variant="h6" color="primary" noWrap>
                        <span>&nbsp;&nbsp;</span>
                        <FontAwesomeIcon icon={iconDecrement} fixedWidth />
                        <span>&nbsp;&nbsp;</span>
                        <span>{viewDate.add(-1, "month").format("MMM")}</span>
                      </Typography>
                    </Box>
                    <Box className="box-inline">
                      <Box
                        className="box-default action-pointer"
                        sx={{
                          borderRadius: configEntzy.BORDER_SIZE_MD,
                        }}
                        onClick={handleShowMonthSelector}
                        hidden={showMonthSelector}
                      >
                        <Typography variant="h6" noWrap>
                          {viewDate.format("MMM YYYY")}{" "}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      className="box-inline right text-right action-pointer"
                      onClick={handleIncrementMonth}
                    >
                      <Typography variant="h6" color="primary" noWrap>
                        <span>{viewDate.add(1, "month").format("MMM")}</span>
                        <span>&nbsp;&nbsp;</span>
                        <FontAwesomeIcon icon={iconIncrement} fixedWidth />
                        <span>&nbsp;&nbsp;</span>
                      </Typography>
                    </Box>
                  </Box>
                  <Container maxWidth="md">
                    <Box
                      className="box-default"
                      sx={{
                        borderRadius: configEntzy.BORDER_SIZE_MD,
                        pt: configEntzy.APP_SPACING_MD,
                        pb: configEntzy.APP_SPACING_MD,
                        pl: configEntzy.APP_SPACING_SM,
                        pr: configEntzy.APP_SPACING_SM,
                      }}
                      hidden={!showMonthSelector}
                    >
                      <Box
                        className="box-default text-left action-pointer"
                        sx={{
                          p: configEntzy.APP_SPACING_SM,
                        }}
                        onClick={handleShowMonthSelector}
                      >
                        <Typography variant="subtitle2" noWrap>
                          Select Month
                          <FontAwesomeIcon
                            icon={iconClose}
                            className="fa-pull-right"
                            transform="grow-10 left-5"
                          />
                        </Typography>
                      </Box>
                      <Box
                        className="box-default bg-white nooverflow shadow-default"
                        sx={{
                          borderRadius: configEntzy.BORDER_SIZE_LG,
                        }}
                      >
                        <InputForm
                          navigate={props.navigate}
                          fields={[
                            {
                              id: "timeline-month",
                              type: "selector-basic",
                              variant: "outlined",
                              bgColor: "white",
                              label: "Go to Month",
                              value: generateMonthsSelectable(viewDate).filter(
                                (obj) => obj.id === "month-0"
                              )[0],
                              required: true,
                              disableClearable: true,
                              disableMargin: true,
                              disableUnderline: true,
                              options: generateMonthsSelectable(viewDate),
                            },
                          ]}
                          submitText="Confirm Offer"
                          submitType="flow"
                          changeEmitter={handleChangeMonth}
                        />
                      </Box>
                    </Box>
                  </Container>
                </Box>
                {expandView && (
                  <Box className="box-default">
                    <Container maxWidth="md">
                      <Box
                        className="box-default"
                        sx={{
                          pl: configEntzy.APP_SPACING_SM2X,
                          pr: configEntzy.APP_SPACING_SM2X,
                          pb: configEntzy.APP_SPACING_MD,
                        }}
                      >
                        {eventView ? (
                          <Box className="box-default"></Box>
                        ) : dayjs().diff(viewDate, "month") > 0 ? (
                          <Typography variant="subtitle1" color="secondary">
                            <em>Showing History</em>
                          </Typography>
                        ) : (
                          <Marquee
                            speed={20}
                            gradient={false}
                            style={{ overflow: "hidden" }}
                          >
                            <Typography variant="subtitle1" color="secondary">
                              <span>
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <span>
                                  Listening for launches from your eventuators
                                </span>
                                <span>&nbsp;&nbsp;&nbsp;</span>

                                <FontAwesomeIcon
                                  icon={iconListener}
                                  className="fa-spin"
                                  style={{
                                    color: "divider",
                                    "--fa-animation-duration": "5s",
                                  }}
                                  fixedWidth
                                />
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <span>Dates are automatically synced</span>
                                <span>&nbsp;&nbsp;&nbsp;</span>
                              </span>
                            </Typography>
                          </Marquee>
                        )}
                      </Box>

                      <Box
                        className="box-default"
                        sx={{
                          pb: configEntzy.APP_SPACING_MD2X,
                        }}
                        hidden={!loadingCalendar}
                      >
                        <ActionLoader />
                      </Box>

                      <Box
                        className="box-default"
                        sx={{
                          pt: configEntzy.APP_SPACING_SM2X,
                          pl: configEntzy.APP_SPACING_SM,
                          pr: configEntzy.APP_SPACING_SM,
                          pb: configEntzy.APP_SPACING_MD2X,
                        }}
                        hidden={loadingCalendar}
                      >
                        <Box
                          className="box-default bg-black-t50 action-pointer"
                          sx={{
                            width: "10%",
                            p: configEntzy.APP_SPACING_SM2X,
                            borderRadius: configEntzy.BORDER_SIZE_XL,
                          }}
                          onClick={handleCompactView}
                        >
                          <Typography variant="subtitle2">
                            <FontAwesomeIcon
                              icon={
                                mainContext.state.viewer.trail
                                  .calendarCompactView
                                  ? iconCompactViewON
                                  : iconCompactViewOFF
                              }
                              color={
                                mainContext.state.viewer.trail
                                  .calendarCompactView
                                  ? "orange"
                                  : "white"
                              }
                              className="fa-flip"
                              style={{
                                "--fa-animation-duration": "1s",
                                "--fa-animation-iteration-count": "1",
                              }}
                              fixedWidth
                            />
                          </Typography>
                        </Box>
                        <Box
                          className="box-default bg-black-t50"
                          sx={{
                            width: "80%",
                            p: configEntzy.APP_SPACING_SM2X,
                            borderRadius: configEntzy.BORDER_SIZE_XL,
                          }}
                        >
                          <Box
                            className="box-default text-left action-pointer"
                            onClick={handleShowCalendarSelector}
                          >
                            <Typography variant="subtitle2" noWrap>
                              <FontAwesomeIcon
                                icon={iconCalendarSettings}
                                fixedWidth
                              />
                              <span>&nbsp;&nbsp;</span>
                              <span>Calendars</span>
                              <span>&nbsp;&nbsp;</span>
                              <FontAwesomeIcon
                                icon={iconDrop}
                                className="fa-pull-right"
                                fixedWidth
                                transform={
                                  "grow-2 down-3 left-2 " +
                                  (showCalendarSelector ? "flip-v" : "")
                                }
                              />
                            </Typography>
                          </Box>
                          <Box
                            className="box-default"
                            hidden={!showCalendarSelector}
                          >
                            {mainContext.state.viewer.calendar.external.map(
                              (item, index) => {
                                return (
                                  <Box
                                    key={item.name}
                                    className={
                                      "box-default" +
                                      (item.connected
                                        ? " bg-black-t75 shadow-light"
                                        : "")
                                    }
                                    sx={{
                                      mt: configEntzy.APP_SPACING_MD,
                                      p: item.connected
                                        ? configEntzy.APP_SPACING_SM2X
                                        : 0,
                                      borderRadius: item.connected
                                        ? configEntzy.BORDER_SIZE_XL
                                        : 0,
                                    }}
                                  >
                                    <Box className="box-default">
                                      <ActionBoxButton
                                        text={
                                          item.connected
                                            ? item.name + " connected"
                                            : item.name + " calendar"
                                        }
                                        subtext={
                                          item.connected
                                            ? "Tap to Disconnect"
                                            : "Connect your Calendar"
                                        }
                                        bgColor={
                                          item.connected
                                            ? "success.main"
                                            : "white"
                                        }
                                        onClick={() => {
                                          if (!user.connected) {
                                            props.drawerConnectToggle();
                                          } else if (item.connected) {
                                            handleConfirmRemoveExternalCalendar(
                                              null,
                                              {
                                                name: item.name,
                                              }
                                            );
                                          } else {
                                            handleAddExternalCalendar({
                                              name: item.name,
                                            });
                                          }
                                        }}
                                        startIcon={
                                          <FontAwesomeIcon icon={iconGoogle} />
                                        }
                                      />
                                    </Box>
                                    {mainContext.state.viewer.calendar
                                      .externalMeta[item.name] && (
                                      <Box
                                        className="box-default"
                                        sx={{
                                          pt: configEntzy.APP_SPACING_SM2X,
                                        }}
                                      >
                                        <Typography variant="body2">
                                          <span>
                                            {
                                              mainContext.state.viewer.calendar
                                                .externalMeta[item.name].id
                                            }
                                          </span>
                                        </Typography>
                                        <Typography variant="body2">
                                          <span>
                                            {
                                              mainContext.state.viewer.calendar
                                                .externalMeta[item.name]
                                                .timeZone
                                            }
                                          </span>
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                );
                              }
                            )}
                          </Box>
                        </Box>
                        <Box
                          className="box-default bg-black-t50 action-pointer"
                          sx={{
                            width: "10%",
                            p: configEntzy.APP_SPACING_SM2X,
                            borderRadius: configEntzy.BORDER_SIZE_XL,
                          }}
                          onClick={handleRefreshView}
                        >
                          <Typography variant="subtitle2">
                            <FontAwesomeIcon
                              icon={iconRefresh}
                              color={loadingCalendar ? "orange" : "white"}
                              className="fa-spin"
                              style={{
                                "--fa-animation-duration": "1s",
                                "--fa-animation-iteration-count": "1",
                              }}
                              fixedWidth
                            />
                          </Typography>
                        </Box>
                      </Box>
                    </Container>
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                className="box-default"
                sx={{ p: configEntzy.APP_SPACING_SM }}
              >
                <Typography variant="h6">
                  <FontAwesomeIcon
                    icon={iconLoading}
                    className="fa-flip"
                    style={{ color: "white" }}
                    fixedWidth
                  />
                </Typography>
              </Box>
            )}
            <Box className="box-default">
              <ActionCheck
                dialog={dialog.visible}
                title={dialog.title}
                description={dialog.description}
                cancel={dialog.cancel}
                confirm={dialog.confirm}
              />
            </Box>
          </Box>
          {mainContext.state.viewer.calendar.hydrated &&
            mainContext.state.viewer.calendar[viewDate.format("YYYY-MM")] &&
            mainContext.state.viewer.calendar[viewDate.format("YYYY-MM")]
              .hydrated && (
              <Box className="box-default">
                {expandView ? (
                  <Container maxWidth="md">
                    <RenderTimeLine />
                  </Container>
                ) : (
                  <Box className="box-default">
                    <Marquee
                      speed={20}
                      gradient={false}
                      pauseOnClick={true}
                      pauseOnHover={true}
                    >
                      <RenderTimeLine />
                    </Marquee>
                  </Box>
                )}
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
}

export default EventCalendar;

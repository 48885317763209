// react core
import { useContext, useState } from "react";

// date handling
import dayjs from "dayjs";

// imported animations
// import Marquee from "react-fast-marquee";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import Slide from "@mui/material/Slide";
// import LinearProgress from "@mui/material/LinearProgress";

// mui x-charts
import { BarChart } from "@mui/x-charts/BarChart";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import { CounterTag } from "components/utils/common/CommonTags";

// fonts icons and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faExplosion as iconLaunched } from "@fortawesome/pro-duotone-svg-icons";
// import { faCheckCircle as iconCheck } from "@fortawesome/pro-solid-svg-icons";
// import { faCircle as iconPending } from "@fortawesome/pro-regular-svg-icons";
// import { faPersonBiking as iconRider } from "@fortawesome/pro-duotone-svg-icons";
// import { faRocketLaunch as iconTriggered } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as iconCircle } from "@fortawesome/pro-solid-svg-icons";
import { faRectangle as iconRectangle } from "@fortawesome/pro-solid-svg-icons";
import { faClock as iconNotice } from "@fortawesome/pro-duotone-svg-icons";
import { faUsersLine as iconPeopleMin } from "@fortawesome/pro-duotone-svg-icons";
import { faUsersViewfinder as iconPeopleMax } from "@fortawesome/pro-duotone-svg-icons";
import { faHorizontalRule as iconLine } from "@fortawesome/pro-thin-svg-icons";
import { faChevronUp as iconUp } from "@fortawesome/pro-thin-svg-icons";
import { faChevronDown as iconDown } from "@fortawesome/pro-thin-svg-icons";

// import { faChartSimpleHorizontal as iconTicking } from "@fortawesome/pro-duotone-svg-icons";

// entzy context and services
import { EventContext } from "pages/events/EventContext";
import { RoomDatesChartItemDetail } from "./RoomDatesChart";

function RoomLaunchpadUpdates(props) {
  const eventContext = useContext(EventContext);

  const source = props.source;
  const mini = props.mini;
  const handleTabs = props.handleTabs || (() => {});
  // const calendarView = props.calendarView;
  // const calendarDateSelect = props.calendarDateSelect;

  const initDateRange = mini ? 10 : 20;
  const barHeight = 40;
  const disabledValue1 = 0.00001;
  const disabledValue2 = 0.00002;
  const runnerFlag1 = 99.99999;
  const runnerFlag2 = 99.99998;

  // const sortedLaunchedDates =
  //   eventContext.state.event.calendar.dates.launched.future.keys
  //     .slice()
  //     .reverse();

  const [dateSelect, setDateSelect] = useState(null);
  // const [progressTickerView, setProgressTickerView] = useState(0);
  const [dateRange] = useState(initDateRange);
  const [datasetStartDate, setDatasetStartDate] = useState(
    // dayjs().add(eventContext.state.event.calendar.limits.notice + 1, "days")
    dayjs()
  );
  const [datasetEndDate, setDatasetEndDate] = useState(
    dayjs().add(
      // dateRange + eventContext.state.event.calendar.limits.notice + 1,
      dateRange,
      "days"
    )
  );

  const handleDateScroll = (direction) => {
    if (direction === "back") {
      setDatasetStartDate(datasetStartDate.subtract(dateRange, "days"));
      setDatasetEndDate(datasetEndDate.subtract(dateRange, "days"));
    } else {
      setDatasetStartDate(datasetStartDate.add(dateRange, "days"));
      setDatasetEndDate(datasetEndDate.add(dateRange, "days"));
    }
  };

  const handleDateSelect = (date) => {
    setDateSelect(date?.id === dateSelect?.id ? null : date);
    // if (calendarView) {
    //   calendarDateSelect(dateId);
    // } else {
    //   eventContext.focusModule("dates");
    // }
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setProgressTickerView((progressTickerView + 1) % 3);
  //   }, 5000);
  //   return () => clearInterval(interval);
  // });

  // create datasets
  const tickingDataset = [];
  const launchedDataset = [];
  let currentDate;

  const getDateFlags = (date, currentDate) => {
    const tickingTotal = date?.progress?.trigger?.total || 0;
    const isInPast = currentDate.isBefore(dayjs());
    const isInNoticePeriod =
      currentDate.isAfter(dayjs()) &&
      currentDate.isBefore(
        dayjs().add(eventContext.state.event.calendar.limits.notice, "days")
      );
    const isLaunched =
      tickingTotal >= 100 && date?.ticking?.TickStatus === "triggered";
    const isExpired = isInPast || isInNoticePeriod || date?.isExpired;
    return {
      tickingTotal,
      isInPast,
      isLaunched,
      isExpired,
      isInNoticePeriod,
    };
  };

  currentDate = dayjs(datasetStartDate);
  while (currentDate.isBefore(datasetEndDate)) {
    let id, date, baseDate, dateFlags, displayDate, displayShort, progressTotal;
    id = currentDate.format("YYYY-MM-DD");
    displayDate = currentDate.format("ddd DD MMM YY");
    displayShort = currentDate.format("dd DD MMM");
    baseDate = {
      id: currentDate.format("YYYY-MM-DD"),
      dow: currentDate.format("ddd"),
      month: currentDate.format("MMM"),
      day: currentDate.format("DD"),
      year: currentDate.format("YYYY"),
    };

    // check for active dates
    date = eventContext.state.event.calendar.dates.combined.active.find(
      (date) => date.id === id
    );

    // check for historical launched dates
    if (
      !date &&
      eventContext.state.event.calendar.dates.launched.history.keys.includes(id)
    ) {
      date = {
        ...baseDate,
        id,
        progress: {
          trigger: {
            total: 100,
          },
        },
      };
    }

    // default any dates with no activity
    if (!date) {
      date = {
        ...baseDate,
      };
    }

    // set date flags
    dateFlags = getDateFlags(date, currentDate);

    // set ticking totals and add to dataset
    progressTotal = dateFlags.isInNoticePeriod
      ? disabledValue2
      : dateFlags.isExpired
      ? disabledValue1
      : date?.progress?.trigger?.total || 0;
    tickingDataset.push({
      id,
      date,
      displayDate,
      displayShort,
      progressTotal,
      targetTotal: 100 - progressTotal,
      ...dateFlags,
    });

    // set launched totals and add to dataset
    progressTotal = dateFlags.isInNoticePeriod
      ? disabledValue2
      : dateFlags.isExpired
      ? disabledValue1
      : !dateFlags.isLaunched && date.isRunnerOffer
      ? runnerFlag1
      : !dateFlags.isLaunched && date.isRunnerLaunchable
      ? runnerFlag2
      : (dateFlags.isLaunched && date?.progress?.capacity?.total) || 0;
    launchedDataset.push({
      id,
      date,
      displayDate,
      displayShort,
      progressTotal,
      targetTotal: 100 - progressTotal,
      ...dateFlags,
    });

    currentDate = currentDate.add(1, "day");
  }

  const barNames = (dataset) => {
    return dataset.map((date) => date.displayDate);
  };
  const barValues = (dataset) => {
    return dataset.map((date) => date.progressTotal);
  };
  const barColors = (datasetValues) => {
    return datasetValues.map((val) => {
      if (val === disabledValue1) {
        return "black";
      } else if (val === disabledValue2) {
        return "rgba(255, 153, 0, 0.1)";
      } else if (val === runnerFlag1) {
        return "rgba(255, 140, 0, 0.5)";
      } else if (val === runnerFlag2) {
        return "rgba(255, 140, 0, 0.2)";
      } else {
        return null;
      }
    });
  };

  const DateNavBar = () => {
    return (
      <Box className="box-default">
        <Box
          className="box-default half-width bg-white-t25 shadow-default action-pointer"
          onClick={() => handleDateScroll("back")}
          sx={{
            p: configEntzy.APP_SPACING_SM,
          }}
        >
          <Typography variant="h3">
            <FontAwesomeIcon icon={iconUp} color="white" fixedWidth />
          </Typography>
        </Box>
        <Box
          className="box-default half-width bg-white-t25 shadow-default action-pointer"
          onClick={() => handleDateScroll("forward")}
          sx={{
            p: configEntzy.APP_SPACING_SM,
          }}
        >
          <Typography variant="h3">
            <FontAwesomeIcon icon={iconDown} color="white" fixedWidth />
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          p: configEntzy.APP_SPACING_MD,
        }}
      >
        <Box
          className="box-default"
          sx={{
            mb: configEntzy.APP_SPACING_XL,
          }}
          hidden={!["main"].includes(source)}
        >
          <Box
            className="box-inline bg-orange action-pointer border-glow"
            sx={{
              p: configEntzy.APP_SPACING_MD,
              borderRadius: configEntzy.BORDER_SIZE_XL,
            }}
            onClick={() => handleTabs(null, 3)}
          >
            <Typography variant="subtitle2" color="white" fontWeight={900}>
              &nbsp;Place Your Date Offers&nbsp;
            </Typography>
          </Box>
        </Box>
        {/* ------------- CRITERIA COUNTERS HEADER ------------- */}
        <Box
          className="box-default action-pointer"
          onClick={() => handleTabs(null, 6)}
        >
          <Box className="box-default quarter-width">
            <Box className="box-default relative pop-up">
              <CounterTag
                count={eventContext.state.event.calendar.limits.notice + "d"}
                size="sm"
                border={true}
              />
            </Box>
            <Box
              className="box-default"
              sx={{ mt: configEntzy.APP_SPACING_NSM }}
            >
              <Typography variant="h1">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon
                    icon={iconLine}
                    transform="rotate-90 down-6 grow-24"
                  />
                  <FontAwesomeIcon
                    icon={iconCircle}
                    transform="up-1"
                    color="#333"
                  />
                  <FontAwesomeIcon
                    icon={iconNotice}
                    transform="shrink-4 up-1"
                    color="orange"
                  />
                </span>
              </Typography>
            </Box>
            <Box className="box-default relative pop-up">
              <Box
                className="box-inline bg-white"
                sx={{
                  borderRadius: configEntzy.BORDER_SIZE_MD,
                  pl: configEntzy.APP_SPACING_SM,
                  pr: configEntzy.APP_SPACING_SM,
                  // pt: configEntzy.APP_SPACING_XS3X,
                  // pb: configEntzy.APP_SPACING_XS3X,
                }}
              >
                <Typography variant="subtitle2" color="black">
                  Notice
                </Typography>
                {/* <Typography
                  variant="body2"
                  color="black"
                  sx={{ mt: configEntzy.APP_SPACING_NXS3X }}
                >
                  In Days
                </Typography> */}
              </Box>
            </Box>
            <Box className="box-default">
              <Typography variant="body1">
                &nbsp;
                {/* <FontAwesomeIcon
                  icon={iconLine}
                  transform="rotate-90"
                  fixedWidth
                /> */}
              </Typography>
            </Box>
          </Box>
          <Box className="box-default half-width">
            {/* <Box className="box-default zero-height">
              <Typography variant="h3">
                <FontAwesomeIcon
                  icon={iconRider}
                  transform="rotate--15 left-75 down-10"
                  fixedWidth
                />
              </Typography>
            </Box>
            <Box className="box-default zero-height">
              <Typography variant="h3">
                <FontAwesomeIcon
                  icon={iconTriggered}
                  transform="rotate-45 right-75 down-10"
                  fixedWidth
                />
              </Typography>
            </Box> */}

            <Box className="box-default relative pop-up">
              <CounterTag
                count={eventContext.state.event.calendar.triggers.default}
                size="md"
                border={true}
              />
            </Box>
            <Box
              className="box-default"
              sx={{ mt: configEntzy.APP_SPACING_NSM }}
            >
              <Typography variant="h1">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon
                    icon={iconLine}
                    transform="rotate-90 down-6 grow-24"
                  />
                  <FontAwesomeIcon
                    icon={iconRectangle}
                    transform="down-3"
                    color="darkorange"
                  />
                  <FontAwesomeIcon
                    icon={iconPeopleMin}
                    transform="shrink-7 down-3"
                    color="black"
                  />
                </span>
              </Typography>
            </Box>
            <Box
              className="box-default relative pop-up"
              sx={{
                // mt: configEntzy.APP_SPACING_NXS3X,
                pt: configEntzy.APP_SPACING_SM,
              }}
            >
              <Box
                className="box-inline bg-white"
                sx={{
                  borderRadius: configEntzy.BORDER_SIZE_MD,
                  pl: configEntzy.APP_SPACING_SM,
                  pr: configEntzy.APP_SPACING_SM,
                  // pt: configEntzy.APP_SPACING_XS3X,
                  // pb: configEntzy.APP_SPACING_XS3X,
                }}
              >
                <Typography variant="subtitle2" color="black">
                  Launch
                </Typography>
                {/* <Typography
                  variant="body2"
                  color="black"
                  sx={{ mt: configEntzy.APP_SPACING_NXS3X }}
                >
                  Min Riders
                </Typography> */}
              </Box>
            </Box>
            {/* <Box
              className="box-default"
            >
              <Typography variant="body1">
                &nbsp;
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon
                    icon={iconLine}
                    transform="rotate-90"
                    fixedWidth
                  />
                  <FontAwesomeIcon
                    icon={iconLaunched}
                    transform="grow-15 down-40"
                    color="orange"
                  />
                </span>
              </Typography>
            </Box> */}
          </Box>
          <Box className="box-default quarter-width">
            <Box className="box-default relative pop-up">
              <CounterTag
                count={eventContext.state.event.calendar.limits.capacity}
                size="sm"
                border={true}
              />
            </Box>
            <Box
              className="box-default"
              sx={{ mt: configEntzy.APP_SPACING_NSM }}
            >
              <Typography variant="h1">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon
                    icon={iconLine}
                    transform="rotate-90 down-6 grow-24"
                  />
                  <FontAwesomeIcon
                    icon={iconRectangle}
                    transform="up-1"
                    color="#333"
                  />
                  <FontAwesomeIcon
                    icon={iconPeopleMax}
                    transform="shrink-7 up-1"
                    color="orange"
                  />
                </span>
              </Typography>
            </Box>
            <Box className="box-default relative pop-up">
              <Box
                className="box-inline bg-white"
                sx={{
                  borderRadius: configEntzy.BORDER_SIZE_MD,
                  pl: configEntzy.APP_SPACING_SM,
                  pr: configEntzy.APP_SPACING_SM,
                  // pt: configEntzy.APP_SPACING_XS3X,
                  // pb: configEntzy.APP_SPACING_XS3X,
                }}
              >
                <Typography variant="subtitle2" color="black">
                  Capacity
                </Typography>
                {/* <Typography
                  variant="body2"
                  color="black"
                  sx={{ mt: configEntzy.APP_SPACING_NXS3X }}
                >
                  Max Riders
                </Typography> */}
              </Box>
            </Box>
            <Box className="box-default">
              <Typography variant="body1">
                &nbsp;
                {/* <FontAwesomeIcon
                  icon={iconLine}
                  transform="rotate-90 grow-10"
                  fixedWidth
                /> */}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* ------------- CHARTS FOR DATE STATUS ------------- */}
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_NXS3X,
            // pl: configEntzy.APP_SPACING_MD,
            // pr: configEntzy.APP_SPACING_MD,
            // pb: configEntzy.APP_SPACING_MD,
          }}
        >
          <Box
            className="box-default nooverflow"
            sx={{
              borderRadius: configEntzy.BORDER_SIZE_XL,
            }}
          >
            <DateNavBar />

            <Box
              className="box-default half-width"
              sx={{
                pointerEvents: "none",
              }}
            >
              <BarChart
                // xAxis={[
                //   {
                //     id: "topAxis",
                //     label: "Launch Target",
                //   },
                //   {
                //     id: "bottomAxis",
                //     label: "Launch Target",
                //   },
                // ]}
                // borderRadius={10}
                // topAxis="topAxis"
                // leftAxis={null}
                // rightAxis={null}
                yAxis={[
                  {
                    id: "leftAxis",
                    scaleType: "band",
                    dataKey: "displayDate",
                    colorMap: {
                      type: "ordinal",
                      values: barNames(tickingDataset),
                      colors: barColors(barValues(tickingDataset)),
                    },
                  },
                  {
                    id: "rightAxis",
                    scaleType: "band",
                    dataKey: "displayDate",
                  },
                ]}
                series={[
                  {
                    dataKey: "progressTotal",
                    color: "#ff9900",
                    stack: "main",
                  },
                  {
                    dataKey: "targetTotal",
                    color: "#333333",
                    stack: "main",
                  },
                ]}
                barLabel={(item, context) => {
                  let itemData = tickingDataset[item.dataIndex];
                  let displayLabel = itemData.isLaunched
                    ? itemData.displayShort +
                      " " +
                      configEntzy.URL_POINTERS.BOT_STYLED_LAUNCHED
                    : !itemData.isExpired && itemData.progressTotal > 0
                    ? itemData.displayShort +
                      " @ " +
                      itemData.progressTotal +
                      "%"
                    : itemData.displayDate;
                  return item.value < 50 ? null : displayLabel;
                }}
                dataset={tickingDataset}
                layout="horizontal"
                height={dateRange * barHeight}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                grid={{
                  horizontal: true,
                  vertical: true,
                }}
                tooltip={{ trigger: "none" }}
                sx={{
                  ".MuiBarLabel-root": {
                    fill: "white",
                  },
                  ".MuiChartsAxis-tickLabel": {
                    fill: "white",
                  },
                  "& .MuiChartsAxis-line": {
                    display: "none",
                  },
                  "& *": {
                    pointerEvents: "auto",
                  },
                }}
                onItemClick={(event, identifier) => {
                  const index = identifier.dataIndex;
                  handleDateSelect(tickingDataset[index].date);
                }}
              />
            </Box>
            <Box
              className="box-default half-width"
              sx={{
                pointerEvents: "none",
              }}
            >
              <BarChart
                yAxis={[
                  {
                    id: "leftAxis",
                    scaleType: "band",
                    dataKey: "displayDate",
                    colorMap: {
                      type: "ordinal",
                      values: barNames(launchedDataset),
                      colors: barColors(barValues(launchedDataset)),
                    },
                  },
                  {
                    id: "rightAxis",
                    scaleType: "band",
                    dataKey: "displayDate",
                  },
                ]}
                series={[
                  {
                    dataKey: "progressTotal",
                    color: "#ff9900",
                    stack: "main",
                  },
                  {
                    dataKey: "targetTotal",
                    color: "#333333",
                    stack: "main",
                  },
                ]}
                barLabel={(item, context) => {
                  let itemData = launchedDataset[item.dataIndex];
                  let displayLabel =
                    itemData.isLaunched && itemData.isInPast
                      ? "Completed"
                      : itemData.isInNoticePeriod
                      ? "Notice Period"
                      : itemData.isExpired
                      ? "Expired"
                      : !itemData.isLaunched && itemData.date.isRunnerLaunchable
                      ? "Launchable!"
                      : !itemData.isLaunched && itemData.date.isRunnerOffer
                      ? "Runner Offered!"
                      : itemData.isLaunched && itemData.progressTotal > 0
                      ? "Capacity @ " + itemData.progressTotal + "%"
                      : itemData.tickingTotal > 0
                      ? "Ticking"
                      : "Open for Offers";
                  return item.value < 50 ? null : displayLabel;
                }}
                dataset={launchedDataset}
                layout="horizontal"
                height={dateRange * barHeight}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                grid={{
                  horizontal: true,
                  vertical: true,
                }}
                tooltip={{ trigger: "none" }}
                sx={{
                  ".MuiBarLabel-root": {
                    fill: "white",
                  },
                  ".MuiChartsAxis-tickLabel": {
                    fill: "white",
                  },
                  "& .MuiChartsAxis-line": {
                    display: "none",
                  },
                  "& *": {
                    pointerEvents: "auto",
                  },
                }}
                onItemClick={(event, identifier) => {
                  const index = identifier.dataIndex;
                  handleDateSelect(launchedDataset[index].date);
                }}
              />
            </Box>

            <DateNavBar />
          </Box>
        </Box>

        <RoomDatesChartItemDetail
          {...props}
          date={dateSelect}
          expandDateDetail={dateSelect ? dateSelect.id : null}
          handleExpandDateDetail={() => handleDateSelect(null)}
        />
      </Box>

      {/* ------------------ LEGACY HIDDEN ------------------ */}
      {/* <Box className="box-default" hidden={true}>
        <Box
          className="box-default"
          sx={{
            mb: configEntzy.APP_SPACING_LG,
          }}
        >
          <Box className="box-default">
            <HeaderTag text="Dates Launched and Coming Up" />
          </Box>
          {sortedLaunchedDates.length > 0 ? (
            <Box className="box-default">
              {sortedLaunchedDates.map((dateId) => {
                const daysToDate = dayjs(dateId, "YYYY-MM-DD").diff(
                  dayjs(),
                  "days"
                );
                const date =
                  eventContext.state.event.calendar.dates.combined.active.find(
                    (date) => date.id === dateId
                  );
                return (
                  <Box
                    key={dateId}
                    className="box-inline bg-white shadow-default action-pointer"
                    sx={{
                      p: configEntzy.APP_SPACING_MD,
                      m: configEntzy.APP_SPACING_XS,
                      backgroundColor: "primary.main",
                      borderRadius: configEntzy.BORDER_SIZE_LG,
                    }}
                    onClick={() => handleDateSelect(date)}
                  >
                    <Typography variant="subtitle2" fontFamily="monospace">
                      <FontAwesomeIcon icon={iconLaunched} fixedWidth />
                    </Typography>
                    <Typography variant="subtitle2" fontFamily="monospace">
                      <span>&nbsp;</span>
                      <span>
                        {dayjs(dateId, "YYYY-MM-DD").format("ddd DD MMM")}
                      </span>
                      <span>&nbsp;</span>
                    </Typography>
                    <Typography variant="subtitle2" fontFamily="monospace">
                      <em>
                        in {daysToDate} day{daysToDate === 1 ? "" : "s"}
                      </em>
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Box className="box-default content-disabled">
              <Typography variant="subtitle1">
                <em>No active date launches</em>
              </Typography>
            </Box>
          )}
        </Box>
        <Box className="box-default">
          <Box className="box-default">
            <HeaderTag text="Dates Ticking with Offer Activity" />
          </Box>

          {eventContext.state.event.calendar.dates.combined.active.length >
          0 ? (
            <Box className="box-default">
              {eventContext.state.event.calendar.dates.combined.active.map(
                (date) => {
                  const daysToDate = dayjs(date.id, "YYYY-MM-DD").diff(
                    dayjs(),
                    "days"
                  );
                  return (
                    <Box
                      key={date.id}
                      className="box-inline shadow-default action-pointer nooverflow"
                      sx={{
                        p: configEntzy.APP_SPACING_SM,
                        m: configEntzy.APP_SPACING_XS,
                        borderRadius: configEntzy.BORDER_SIZE_LG,
                        backgroundColor: date.isExpired
                          ? "primaryDisabled.main"
                          : "secondary.main",

                        color: date.isExpired ? "lightgray" : "black",
                        opacity: date.isExpired ? 0.5 : 1,
                        display:
                          eventContext.state.event.calendar.dates.launched.future.keys.includes(
                            date.id
                          )
                            ? "none"
                            : undefined,
                      }}
                      onClick={() => handleDateSelect(date)}
                    >
                      <Typography variant="subtitle2" fontFamily="monospace">
                        <strong>
                          <span>&nbsp;</span>
                          <span>
                            {dayjs(date.id, "YYYY-MM-DD").format("ddd DD MMM")}
                          </span>
                          <span>&nbsp;</span>
                        </strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontFamily="monospace"
                        sx={{
                          pb: configEntzy.APP_SPACING_SM,
                          mb: configEntzy.APP_SPACING_SM,
                          borderBottom: "1px solid #555",
                        }}
                      >
                        {date.isExpired ? (
                          <span>
                            <em>Expired</em>
                          </span>
                        ) : (
                          <em>
                            in {daysToDate} day{daysToDate === 1 ? "" : "s"}
                          </em>
                        )}
                      </Typography>

                      <Slide in={progressTickerView === 1} direction="right">
                        <Typography
                          variant="body1"
                          sx={{
                            textAlign: "left",
                            display:
                              progressTickerView === 1 ? "block" : "none",
                          }}
                        >
                          <span>
                            {date.isRunnerOffer ? (
                              <FontAwesomeIcon
                                icon={iconCheck}
                                color="green"
                                fixedWidth
                              />
                            ) : (
                              <FontAwesomeIcon icon={iconPending} fixedWidth />
                            )}
                          </span>
                          <span>&nbsp;&nbsp;</span>
                          <span>Runners</span>
                        </Typography>
                      </Slide>

                      <Slide in={progressTickerView === 2} direction="left">
                        <Typography
                          variant="body1"
                          sx={{
                            textAlign: "right",
                            display:
                              progressTickerView === 2 ? "block" : "none",
                          }}
                        >
                          <span>Riders</span>
                          <span>&nbsp;</span>
                          <span>{date.progress.trigger.quantity}%</span>
                          <span>&nbsp;&nbsp;</span>
                          <span>
                            {date.progress.trigger.quantity > 0 ? (
                              <FontAwesomeIcon
                                icon={iconCheck}
                                color="orange"
                                fixedWidth
                              />
                            ) : (
                              <FontAwesomeIcon icon={iconPending} fixedWidth />
                            )}
                          </span>
                        </Typography>
                      </Slide>

                      <Slide in={progressTickerView === 0} direction="up">
                        <Typography
                          variant="body1"
                          sx={{
                            display:
                              progressTickerView === 0 ? "block" : "none",
                          }}
                        >
                          <span style={{ float: "left" }}>
                            {date.isRunnerOffer ? (
                              <FontAwesomeIcon
                                icon={iconCheck}
                                color="green"
                                fixedWidth
                              />
                            ) : (
                              <FontAwesomeIcon icon={iconPending} fixedWidth />
                            )}
                          </span>
                          <span>{date.progress.trigger.quantity}%</span>
                          <span style={{ float: "right" }}>
                            {date.progress.trigger.quantity > 0 ? (
                              <FontAwesomeIcon
                                icon={iconCheck}
                                color="orange"
                                fixedWidth
                              />
                            ) : (
                              <FontAwesomeIcon icon={iconPending} fixedWidth />
                            )}
                          </span>
                        </Typography>
                      </Slide>

                      <LinearProgress
                        variant="determinate"
                        value={date.progress.trigger.quantity}
                        color={date.isExpired ? "secondaryDisabled" : "primary"}
                        className="shadow-default"
                        sx={{
                          mt: configEntzy.APP_SPACING_SM,
                          width: "100%",
                          height: configEntzy.AVATAR_SIZE_XS,
                          borderRadius: configEntzy.BORDER_SIZE_LG,
                        }}
                      />
                      <RoomDatesChartItemDetail
                        {...props}
                        date={date}
                        expandDateDetail={expandDateDetail}
                        handleExpandDateDetail={handleExpandDateDetail}
                      />
                    </Box>
                  );
                }
              )}
            </Box>
          ) : (
            <Box className="box-default content-disabled">
              <Typography variant="subtitle1">
                <em>No active date offers</em>
              </Typography>
            </Box>
          )}
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_LG,
            }}
          >
            <Typography variant="subtitle2" color="primary">
              <span
                style={{
                  padding: "0.5rem",
                  borderTop: "1px solid #ccc",
                  borderBottom: "1px solid #ccc",
                }}
              >
                <span>Launching is at</span>
                <span>&nbsp;&nbsp;</span>
                <span className="bg-white-t25 bdp2 rounded">
                  &nbsp;{eventContext.state.event.calendar.limits.notice}&nbsp;
                </span>
                <span>&nbsp;&nbsp;</span>
                <span>day</span>
                {eventContext.state.event.calendar.limits.notice === 1
                  ? ""
                  : "s"}
                <span>&nbsp;notice</span>
              </span>
            </Typography>
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
}

export default RoomLaunchpadUpdates;

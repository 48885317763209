// react core
import { useContext, useState } from "react";

// date handling
import dayjs from "dayjs";
// import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
// import { styled } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// entzy context and services
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import {
  DateTickingHeader,
  DateTickingProgressBar,
  DateTickingBreakdown,
} from "components/event/lists/ListDates";
import { ActionBoxButton } from "components/utils/common/CommonButtons";
import { FallbackMessage } from "components/event/lists/ListFallbacks";

// entzy components
import RoomDatesTicking from "./RoomDatesTicking";
import ActionCore from "components/event/actions/ActionCore";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCalendarCheck as iconOffer } from "@fortawesome/pro-solid-svg-icons";
import { faClose as iconClose } from "@fortawesome/pro-solid-svg-icons";

export const RoomDatesChartItemDetail = (props) => {
  const user = props.user;
  const date = props.date;
  const expandDateDetail = props.expandDateDetail;
  const handleExpandDateDetail = props.handleExpandDateDetail;
  const eventContext = useContext(EventContext);
  // const mainContext = useContext(MainContext);

  // check if before yesterday
  const isDateOfferable =
    date &&
    dayjs(date.id, "YYYY-MM-DD").isAfter(
      dayjs().add(eventContext.state.event.calendar.limits.notice, "days")
    );

  return (
    <Box className="box-default">
      {date && (
        <Drawer
          anchor="bottom"
          open={date.id === expandDateDetail}
          onClose={handleExpandDateDetail}
          keepMounted={true}
        >
          <Box
            className="box-default bg-black-t90"
            sx={{
              p: configEntzy.APP_SPACING_MD2X,
            }}
          >
            <Box
              className="box-default"
              sx={{
                pb: configEntzy.APP_SPACING_MD,
                mb: configEntzy.APP_SPACING_MD,
                borderBottom: "1px solid #fff",
              }}
            >
              <Box className="box-default quarter-width">
                <Typography variant="h6">
                  <span>&nbsp;</span>
                </Typography>
              </Box>
              <Box className="box-default half-width">
                <Typography variant="h6" color="orange">
                  <span>{date.dow}</span>
                  <span>&nbsp;</span>
                  <span>{date.day}</span>
                  <span>&nbsp;</span>
                  <span>{date.month}</span>
                  <span>&nbsp;</span>
                  <span>{date.year}</span>
                </Typography>
                <Typography variant="subtitle2" color="white">
                  Live Date Activity
                </Typography>
              </Box>
              <Box
                className="box-default quarter-width text-right action-pointer"
                onClick={handleExpandDateDetail}
              >
                <Typography variant="h6">
                  <FontAwesomeIcon
                    icon={iconClose}
                    transform="grow-4"
                    fixedWidth
                  />
                </Typography>
              </Box>
            </Box>
            {date.ticking ? (
              <RoomDatesTicking key={date.id} {...props} viewDate={date} />
            ) : (
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_LG,
                  pb: configEntzy.APP_SPACING_LG,
                }}
              >
                {isDateOfferable ? (
                  <Box className="box-default">
                    <Typography variant="subtitle2">
                      <em>No date activity yet</em>
                    </Typography>
                  </Box>
                ) : (
                  <Box className="box-default">
                    <Typography variant="subtitle2">
                      This date is expired or past notice
                    </Typography>
                    <Typography variant="subtitle2">
                      Check out other dates to make an offer
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            <Box
              className="box-default"
              // sx={{
              //   p: configEntzy.APP_SPACING_MD,
              // }}
            >
              <Container maxWidth="md" disableGutters>
                <Box
                  className="box-default shadow-dark"
                  sx={{
                    p: configEntzy.APP_SPACING_LG,
                    borderRadius: configEntzy.BORDER_SIZE_XL,
                  }}
                >
                  <Container maxWidth="sm">
                    {isDateOfferable ? (
                      <Box className="box-default">
                        <ActionCore
                          {...props}
                          room="dates"
                          actionText="Make Offer"
                          actionParams={{
                            dateKey: date.DateKey,
                          }}
                          // icon={iconOffer}
                          mini={true}
                          fullWidth={true}
                        />
                      </Box>
                    ) : (
                      <Box className="box-default" hidden={!user.connected}>
                        <ActionBoxButton
                          size="small"
                          text="View Matched Dates"
                          bgColor="primary.main"
                          color="black"
                          rounded={true}
                          onClick={() => {
                            eventContext.focusModule("entry");
                          }}
                        />
                      </Box>
                    )}
                  </Container>
                </Box>
              </Container>
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export const RoomDatesChartItem = (props) => {
  const date = props.item;
  const eventView = props.eventView;
  const eventContext = useContext(EventContext);
  const mainContext = useContext(MainContext);

  const [expandDate, setExpandDate] = useState(eventView ? true : false);
  const [expandDateDetail, setExpandDateDetail] = useState(null);

  const handleExpandDate = () => {
    setExpandDate(!expandDate);
  };
  const handleExpandDateDetail = () => {
    setExpandDateDetail(expandDateDetail === date.id ? null : date.id);
  };

  // const CustomDateCalendar = styled(DateCalendar)(({ theme }) => ({
  //   "& .MuiDayCalendar-weekDayLabel": {
  //     color: "white",
  //   },
  //   "& .MuiIconButton-root": {
  //     display: "none",
  //   },
  //   "& .MuiPickersCalendarHeader-root": {
  //     display: "none",
  //   },
  // }));

  return (
    <Box
      key={date.id}
      className={
        "box-default nooverflow" + (expandDate ? "" : " action-pointover")
      }
      sx={{
        height: expandDate ? "auto" : configEntzy.AVATAR_CONTAINER_SM,
        borderBottom: expandDate ? "none" : "2px solid #555",
        // pl: configEntzy.APP_SPACING_MD,
        // pr: configEntzy.APP_SPACING_MD,
        mb: configEntzy.APP_SPACING_MD,
      }}
    >
      <Box className="box-default">
        {/* {!eventView && expandDate && (
          <Box className="box-default text-right">
            <Typography
              variant="subtitle1"
              sx={{
                mt: configEntzy.APP_SPACING_MD,
                mb: configEntzy.APP_SPACING_MD,
              }}
            >
              <FontAwesomeIcon
                icon={iconClose}
                className="action-pointer"
                size="2x"
                onClick={handleExpandDate}
                fixedWidth
              />
            </Typography>
          </Box>
        )} */}
        <Box
          className={
            "box-default " +
            (eventContext.state.event.calendar.dates.launched.all.keys.includes(
              date.id
            )
              ? "shadow-default bg-highlight"
              : date.isExpired
              ? "bg-black-t10"
              : "bg-black-t50")
          }
          sx={{
            p: configEntzy.APP_SPACING_MD,
            mb: configEntzy.APP_SPACING_MD,
            borderRadius: configEntzy.BORDER_SIZE_XL,
          }}
        >
          <Grid container spacing={0}>
            <Grid
              item
              xs={6}
              sx={{
                borderRight: "1px solid #333",
              }}
            >
              <Box
                className="box-default action-pointer"
                onClick={handleExpandDate}
              >
                <DateTickingHeader
                  date={date}
                  runners={true}
                  triggers={eventContext.state.event.calendar.triggers}
                  limits={eventContext.state.event.calendar.limits}
                  launched={eventContext.state.event.calendar.dates.launched.all.keys.includes(
                    date.id
                  )}
                  launchPerTicket={
                    eventContext.state.event.data.LaunchPerTicket
                  }
                  eventView={eventView}
                />
              </Box>
              <Box
                className="box-default action-pointer"
                onClick={handleExpandDateDetail}
              >
                <DateTickingProgressBar
                  date={date}
                  runners={true}
                  triggers={eventContext.state.event.calendar.triggers}
                  limits={eventContext.state.event.calendar.limits}
                  icons={{ trigger: props.module.icon.trigger }}
                  launched={eventContext.state.event.calendar.dates.launched.all.keys.includes(
                    date.id
                  )}
                  launchPerTicket={
                    eventContext.state.event.data.LaunchPerTicket
                  }
                  eventView={eventView}
                />
                {eventView &&
                mainContext.state.viewer.trail.calendarCompactView ? (
                  <></>
                ) : (
                  <DateTickingBreakdown
                    date={date}
                    runners={true}
                    triggers={eventContext.state.event.calendar.triggers}
                    limits={eventContext.state.event.calendar.limits}
                    launched={eventContext.state.event.calendar.dates.launched.all.keys.includes(
                      date.id
                    )}
                    launchPerTicket={
                      eventContext.state.event.data.LaunchPerTicket
                    }
                    eventView={eventView}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className="box-default action-pointer"
                onClick={handleExpandDate}
              >
                <DateTickingHeader
                  date={date}
                  runners={false}
                  triggers={eventContext.state.event.calendar.triggers}
                  limits={eventContext.state.event.calendar.limits}
                  launched={eventContext.state.event.calendar.dates.launched.all.keys.includes(
                    date.id
                  )}
                  launchPerTicket={
                    eventContext.state.event.data.LaunchPerTicket
                  }
                  eventView={eventView}
                />
              </Box>
              <Box
                className="box-default action-pointer"
                onClick={handleExpandDateDetail}
              >
                <DateTickingProgressBar
                  date={date}
                  runners={false}
                  triggers={eventContext.state.event.calendar.triggers}
                  limits={eventContext.state.event.calendar.limits}
                  icons={{ trigger: props.module.icon.trigger }}
                  launched={eventContext.state.event.calendar.dates.launched.all.keys.includes(
                    date.id
                  )}
                  launchPerTicket={
                    eventContext.state.event.data.LaunchPerTicket
                  }
                  eventView={eventView}
                />
                {eventView &&
                mainContext.state.viewer.trail.calendarCompactView ? (
                  <></>
                ) : (
                  <DateTickingBreakdown
                    date={date}
                    runners={false}
                    triggers={eventContext.state.event.calendar.triggers}
                    limits={eventContext.state.event.calendar.limits}
                    launched={eventContext.state.event.calendar.dates.launched.all.keys.includes(
                      date.id
                    )}
                    launchPerTicket={
                      eventContext.state.event.data.LaunchPerTicket
                    }
                    eventView={eventView}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
          {/* show calendar with date highlighted */}
          {/* <Grow in={expandDate} timeout={1000}>
              <Box
                className="box-default bg-black-t50 text-center"
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                  mb: configEntzy.APP_SPACING_MD,
                  borderRadius: configEntzy.BORDER_SIZE_LG,
                  color: "#fff",
                }}
              >
                <Box
                  className="box-default"
                  sx={{
                    pt: configEntzy.APP_SPACING_MD2X,
                    pb: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <Typography variant="h6" color="primary">
                    {date.month} {date.year}
                  </Typography>
                </Box>
                <Box className="box-default">
                  <CustomDateCalendar
                    defaultValue={dayjs(date.id, "YYYY-MM-DD")}
                    sx={{
                      width: "100%",
                    }}
                    disabled={true}
                  />
                </Box>
              </Box>
            </Grow> */}

          <RoomDatesChartItemDetail
            {...props}
            date={date}
            expandDateDetail={expandDateDetail}
            handleExpandDateDetail={handleExpandDateDetail}
          />
        </Box>
      </Box>
    </Box>
  );
};

function RoomDatesChart(props) {
  const eventContext = useContext(EventContext);

  return (
    <Box id="anchor-room-dates-chart" className="box-default">
      {!eventContext.state.event.data.Active && (
        <Box
          className="box-default"
          sx={{
            p: configEntzy.APP_SPACING_LG,
          }}
        >
          <Typography variant="subtitle2">Offer Exchange Closed</Typography>
          <Typography variant="subtitle1">
            <em>Eventuator is Inactive</em>
          </Typography>
        </Box>
      )}

      <Box
        className="box-default"
        sx={{
          textAlign: "left",
          pt: configEntzy.APP_SPACING_MD,
        }}
      >
        {eventContext.state.event.calendar.dates.combined.active.length > 0 ? (
          <Box className="box-default">
            {eventContext.state.event.calendar.dates.combined.active.map(
              (item) => {
                return (
                  <RoomDatesChartItem key={item.id} {...props} item={item} />
                );
              }
            )}
          </Box>
        ) : (
          <FallbackMessage
            fallback={props.module.fallback}
            room={true}
            split={true}
          />
        )}
      </Box>
    </Box>
  );
}

export default RoomDatesChart;

// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose as iconClose } from "@fortawesome/pro-thin-svg-icons";
import { faCheck as iconActive } from "@fortawesome/pro-duotone-svg-icons";
import { faTimes as iconInactive } from "@fortawesome/pro-duotone-svg-icons";
import { faEdit as iconEdit } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as iconCircle } from "@fortawesome/pro-solid-svg-icons";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";
// import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";

function ActionLaunchpadRunnerCriteria(props) {
  const section = "criteria";
  // const user = props.user;
  const editing = props.editing;
  const handleEdit = props.handleEdit;
  const anchorTop = "anchor-launchpad-edit-" + section + "-top";
  const handleRunnerActionCallback = props.handleRunnerActionCallback;

  // const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  return (
    <Box id={anchorTop} className="box-default">
      <Box className="box-default">
        <Typography variant="h6" color="primary">
          Launch Criteria
        </Typography>
        <Typography variant="subtitle1" color="white">
          <em>Set launch triggers and guardrails</em>
        </Typography>
      </Box>
      {editing ? (
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_MD2X,
          }}
        >
          {/* <Box
            className="box-default text-right"
            sx={{
              pb: configEntzy.APP_SPACING_MD2X,
            }}
          >
            <ActionButton
              color="bright"
              size="small"
              text="Exit Criteria Editor"
              onClick={() => handleEdit()}
              endIcon={<FontAwesomeIcon icon={iconClose} fixedWidth />}
            />
          </Box> */}
          <InputForm
            navigate={props.navigate}
            fields={[
              {
                id: "setting-notice",
                type: "text-standard",
                label: "Launch Notice",
                value: eventContext.state.event.calendar.limits.notice,
                minValue: eventContext.state.constants.events.minLaunchNotice,
                maxValue: eventContext.state.constants.events.maxLaunchNotice,
                numeric: true,
                required: true,
                border: true,
                startAdornment:
                  eventContext.state.toolbox.getAdormentIcon("time-min"),
                endAdornment: "days",
                footnote: "The notice period you need before each date launch",
              },
              {
                id: "setting-trigger",
                type: "text-standard",
                label: "Minimum Riders",
                value: eventContext.state.event.calendar.triggers.default,
                minValue: eventContext.state.constants.events.minLaunchGuests,
                maxValue: eventContext.state.constants.events.maxLaunchGuests,
                numeric: true,
                required: true,
                border: true,
                startAdornment:
                  eventContext.state.toolbox.getAdormentIcon("user-min"),
                endAdornment: "people",
                footnote: "The minimum people required to trigger a date",
              },
              {
                id: "setting-capacity",
                type: "text-standard",
                label: "Maximum Riders (across all tickets)",
                value: eventContext.state.event.calendar.limits.capacity,
                minValue: eventContext.state.constants.events.minLaunchGuests,
                maxValue: eventContext.state.constants.events.maxLaunchGuests,
                numeric: true,
                required: true,
                border: true,
                startAdornment:
                  eventContext.state.toolbox.getAdormentIcon("user-max"),
                endAdornment: "people",
                footnote:
                  "The maximum number of people per date. This cannot exceed ticket capacity (currently " +
                  eventContext.state.event.calendar.limits.capacityTickets +
                  " across " +
                  eventContext.state.event.calendar.tickets.selectable.active
                    .length +
                  " type" +
                  (eventContext.state.event.calendar.tickets.selectable.active
                    .length === 1
                    ? ")"
                    : "s)") +
                  " but you can oversupply different ticket types as the date is capped.",
              },
              {
                id: "event-private",
                type: "switch-standard",
                label: "Private",
                value:
                  eventContext.state.event.data.Access === "private"
                    ? true
                    : false,
                required: true,
                startAdornment:
                  eventContext.state.toolbox.getAdormentIcon("private-switch"),
                endAdornment: "switch",
                footnote:
                  "If private is active riders need to request access to enter the eventuator and will join a rider queue for your review.",
              },
              {
                id: "event-active",
                type: "switch-standard",
                label: "Launching",
                value: eventContext.state.event.data.Active ? true : false,
                required: true,
                startAdornment:
                  eventContext.state.toolbox.getAdormentIcon("active-switch"),
                endAdornment: "switch",
                footnote:
                  "If launching is active the eventuator is live and dates can trigger.",
              },
              {
                id: "event-launch-per-ticket",
                type: "switch-standard",
                label: "Launch Per Ticket",
                value: eventContext.state.event.data.LaunchPerTicket
                  ? true
                  : false,
                required: true,
                startAdornment:
                  eventContext.state.toolbox.getAdormentIcon("ticket-text"),
                endAdornment: "switch",
                footnote:
                  "If the launch per ticket setting is active dates will launch only when a single ticket type reaches the minimum riders. By default when inactive, dates will launch when the overall minimum riders is reached across all ticket types.",
              },
            ]}
            submitText="Update Launch Criteria"
            callback={handleRunnerActionCallback}
            cancel={() => {
              handleEdit();
            }}
            cancelText={
              <span>
                <span>Exit Criteria Editor</span>
                <span>&nbsp;&nbsp;</span>
                <FontAwesomeIcon
                  icon={iconClose}
                  transform="grow-6 right-3"
                  fixedWidth
                />
              </span>
            }
            cancelAutoConfirm={true}
          />
        </Box>
      ) : (
        <Box
          className="box-default action-pointer"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
            color: "white",
          }}
          onClick={() => handleEdit("criteria")}
        >
          <Box
            className="box-default text-left shadow-light"
            sx={{
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <Typography variant="subtitle2">
              <span>
                {eventContext.state.toolbox.getAdormentIcon("time-min")}
              </span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>Launch Notice</span>
              <span>&nbsp;</span>
              <span className="text-sit-right">
                <span>{eventContext.state.event.calendar.limits.notice}</span>
                <span>&nbsp;</span>
                <span>Days</span>
              </span>
            </Typography>
          </Box>
          <Box
            className="box-default text-left shadow-light"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <Typography variant="subtitle2">
              <span>
                {eventContext.state.toolbox.getAdormentIcon("user-min")}
              </span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>Min Riders</span>
              <span>&nbsp;</span>
              <span className="text-sit-right">
                <span>
                  {eventContext.state.event.calendar.triggers.default}
                </span>
                <span>&nbsp;</span>
                <span>People</span>
              </span>
            </Typography>
          </Box>
          <Box
            className="box-default text-left shadow-light"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <Typography variant="subtitle2">
              <span>
                {eventContext.state.toolbox.getAdormentIcon("user-max")}
              </span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>Max Riders</span>
              <span>&nbsp;</span>
              <span className="text-sit-right">
                <span>{eventContext.state.event.calendar.limits.capacity}</span>
                <span>&nbsp;</span>
                <span>People</span>
              </span>
            </Typography>
          </Box>
          {/* EDIT ICON IN MIDDLE */}
          {/* ----------------------------------------- */}
          <Box className="box-default zero-height">
            <Typography variant="h1" color="primary">
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon
                  icon={iconCircle}
                  color="#ccc"
                  transform="grow-15 up-8"
                />
                <FontAwesomeIcon
                  icon={iconCircle}
                  color="white"
                  transform="grow-14 up-8"
                />
                <FontAwesomeIcon icon={iconEdit} transform="up-8" />
              </span>
            </Typography>
          </Box>
          {/* ----------------------------------------- */}
          <Box
            className="box-default text-left shadow-light"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <Typography variant="subtitle2">
              <span>
                {eventContext.state.toolbox.getAdormentIcon("private-switch")}
              </span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>Private</span>
              <span>&nbsp;</span>
              <span className="text-sit-right">
                {eventContext.state.event.data.Access === "private" ? (
                  <span>
                    <span>Active&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <FontAwesomeIcon icon={iconActive} color="green" />
                  </span>
                ) : (
                  <span>
                    <span>Inactive&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <FontAwesomeIcon icon={iconInactive} color="red" />
                  </span>
                )}
              </span>
            </Typography>
          </Box>
          <Box
            className="box-default text-left shadow-light"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <Typography variant="subtitle2">
              <span>
                {eventContext.state.toolbox.getAdormentIcon("active-switch")}
              </span>

              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>Launching</span>
              <span>&nbsp;</span>
              <span className="text-sit-right">
                {eventContext.state.event.data.Active ? (
                  <span>
                    <span>Active&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <FontAwesomeIcon icon={iconActive} color="green" />
                  </span>
                ) : (
                  <span>
                    <span>Inactive&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <FontAwesomeIcon icon={iconInactive} color="red" />
                  </span>
                )}
              </span>
            </Typography>
          </Box>
          <Box
            className="box-default text-left shadow-light"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <Typography variant="subtitle2">
              <span>
                {eventContext.state.toolbox.getAdormentIcon("ticket-text")}
              </span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>Launch per Ticket</span>
              <span>&nbsp;</span>
              <span className="text-sit-right">
                {eventContext.state.event.data.LaunchPerTicket ? (
                  <span>
                    <span>Active&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <FontAwesomeIcon icon={iconActive} color="green" />
                  </span>
                ) : (
                  <span>
                    <span>Inactive&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <FontAwesomeIcon icon={iconInactive} color="red" />
                  </span>
                )}
              </span>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ActionLaunchpadRunnerCriteria;

// react core
import { useState, useEffect, useCallback, useRef } from "react";

// text ticker
import Marquee from "react-fast-marquee";

// material design
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs as iconPlace } from "@fortawesome/pro-duotone-svg-icons";
import { faMapMarkerQuestion as iconMapsSuggestion } from "@fortawesome/pro-duotone-svg-icons";
import { faQuestionSquare as iconLinkSuggestion } from "@fortawesome/pro-duotone-svg-icons";
import { faMapPin as iconGoogleMaps } from "@fortawesome/pro-duotone-svg-icons";
import { faLink as iconExternalLink } from "@fortawesome/pro-duotone-svg-icons";
import { faExternalLink as iconFollowLink } from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt as iconRemove } from "@fortawesome/pro-duotone-svg-icons";
import { faUndo as iconRestore } from "@fortawesome/pro-duotone-svg-icons";
import { faInfoCircle as iconText } from "@fortawesome/pro-duotone-svg-icons";
import { faArrowRight as iconNext } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck as iconAccept } from "@fortawesome/pro-duotone-svg-icons";
// import { faMapLocationDot as iconFocus } from "@fortawesome/pro-solid-svg-icons";
// import { faPlus as iconAdd } from "@fortawesome/pro-duotone-svg-icons";
// import { faClose as iconClose } from "@fortawesome/pro-duotone-svg-icons";

// entzy components
import configEntzy from "components/config/ConfigEntzy";
import {
  ActionButton,
  ActionBoxButton,
  CopyButton,
} from "components/utils/common/CommonButtons";
import {
  ActionAlert,
  ActionLoader,
} from "components/utils/common/CommonLoaders";
import { UserAvatar } from "components/utils/common/CommonAvatars";
import {
  TextTag,
  ContentWarningHeaderTag,
  ContentWarningTag,
} from "components/utils/common/CommonTags";
import { FallbackMessage } from "components/event/lists/ListFallbacks";
import { countriesGetItem } from "models/Country";
import {
  getDomainFromUrl,
  scrollIntoViewIfNeeded,
  idGenerator,
  getExternalTag,
  getExternalLink,
  isExternalLink,
  isUrl,
  jsonTryParse,
} from "models/Tools";

// entzy assets and icons
// import MapIcon from "assets/icons/map-pin.png";

const GooglePlace = (props) => {
  // initial states
  const user = props.user;
  const country = props.country;
  const runnerAction = props.runnerAction;
  const riderAction = props.riderAction;
  const editFormCallback = props.callback;
  const editView = props.editView;
  const mapViewOnly = props.mapViewOnly;
  const mapViewLink = props.mapViewLink;

  // for now remove external links from locations (needs a redesign)
  const placeLocations = props.locations.filter(
    (location) => !isExternalLink(location)
  );

  // separate order tag from title in locations
  const orderedLocations = placeLocations.map((location, index) => {
    let orderTag = index + 1;
    let title = location.Title;
    if (title.includes("||")) {
      let titleParts = title.split("||");
      title = titleParts[0];
      orderTag = parseInt(titleParts[1]);
    }
    return {
      ...location,
      Title: title,
      OrderTag: orderTag,
    };
  });
  orderedLocations.sort((a, b) => a.OrderTag - b.OrderTag);

  const [locations, setLocations] = useState(orderedLocations);
  const [addedLocation, setAddedLocation] = useState(null);
  const [editViewer, setEditViewer] = useState(
    1
    // orderedLocations.length
    //   ? orderedLocations[0].LinkTarget.startsWith(getExternalTag())
    //     ? 2
    //     : 1
    //   : 0
  );
  const [focusLocation, setFocusLocation] = useState(
    editView || orderedLocations.length === 0
      ? { LinkId: null }
      : orderedLocations[0]
    // { LinkId: null }
  );
  const [changes, setChanges] = useState(false);
  const [focusViewer, setFocusViewer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");

  // input refs
  const roomContainerRef = props.roomContainerRef;
  const inputGoogleMapsTextRef = useRef();
  const inputGoogleMapsRef = useRef();
  const inputExternalLinkTextRef = useRef();
  const inputExternalLinkRef = useRef();
  const googleMapsRef = useRef();
  const externalLinkRef = useRef();

  // auto save on any change to locations
  // useEffect(() => {
  //   if (changes) {
  //     setChanges(false);
  //     handleFormSubmit();
  //   }
  // }, [user.connected, changes, locations, editFormCallback]);
  const handleFormSubmit = async (event) => {
    // event.preventDefault();
    setLoading(true);
    setAlert(false);
    if (user.connected) {
      try {
        const locationSettings = [];
        locations.forEach((location, index) => {
          let orderTag = index + 1;
          locationSettings.push({
            id: location.LinkId,
            active: location.LinkStatus === "active" ? true : false,
            name: location.Title + " ||" + orderTag + "||",
            description: location.Description,
            coordinates: location.LinkTarget,
            user: location.UserId,
            tag: "Location",
            subtag: location.Accepted ? "accepted" : location.Category,
            order: orderTag,
          });
        });
        const results = await editFormCallback(locationSettings);
        setLoading(false);
        if (results.alert) {
          setAlert(true);
          setMessage(results.message);
        }
      } catch (e) {
        setLoading(false);
        setAlert(true);
        // TODO: replace this with stock message and add a log writer to catch
        setMessage(e.message);
        // console.error(e.message);
      }
    } else {
      setLoading(false);
      setAlert(true);
      setMessage("Connect to manage places");
    }
  };

  // handle save all
  const handleSaveAllChanges = () => {
    setAddedLocation(null);
    handleFormSubmit();
  };

  // to make a place the launch location make it first in list
  const handlePlaceLaunch = (location) => {
    const newLocations = locations.filter(
      (item) => item.LinkId !== location.LinkId
    );
    newLocations.unshift(location);
    setLocations(newLocations);
    handlePlaceFocus(location);
    setChanges(true);
  };

  // change viewer focus and scroll to it if needed
  const handlePlaceFocus = (location) => {
    setFocusLocation(location);
    setFocusViewer(true);
    setEditViewer(editView ? (isExternalLink(location) ? 2 : 1) : 0);
  };
  useEffect(() => {
    const scrollToViewerIfNeeded = function (location) {
      const target = roomContainerRef.current;
      if (!target) return;
      scrollIntoViewIfNeeded(target);
      setFocusViewer(false);
    };
    if (focusViewer) {
      scrollToViewerIfNeeded(focusLocation);
    }
  }, [focusLocation, focusViewer, roomContainerRef]);

  const updatePlaceAttribute = (location, attribute, value) => {
    const newLocations = [];
    locations.forEach((item) => {
      if (item.LinkId === location.LinkId) {
        item[attribute] = value;
      }
      newLocations.push(item);
    });
    setLocations(newLocations);
    setChanges(true);
  };

  const handlePlaceActive = (location) => {
    updatePlaceAttribute(
      location,
      "LinkStatus",
      location.LinkStatus === "active" ? "inactive" : "active"
    );
  };

  const handleAcceptSuggestion = (location) => {
    updatePlaceAttribute(
      location,
      "Accepted",
      location.Accepted ? false : true
    );
  };

  const TextBanner = (props) => {
    return (
      <Typography variant="body1" noWrap>
        <span>&nbsp;&nbsp;&nbsp;</span>
        <FontAwesomeIcon
          icon={props.icon}
          style={{ color: "green" }}
          fixedWidth
        />
        <span>&nbsp;&nbsp;&nbsp;</span>
        <span style={{ fontWeight: props.bold ? 800 : 400 }}>{props.text}</span>
        <span>&nbsp;&nbsp;&nbsp;</span>
      </Typography>
    );
  };
  const PlaceTicker = (props) => {
    return (
      <Marquee
        speed={30}
        gradient={false}
        gradientWidth={20}
        // gradientColor={[238, 238, 238]}
      >
        {props.location.Category === "rider" ? (
          <TextBanner
            icon={
              isExternalLink(props.location)
                ? iconLinkSuggestion
                : iconMapsSuggestion
            }
            text={props.location.Title}
            bold={true}
          />
        ) : (
          <TextBanner
            icon={
              isExternalLink(props.location) ? iconExternalLink : iconGoogleMaps
            }
            text={props.location.Title}
            bold={true}
          />
        )}
      </Marquee>
    );
  };
  const DescriptionTicker = (props) => {
    return (
      <Marquee
        speed={20}
        gradient={false}
        gradientWidth={20}
        // gradientColor={[238, 238, 238]}
      >
        <TextBanner icon={iconText} text={props.location.Description} />
      </Marquee>
    );
  };

  const EmptyViewer = (props) => {
    return (
      <Box
        className="box-default"
        sx={{
          p: configEntzy.APP_SPACING_MD,
          border: configEntzy.BORDER_DASH_GSM,
          borderRadius: configEntzy.BORDER_SIZE_XL,
        }}
      >
        <Box className="box-default">
          {!user.connected ? (
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_XL,
                pb: configEntzy.APP_SPACING_XL,
              }}
            >
              <Typography variant="subtitle2" noWrap>
                MANAGE PLACES
              </Typography>
              <Typography variant="subtitle1" noWrap>
                <em>Here is where you will be able to add places</em>
              </Typography>
              <Typography variant="subtitle1" noWrap>
                <em>and make suggestions for the launch site</em>
              </Typography>
            </Box>
          ) : (
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_XL,
                pb: configEntzy.APP_SPACING_MD,
              }}
            >
              <Box className="box-default">
                <Typography variant="subtitle2" noWrap>
                  SELECT PLACE TYPE
                </Typography>
                <Typography variant="subtitle1" noWrap>
                  <em>Select whether to edit the map</em>
                </Typography>
                <Typography variant="subtitle1" noWrap>
                  <em>or your own external web links</em>
                </Typography>
              </Box>
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_LG,
                  pl: configEntzy.APP_SPACING_MD,
                  pr: configEntzy.APP_SPACING_MD,
                }}
              >
                <Box
                  className="box-default half-width"
                  sx={{
                    pl: runnerAction ? 0 : configEntzy.APP_SPACING_SM,
                    pr: riderAction ? 0 : configEntzy.APP_SPACING_SM2X,
                  }}
                >
                  <ActionBoxButton
                    variant={editViewer !== 1 ? "contained" : "outlined"}
                    size="small"
                    type="button"
                    text="Google Maps"
                    disabled={!user.connected}
                    fullWidth={true}
                    onClick={() => props.handleInput(1, 0)}
                    topIcon={
                      <FontAwesomeIcon
                        icon={iconGoogleMaps}
                        transform="grow-2 up-6"
                        fixedWidth
                      />
                    }
                  />
                </Box>
                <Box
                  className="box-default half-width"
                  sx={{
                    pl: runnerAction ? 0 : configEntzy.APP_SPACING_SM2X,
                    pr: riderAction ? 0 : configEntzy.APP_SPACING_SM,
                  }}
                >
                  <ActionBoxButton
                    variant={editViewer !== 2 ? "contained" : "outlined"}
                    size="small"
                    type="button"
                    text="Web Links"
                    fullWidth={true}
                    disabled={!user.connected}
                    onClick={() => props.handleInput(2, 0)}
                    topIcon={
                      <FontAwesomeIcon
                        icon={iconExternalLink}
                        transform="grow-2 up-6"
                        fixedWidth
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const EditPlaceNew = (props) => {
    return (
      <Box className="box-default">
        <ActionButton
          variant="contained"
          size="medium"
          type="button"
          text={
            props.editFlow > 0
              ? "Cancel"
              : editViewer === 2
              ? "New Web Link"
              : "Add New Place"
          }
          color={props.editFlow > 0 ? "bright" : "secondary"}
          disabled={!user.connected}
          fullWidth={props.editFlow > 0 ? false : true}
          onClick={() =>
            props.handleInput(editViewer, props.editFlow > 0 ? 0 : 1)
          }
          // endIcon={
          //   <FontAwesomeIcon
          //     icon={props.editFlow > 0 ? iconClose : iconAdd}
          //     transform="shrink-6 right-2"
          //     fixedWidth
          //   />
          // }
        />
      </Box>
    );
  };

  const EditPlaceSave = (props) => {
    return (
      <Box className="box-default">
        <Box className="box-default" hidden={!editView || props.editFlow > 0}>
          {loading ? (
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_MD,
                pb: configEntzy.APP_SPACING_MD,
              }}
            >
              <ActionLoader size="md" />
            </Box>
          ) : (
            <Box className="box-default">
              {!user.connected && (
                <Box
                  className="box-default"
                  sx={{
                    mb: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <ActionAlert
                    severity="info"
                    title="Connect to continue"
                    message="Tap here to activate locations"
                    onClick={props.toggleConnect}
                  />
                </Box>
              )}
              {changes && (
                <Box
                  // className="box-fixed bottom-left full-width pop-up"
                  className="box-default"
                  // sx={{
                  //   pb: configEntzy.APP_SPACING_XL,
                  // }}
                >
                  <Container maxWidth="xs">
                    <ActionButton
                      variant="contained"
                      size="medium"
                      type="button"
                      text="Save All Changes"
                      disabled={!user.connected}
                      onClick={handleSaveAllChanges}
                    />
                  </Container>
                </Box>
              )}
            </Box>
          )}
          {alert && message && (
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_MD,
              }}
            >
              <ActionAlert severity="error" message={message} />
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const EditPlaceInput = (props) => {
    const [editFlow, setEditFlow] = useState(0);
    const [inputTextValue, setInputTextValue] = useState("");

    let mapsLocations, linksLocations;
    if (locations.length > 0) {
      if (riderAction) {
        mapsLocations = locations.filter(
          (place) => !isExternalLink(place) && place.Category === "rider"
        );
        linksLocations = locations.filter(
          (place) => isExternalLink(place) && place.Category === "rider"
        );
      } else {
        mapsLocations = locations.filter((place) => !isExternalLink(place));
        linksLocations = locations.filter((place) => isExternalLink(place));
      }
    } else {
      mapsLocations = [];
      linksLocations = [];
    }

    const handleTextInput = async (event) => {
      event.preventDefault();
      setInputTextValue(event.target.value);
    };
    // input workflow handler
    // viewer flow
    // 0 = no viewer
    // 1 = map viewer
    // 2 = external link viewer
    // input flow
    // 0 = text input
    // 1 = place input
    const handleInput = (viewer, flow) => {
      if (editViewer === viewer) {
        setEditFlow(flow);
        if (flow === 0) {
          setInputTextValue("");
          setAddedLocation(null);
        }
      } else {
        // when flipping view and locations exist
        // refocus the location and viewer
        if (locations.length > 0) {
          if (viewer === 2 && linksLocations.length > 0) {
            handlePlaceFocus(linksLocations[0]);
          } else if (viewer === 1 && mapsLocations.length > 0) {
            handlePlaceFocus(mapsLocations[0]);
          } else {
            setEditViewer(viewer);
          }
        } else {
          setEditViewer(viewer);
        }
        setAddedLocation(null);
      }
    };
    const handleKeyDownTextInput = (e) => {
      if (e.key === "Enter" || e.keyCode === 13) {
        e.preventDefault();
        e.target.blur();
        handleInput(editViewer, 2);
      }
    };

    const [inputExternalLinkValue, setInputExternalLinkValue] = useState("");

    const handleExternalLinkInput = async (event) => {
      event.preventDefault();
      setInputExternalLinkValue(event.target.value);
    };
    const handlePostExternalLink = (event) => {
      let id, text, url, title;
      id = "new-" + idGenerator();
      text = inputTextValue;
      url = inputExternalLinkValue;
      title = getDomainFromUrl(inputExternalLinkValue);
      if (text.replace(/\s/g, "").length === 0) text = "No description";
      if (!url.toLowerCase().startsWith("http")) url = "https://" + url;
      if (title.length > 20) title = title.substring(0, 20) + "...";
      if (isUrl(url)) {
        const location = {
          LinkId: id,
          LinkStatus: "active",
          LinkTarget: getExternalTag() + url,
          Title: title,
          Description: text,
          Category: props.manager ? "runner" : "rider",
          UserId: props.user.identity,
        };
        setFocusLocation(location);
        setFocusViewer(true);
        setAddedLocation(location);
        setLocations([location].concat(locations));
        setChanges(true);
      } else {
        window.alert("URL seems to be invalid.\nCheck and reconfirm.");
      }
    };
    const handleKeyDownExternalLinkInput = (e) => {
      if (e.key === "Enter" || e.keyCode === 13) {
        e.preventDefault();
        e.target.blur();
        handlePostExternalLink(e);
      }
    };

    return (
      <Box className="box-default">
        {/* ---- viewers ---- */}
        <Box className="box-default">
          {editViewer === 2 && editFlow > 0 ? (
            <ExternalLinkViewer
              url={inputExternalLinkValue}
              text={inputTextValue}
            />
          ) : editViewer === 2 ? (
            <ExternalLinkViewer
              url={
                focusLocation.LinkId
                  ? isExternalLink(focusLocation)
                    ? getExternalLink(focusLocation)
                    : ""
                  : ""
              }
              text={focusLocation.LinkId ? focusLocation.Description : ""}
              moderation={focusLocation.ContentModeration}
            />
          ) : editViewer === 1 ? (
            <GoogleMapsViewer {...props} />
          ) : (
            <EmptyViewer handleInput={handleInput} />
          )}
        </Box>

        {/* ---- input text captured display ---- */}
        {editFlow === 2 && (
          <Box
            className="box-default shadow-default text-left"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
              p: configEntzy.APP_SPACING_MD,
              borderRadius: configEntzy.BORDER_SIZE_XL,
              backgroundColor: "black",
              color: "white",
            }}
          >
            <Typography variant="body2" className="overflow" noWrap>
              <span className="bdp2 bg-success rounded">
                <FontAwesomeIcon icon={iconText} fixedWidth />
                <span>&nbsp;&nbsp;</span>
                <span>Place Comment:</span>
                <span>&nbsp;</span>
              </span>
              <span>&nbsp;&nbsp;</span>
              <span>{inputTextValue}</span>
              <span>&nbsp;&nbsp;</span>
            </Typography>
          </Box>
        )}

        {/* ---- input text and place fields ---- */}
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_MD,
          }}
        >
          <Box
            className="box-default bg-white"
            sx={{
              borderRadius: configEntzy.BORDER_SIZE_XL,
              p: configEntzy.APP_SPACING_MD,
              mb: configEntzy.APP_SPACING_MD,
            }}
            hidden={editFlow === 0}
          >
            <input
              type="text"
              className="box-default rounded"
              ref={
                editViewer === 2
                  ? inputExternalLinkTextRef
                  : editViewer === 1
                  ? inputGoogleMapsTextRef
                  : null
              }
              value={inputTextValue}
              onChange={handleTextInput}
              onKeyDown={handleKeyDownTextInput}
              placeholder={
                "First enter a comment for your new " +
                (editViewer === 1 ? "place" : "") +
                (editViewer === 2 ? "link" : "") +
                " (step 1 of 2)"
              }
              style={{
                padding: "1.4rem",
                // marginBottom: "1px",
                // border: 0,
              }}
              hidden={editFlow !== 1}
            />
            <input
              type="text"
              className="box-default shadow-default rounded"
              ref={inputGoogleMapsRef}
              placeholder={
                "Start typing a location from " + countriesGetItem(country).name
              }
              style={{
                padding: "1.4rem",
                // border: 0,
              }}
              hidden={!(editViewer === 1 && editFlow === 2)}
            />
            <input
              type="url"
              className="box-default shadow-default rounded"
              ref={inputExternalLinkRef}
              value={inputExternalLinkValue}
              onChange={handleExternalLinkInput}
              onKeyDown={handleKeyDownExternalLinkInput}
              placeholder={"https:// add your place link"}
              style={{
                padding: "1.4rem",
                marginBottom: "1px",
                // border: 0,
              }}
              hidden={!(editViewer === 2 && editFlow === 2)}
            />
          </Box>
        </Box>

        {/* ---- input text confirm next ---- */}
        {/* && inputTextValue.length > 0 */}
        {editFlow === 1 && (
          <Box
            className="box-default"
            sx={{ pt: configEntzy.APP_SPACING_XS2X }}
          >
            <ActionButton
              variant="contained"
              size="medium"
              type="button"
              color="primary"
              text="NEXT"
              textRight={true}
              disabled={!user.connected}
              // disableElevation={true}
              fullWidth={true}
              onClick={() => handleInput(editViewer, 2)}
              endIcon={
                <FontAwesomeIcon
                  icon={iconNext}
                  transform="shrink-6 right-2"
                  fixedWidth
                />
              }
            />
            {/* <Divider /> */}
          </Box>
        )}

        {/* ---- links input place confirm ---- */}
        {editViewer === 2 && editFlow === 2 && (
          <Box
            className="box-default"
            sx={{ pt: configEntzy.APP_SPACING_XS2X }}
            hidden={inputExternalLinkValue.length === 0}
          >
            <ActionButton
              variant="contained"
              size="medium"
              type="button"
              color="primary"
              text="Confirm Add Link"
              disabled={!user.connected}
              // disableElevation={true}
              fullWidth={true}
              onClick={handlePostExternalLink}
              startIcon={
                <FontAwesomeIcon
                  icon={iconExternalLink}
                  transform="shrink-6 right-2"
                  fixedWidth
                />
              }
              endIcon={
                <FontAwesomeIcon
                  icon={iconNext}
                  transform="shrink-6 right-2"
                  fixedWidth
                />
              }
            />
            {/* <Divider /> */}
          </Box>
        )}

        {/* ---- input nav buttons flow 0 ---- */}
        {editViewer > 0 && (
          <Box className="box-default">
            <Box
              className="box-default"
              sx={{
                pt: editFlow > 0 ? configEntzy.APP_SPACING_XL : 0,
              }}
            >
              <Grid container spacing={0}>
                {riderAction && (
                  <Grid item xs={12}>
                    <Box
                      className="box-default"
                      // sx={{
                      //   pr: configEntzy.APP_SPACING_SM,
                      // }}
                    >
                      {editViewer !== 0 && (
                        <EditPlaceNew
                          editFlow={editFlow}
                          handleInput={handleInput}
                        />
                      )}
                    </Box>
                  </Grid>
                )}
                {/* <Grid item xs={3}>
                  <Box
                    className="box-default"
                    sx={{
                      pl: runnerAction ? 0 : configEntzy.APP_SPACING_SM,
                      pr: riderAction ? 0 : configEntzy.APP_SPACING_SM2X,
                    }}
                  >
                    <ActionButton
                      variant={editViewer !== 1 ? "contained" : "outlined"}
                      size="medium"
                      type="button"
                      text="Map"
                      color={editViewer !== 1 ? "bright" : "primary"}
                      disabled={!user.connected}
                      fullWidth={true}
                      onClick={() => handleInput(1, 0)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    className="box-default"
                    sx={{
                      pl: runnerAction ? 0 : configEntzy.APP_SPACING_SM2X,
                      pr: riderAction ? 0 : configEntzy.APP_SPACING_SM,
                    }}
                  >
                    <ActionButton
                      variant={editViewer !== 2 ? "contained" : "outlined"}
                      size="medium"
                      type="button"
                      text="Web"
                      color={editViewer !== 2 ? "bright" : "primary"}
                      fullWidth={true}
                      disabled={!user.connected}
                      onClick={() => handleInput(2, 0)}
                    />
                  </Box>
                </Grid> */}
                {runnerAction && (
                  <Grid item xs={12}>
                    <Box
                      className="box-default"
                      // sx={{
                      //   pl: configEntzy.APP_SPACING_SM,
                      // }}
                    >
                      {editViewer !== 0 && (
                        <EditPlaceNew
                          editFlow={editFlow}
                          handleInput={handleInput}
                        />
                      )}
                    </Box>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{
                    pt: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <EditPlaceSave editFlow={editFlow} />
                </Grid>
                {addedLocation && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      pt: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <ActionAlert
                      type="success"
                      message={
                        (editViewer === 1
                          ? "New map pin added."
                          : editViewer === 2
                          ? "New web link added."
                          : "New place added.") +
                        " Save to confirm all when ready."
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Box>

            {/* ---- header  ---- */}
            <Box className="box-default">
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD2X,
                }}
              >
                <Divider />
              </Box>
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD2X,
                }}
              >
                <Typography variant="subtitle2" noWrap>
                  <span>
                    {editViewer === 1 && (
                      <span>
                        {runnerAction
                          ? "Showing All Map Places"
                          : "Your Map Suggestions"}
                        :&nbsp;&nbsp;{mapsLocations.length}
                      </span>
                    )}
                    {editViewer === 2 && (
                      <span>
                        {runnerAction
                          ? "Showing All Web Places"
                          : "Your Web Suggestions"}
                        :&nbsp;&nbsp;{linksLocations.length}
                      </span>
                    )}
                  </span>
                </Typography>
              </Box>
              {((editViewer === 1 && mapsLocations.length === 0) ||
                (editViewer === 2 && linksLocations.length === 0)) && (
                <Box
                  className="box-default"
                  sx={{
                    pt: configEntzy.APP_SPACING_MD2X,
                  }}
                >
                  <Typography variant="subtitle1" color="primary" noWrap>
                    <em>No places yet</em>
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const FocusLocationViewer = (props) => {
    const isLaunchLocation =
      focusLocation &&
      locations.length > 0 &&
      focusLocation.LinkId === locations[0].LinkId &&
      focusLocation.Category === "runner";
    const mapLaunchLocations = locations.filter(
      (location) =>
        // location.LinkStatus === "active" &&
        location.Category === "runner" && !isExternalLink(location)
    );
    return (
      <Box
        className={
          "box-default shadow-default" +
          (isLaunchLocation ? " bg-black shadow-basic" : " bg-white") +
          (mapViewOnly && mapViewLink ? " action-pointer" : "")
        }
        sx={{
          // mt: configEntzy.APP_SPACING_MD,
          mb: configEntzy.APP_SPACING_SM,
          p: configEntzy.APP_SPACING_MD,
          borderRadius: configEntzy.BORDER_SIZE_XL,
          color: isLaunchLocation ? "white" : "black",
        }}
        onClick={mapViewOnly && mapViewLink ? mapViewLink : null}
        hidden={!focusLocation || editView}
      >
        {isLaunchLocation ? (
          <Typography variant="h6" className="glowing-text" noWrap>
            <span>LAUNCH LOCATION</span>
            {mapLaunchLocations.length > 0 && (
              <span>&nbsp;(1 of {mapLaunchLocations.length})</span>
            )}
          </Typography>
        ) : (
          <Typography variant="h6" noWrap>
            {focusLocation.Category === "rider" ? (
              <span>Rider Site Suggestion</span>
            ) : (
              <span>Other Launch Location</span>
            )}
          </Typography>
        )}

        <Typography variant="subtitle2">
          {focusLocation.Title
            ? focusLocation.Title
            : "Select or add a place to view"}
        </Typography>
        <Typography variant="subtitle1" color="primary">
          {focusLocation.Title
            ? focusLocation.Description
            : "Location details will show below"}
        </Typography>
      </Box>
    );
  };

  const ExternalLinkViewer = (props) => {
    let moderation, url, text;

    url = props.url;
    text = props.text;
    moderation = props.moderation;
    if (moderation && !moderation.status) {
      moderation = jsonTryParse(moderation, {
        status: "fail",
      });
    }

    const [showModerated, setShowModerated] = useState(null);

    const handleShowModerated = (id) => {
      setShowModerated(showModerated === id ? null : id);
    };

    return (
      <Box className="box-default">
        {focusLocation && !moderation && <FocusLocationViewer />}
        <Box
          ref={externalLinkRef}
          className="box-default bg-white shadow-default"
          sx={{
            height: configEntzy.AVATAR_SIZE_XL,
            pt: configEntzy.APP_SPACING_XL,
            pb: configEntzy.APP_SPACING_XL,
            pl: configEntzy.APP_SPACING_MD,
            pr: configEntzy.APP_SPACING_MD,
            borderRadius: configEntzy.BORDER_SIZE_XL,
            // borderTopLeftRadius: "0px",
            // borderTopRightRadius: "0px",
          }}
        >
          <Box
            className="box-default"
            sx={{
              pb: configEntzy.APP_SPACING_MD,
            }}
          >
            <Typography variant="h1" color="primary">
              <FontAwesomeIcon icon={iconExternalLink} fixedWidth />
            </Typography>
          </Box>
          {url === "" ? (
            <Box className="box-default">
              <Typography variant="subtitle1" color="black">
                <span>External Links</span>
              </Typography>
              <Typography
                variant="subtitle1"
                color="primary"
                sx={{
                  mt: configEntzy.APP_SPACING_LG,
                }}
              >
                <span>Link to places anywhere on the web</span>
              </Typography>
            </Box>
          ) : (
            <Box className="box-default">
              <ContentWarningTag
                moderation={moderation}
                contentId={url}
                showModerated={showModerated}
                handleShowModerated={handleShowModerated}
              />
              {moderation && (
                <Box
                  className="box-default"
                  sx={{
                    pl: configEntzy.APP_SPACING_MD2X,
                    pr: configEntzy.APP_SPACING_MD2X,
                  }}
                >
                  {showModerated === url ? (
                    <CopyButton text={url} infoText="Copy Unsafe Link" />
                  ) : (
                    <Container maxWidth="xs">
                      <Typography variant="body2" color="red">
                        <em>
                          Dangerous link detected and disabled. Do not interact
                          with any content in this link unless you trust the
                          sender.
                        </em>
                      </Typography>
                    </Container>
                  )}
                </Box>
              )}
              {!moderation && (
                <Box className="box-default">
                  <Box className="box-default">
                    <Typography variant="subtitle1" color="black">
                      <span>{text}</span>
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                      <span>Tap to open external link</span>
                    </Typography>
                  </Box>
                  <Box
                    className="box-default"
                    sx={{
                      mt: configEntzy.APP_SPACING_MD2X,
                    }}
                  >
                    <Chip
                      label={
                        <Typography variant="subtitle2" noWrap>
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="image-link"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <FontAwesomeIcon icon={iconFollowLink} fixedWidth />
                            <span>&nbsp;&nbsp;</span>
                            <span className="lower-case">{url}</span>
                          </a>
                        </Typography>
                      }
                      color="primary"
                      variant="contained"
                    />
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const GoogleMapsViewer = (props) => {
    return (
      <Box className="box-default">
        {focusLocation && <FocusLocationViewer />}
        <Box
          className="box-default shadow-default"
          sx={{
            mt: configEntzy.APP_SPACING_SM,
            mb: configEntzy.APP_SPACING_SM,
            height: configEntzy.AVATAR_SIZE_XL,
            borderRadius: configEntzy.BORDER_SIZE_XL,
          }}
          ref={googleMapsRef}
        ></Box>
      </Box>
    );
  };

  const LocationsList = (props) => {
    return (
      <Box className="box-default">
        <Box
          className="box-default"
          hidden={editView}
          sx={{
            p: configEntzy.APP_SPACING_XS,
          }}
        >
          <Box
            className="box-default bg-white shadow-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD,
              pb: configEntzy.APP_SPACING_MD,
              borderRadius: configEntzy.BORDER_SIZE_XL,
            }}
          >
            <Typography variant="subtitle2">
              {props.runnerView ? "RUNNER PLACES" : "RIDER SUGGESTIONS"}
            </Typography>
            <Typography variant="subtitle1" color="primary">
              {props.locations.length
                ? "Tap place to focus map"
                : props.runnerView
                ? "No places yet"
                : "No suggestions"}
            </Typography>
          </Box>
        </Box>
        {props.locations.map(function (location, index) {
          const isLaunchLocation =
            index === 0 && location.Category === "runner";
          return (
            <Box
              key={location.LinkId}
              className={
                "box-default " +
                (location.LinkStatus === "inactive"
                  ? "bg-danger content-disabled"
                  : isLaunchLocation
                  ? "bg-black"
                  : location.Category === "rider"
                  ? "bg-white"
                  : "bg-white") +
                (isLaunchLocation
                  ? " shadow-highlight shadow-dark"
                  : focusLocation.LinkId === location.LinkId
                  ? " shadow-default shadow-basic"
                  : "")
              }
              sx={{
                minHeight: configEntzy.AVATAR_SIZE_LG,
                mt: configEntzy.APP_SPACING_SM,
                p: configEntzy.APP_SPACING_MD,
                borderRadius: configEntzy.BORDER_SIZE_XL,
              }}
              hidden={
                (!editView && !location.LinkStatus === "active") ||
                (editView && editViewer === 1 && isExternalLink(location)) ||
                (editView && editViewer === 2 && !isExternalLink(location))
              }
            >
              {/* --- location info --- */}
              <Box className="box-default">
                <Box className="box-default">
                  <Grid container spacing={0}>
                    <Grid item xs={2} className="text-left">
                      {location.Category === "runner" && (
                        <FontAwesomeIcon
                          icon={
                            isExternalLink(location)
                              ? iconExternalLink
                              : iconGoogleMaps
                          }
                          transform="grow-3 down-6"
                          fixedWidth
                        />
                      )}
                    </Grid>
                    <Grid item xs={8}>
                      <Box
                        className="box-default"
                        sx={{
                          pt: configEntzy.APP_SPACING_MD,
                        }}
                      >
                        {isLaunchLocation ? (
                          <Typography
                            variant="subtitle2"
                            className="glowing-text"
                            noWrap
                          >
                            <span>LAUNCH SITE</span>
                          </Typography>
                        ) : (
                          <Typography variant="subtitle2" noWrap>
                            {location.Category === "runner" ? (
                              <span>Other Launch Site</span>
                            ) : (
                              <span>Suggested Site</span>
                            )}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        className="box-default"
                        sx={{
                          pb: configEntzy.APP_SPACING_MD2X,
                        }}
                      >
                        <Box className="box-inline">
                          <UserAvatar
                            user={{
                              identity: location.UserId,
                            }}
                            size="md"
                            nameOnly={true}
                            nameHideInfo={true}
                            // onClick={() => handlePlaceFocus(location)}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2} className="text-right">
                      {location.Category === "rider" && (
                        <FontAwesomeIcon
                          icon={
                            isExternalLink(location)
                              ? iconExternalLink
                              : iconGoogleMaps
                          }
                          transform="grow-3 down-6"
                          fixedWidth
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
                {/* --- focus tag --- */}
                <Box className="box-default relative zero-height pop-top">
                  <Box
                    className="box-default"
                    sx={{
                      mt: configEntzy.APP_SPACING_NMD,
                    }}
                  >
                    <TextTag
                      size="sm"
                      bgColor={
                        focusLocation.LinkId === location.LinkId
                          ? "primary.dark"
                          : "dusk.main"
                      }
                      color="white"
                      textNoCasing={true}
                      rounded={true}
                      text={
                        focusLocation.LinkId === location.LinkId ? (
                          <span>
                            {location.Category === "runner" && (
                              <FontAwesomeIcon icon={iconPlace} fixedWidth />
                            )}
                            &nbsp;SHOWING&nbsp;
                            {location.Category === "rider" && (
                              <FontAwesomeIcon icon={iconPlace} fixedWidth />
                            )}
                          </span>
                        ) : (
                          <span>&nbsp;Tap&nbsp;to&nbsp;View&nbsp;</span>
                        )
                      }
                    />
                  </Box>
                </Box>
                {/* --- place content --- */}
                <Box
                  className={
                    "box-default action-pointer" +
                    (focusLocation.LinkId === location.LinkId
                      ? " bg-highlight shadow-default"
                      : isLaunchLocation
                      ? " bg-white-t25"
                      : " bg-black-t10")
                  }
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                    borderRadius: configEntzy.BORDER_SIZE_XL,
                  }}
                  onClick={() => handlePlaceFocus(location)}
                >
                  {/* <Divider /> */}
                  <Box className="box-default">
                    {location.ContentModeration ? (
                      <ContentWarningHeaderTag />
                    ) : (
                      <PlaceTicker location={location} />
                    )}
                  </Box>
                  <Box
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_SM,
                    }}
                  >
                    {location.ContentModeration ? (
                      <Typography variant="subtitle2" color="error">
                        <span>Dangerous Link</span>
                      </Typography>
                    ) : (
                      <DescriptionTicker location={location} />
                    )}
                  </Box>
                </Box>
              </Box>
              {/* --- editing buttons --- */}
              {editView && user.connected && (
                <Box
                  className={
                    "box-default rounded" +
                    (isLaunchLocation ? " bg-black" : "")
                  }
                  sx={{
                    mt: configEntzy.APP_SPACING_MD,
                    p: configEntzy.APP_SPACING_SM,
                    border: configEntzy.BORDER_DASH_GSM,
                  }}
                >
                  <Box
                    className="box-default"
                    sx={{
                      // visibility:
                      //   location.LinkStatus === "inactive" ||
                      //   (runnerAction && location.Category === "rider")
                      //     ? "visible"
                      //     : "hidden",
                      pb: location.Accepted ? 0 : configEntzy.APP_SPACING_SM,
                    }}
                    hidden={
                      (location.Category === "runner" &&
                        location.LinkStatus === "active") ||
                      (location.Category === "rider" && !runnerAction) ||
                      location.LinkStatus === "inactive"
                    }
                  >
                    <ActionButton
                      variant="contained"
                      size="small"
                      type="button"
                      color={
                        location.LinkStatus === "inactive"
                          ? "dusk"
                          : location.Accepted
                          ? "highlight"
                          : "success"
                      }
                      text={
                        location.LinkStatus === "inactive"
                          ? "To be Removed"
                          : location.Accepted
                          ? "To be Accepted"
                          : "Accept"
                      }
                      disabled={
                        !user.connected || location.LinkStatus === "inactive"
                      }
                      onClick={() => handleAcceptSuggestion(location)}
                      disableElevation={true}
                      fullWidth={true}
                      endIcon={
                        location.LinkStatus === "active" ? (
                          <FontAwesomeIcon
                            icon={location.Accepted ? iconRestore : iconAccept}
                            style={{
                              fontSize: "inherit",
                            }}
                            fixedWidth
                          />
                        ) : null
                      }
                    />
                  </Box>
                  <Box
                    className="box-default"
                    sx={{
                      // visibility:
                      //   location.Category === "runner" &&
                      //   location.LinkId !== locations[0].LinkId
                      //     ? "visible"
                      //     : "hidden",
                      pb: configEntzy.APP_SPACING_SM,
                    }}
                    hidden={
                      isLaunchLocation ||
                      location.Category === "rider" ||
                      location.LinkStatus === "inactive"
                    }
                  >
                    <ActionButton
                      variant="contained"
                      size="small"
                      type="button"
                      color={
                        location.LinkStatus === "inactive"
                          ? "bright"
                          : "success"
                      }
                      text={"Make Launch Site"}
                      disabled={
                        !user.connected || location.LinkStatus === "inactive"
                      }
                      onClick={() => handlePlaceLaunch(location)}
                      disableElevation={true}
                      fullWidth={true}
                      // endIcon={
                      //   location.LinkStatus === "active" ? (
                      //     <FontAwesomeIcon
                      //       icon={iconFocus}
                      //       style={{
                      //         fontSize: "inherit",
                      //       }}
                      //       fixedWidth
                      //     />
                      //   ) : null
                      // }
                    />
                  </Box>
                  <Box className="box-default" hidden={location.Accepted}>
                    <ActionButton
                      variant="contained"
                      size="small"
                      type="button"
                      color={
                        location.Accepted
                          ? "clear"
                          : location.LinkStatus === "active"
                          ? "danger"
                          : "highlight"
                      }
                      text={
                        location.Accepted
                          ? "To be Accepted"
                          : location.LinkStatus === "active"
                          ? "Remove"
                          : "To be Removed"
                      }
                      disabled={!user.connected || location.Accepted}
                      onClick={() => handlePlaceActive(location)}
                      disableElevation={true}
                      fullWidth={true}
                      // startIcon={
                      //   location.Category === "runner" ? (
                      //     <FontAwesomeIcon
                      //       icon={
                      //         location.LinkStatus === "active"
                      //           ? iconRemove
                      //           : iconRestore
                      //       }
                      //       style={{
                      //         fontSize: "inherit",
                      //       }}
                      //       fixedWidth
                      //     />
                      //   ) : null
                      // }
                      endIcon={
                        <FontAwesomeIcon
                          icon={
                            location.LinkStatus === "active"
                              ? iconRemove
                              : iconRestore
                          }
                          style={{
                            fontSize: "inherit",
                          }}
                          fixedWidth
                        />
                        // location.Category === "rider" && !location.Accepted ? (
                        //       <FontAwesomeIcon
                        //         icon={
                        //           location.LinkStatus === "active"
                        //             ? iconRemove
                        //             : iconRestore
                        //         }
                        //         style={{
                        //           fontSize: "inherit",
                        //         }}
                        //         fixedWidth
                        //       />
                        //     ) : null
                      }
                    />
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    );
  };

  const LocationsViewer = (props) => {
    return (
      <Grid
        container
        spacing={0}
        sx={{
          mt: editView
            ? configEntzy.APP_SPACING_MD2X
            : configEntzy.APP_SPACING_SM,
        }}
      >
        <Grid item xs={6}>
          <Box
            className="box-default"
            sx={{
              pr: configEntzy.APP_SPACING_XS,
            }}
          >
            <LocationsList
              locations={locations.filter((obj) => obj.Category === "runner")}
              runnerView={true}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            className="box-default"
            sx={{
              pl: configEntzy.APP_SPACING_XS,
            }}
          >
            <LocationsList
              locations={locations.filter((obj) => obj.Category === "rider")}
              riderView={true}
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  // google maps initiate
  const initGoogleMaps = useCallback(() => {
    const googleMapsLocations = locations.filter(
      (place) => place.LinkStatus === "active" && !isExternalLink(place)
    );
    if (googleMapsRef.current) {
      // load map if on page
      const editmap = new window.google.maps.Map(googleMapsRef.current, {
        zoom: 12,
        mapTypeControl: false,
        mapId: "PLACE_MAP_ID",
      });
      googleMapsLocations.forEach((place) => {
        // create marker icon
        const glyphImg = document.createElement("img");
        glyphImg.src = "/safari-pinned-tab.svg";
        glyphImg.style.width = "24px";
        glyphImg.style.height = "24px";
        const faPin = new window.google.maps.marker.PinElement({
          glyph: glyphImg,
          glyphColor: "#000000",
          background: place.Category === "runner" ? "#ff9900" : "#999999",
          borderColor: "#000000",
          scale: 1.5,
        });
        // create marker element
        const marker = new window.google.maps.marker.AdvancedMarkerElement({
          map: editmap,
          position: {
            lat: Number(place.LinkTarget.split(",")[0]),
            lng: Number(place.LinkTarget.split(",")[1]),
          },
          title: place.Title,
          content: faPin.element,
          // icon: MapIcon,
        });
        marker.content.classList.add("marker-drop");
        // marker.setAnimation(window.google.maps.Animation.DROP);
        marker.addListener("gmp-click", () => {
          setFocusLocation(place);
          setFocusViewer(true);
        });
      });
      // set map center by focussed item or by country if no locations
      if (focusLocation.LinkId && !isExternalLink(focusLocation)) {
        editmap.setCenter({
          lat: Number(focusLocation.LinkTarget.split(",")[0]),
          lng: Number(focusLocation.LinkTarget.split(",")[1]),
        });
      } else if (googleMapsLocations.length) {
        editmap.setCenter({
          lat: Number(googleMapsLocations[0].LinkTarget.split(",")[0]),
          lng: Number(googleMapsLocations[0].LinkTarget.split(",")[1]),
        });
      } else {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          { address: countriesGetItem(country).name },
          (results) => {
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();
            editmap.setCenter({ lat, lng });
          }
        );
      }
      if (inputGoogleMapsRef.current) {
        // load autocomplete if on page
        const autocomplete = new window.google.maps.places.Autocomplete(
          inputGoogleMapsRef.current,
          {}
        );
        const handleGooglePlaceSelect = (autocomplete, editmap) => {
          const place = autocomplete.getPlace();
          let text;
          text = inputGoogleMapsTextRef.current.value;
          if (text.replace(/\s/g, "").length === 0) text = "No description";
          if (place.geometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            const title = place.formatted_address;
            const description = text;
            const id = "new-" + idGenerator();
            const location = {
              LinkId: id,
              LinkStatus: "active",
              LinkTarget: lat + "," + lng,
              Title: title,
              Description: description,
              Category: props.manager ? "runner" : "rider",
              UserId: props.user.identity,
            };
            setFocusLocation(location);
            setFocusViewer(true);
            setAddedLocation(location);
            setLocations([location].concat(locations));
            setChanges(true);
            inputGoogleMapsRef.current.value = "";
          }
        };
        autocomplete.setFields(["formatted_address", "geometry"]);
        autocomplete.addListener("place_changed", () =>
          handleGooglePlaceSelect(autocomplete, editmap)
        );
        if (country === "ww") {
          autocomplete.setComponentRestrictions({ country: [] });
        } else {
          autocomplete.setComponentRestrictions({ country: country });
          // map.setCenter(countries[country].center);
          // map.setZoom(countries[country].zoom);
        }
      }
    }
  }, [props.manager, props.user.identity, country, locations, focusLocation]);

  useEffect(() => {
    if (window.google && window.google.maps) {
      initGoogleMaps();
    }
  });

  return (
    <Box className="box-default">
      {editView ? (
        <Box className="box-default">
          {user.connected ? (
            <Box className="box-default">
              <Box className="box-default">
                <EditPlaceInput {...props} />
              </Box>
              {editViewer > 0 && (
                <Box className="box-default">
                  <LocationsViewer />
                </Box>
              )}
            </Box>
          ) : (
            <Box className="box-default">
              <Box
                className="box-default"
                sx={{
                  mb: configEntzy.APP_SPACING_MD,
                }}
              >
                <ActionAlert
                  severity="info"
                  title="Connect to continue"
                  message="Tap here to activate locations"
                  onClick={props.toggleConnect}
                />
              </Box>
              <EmptyViewer />
            </Box>
          )}
        </Box>
      ) : (
        <Box className="box-default">
          {locations.length > 0 ? (
            <Box className="box-default">
              <Box className="box-default">
                {isExternalLink(focusLocation) ? (
                  <ExternalLinkViewer
                    url={getExternalLink(focusLocation)}
                    text={focusLocation.Description}
                    moderation={focusLocation.ContentModeration}
                  />
                ) : (
                  <Box className="box-default">
                    <GoogleMapsViewer {...props} />
                  </Box>
                )}
              </Box>
              <Box className="box-default" hidden={mapViewOnly}>
                <LocationsViewer />
              </Box>
            </Box>
          ) : (
            <Box className="box-default">
              <FallbackMessage
                fallback={
                  user.connected
                    ? "No places yet"
                    : "Connect to access launch locations"
                }
                split={true}
                room={true}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default GooglePlace;

// MOVED TO EVENT RUNNER
// if (!window.google) {
//   const script = document.createElement("script");
//   script.type = "text/javascript";
//   script.src =
//     "https://maps.googleapis.com/maps/api/js?libraries=places&key=" +
//     configEntzy.APP_MAPS_PUBLIC_KEY +
//     "&callback=Function.prototype";
//   const headScript = document.getElementsByTagName("script")[0];
//   headScript.parentNode.insertBefore(script, headScript);
//   script.addEventListener("load", onLoad);
//   return () => {
//     script.removeEventListener("load", onLoad);
//   };
// } else {
//   onLoad();
// }
// }, [locations, country, props.manager, props.user.identity]);

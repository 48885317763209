import React, { useContext, useState, useEffect, useCallback } from "react";
import {
  Avatar,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Grow,
} from "@mui/material";
import { useSprings, animated } from "@react-spring/web";
import { useSwipeable } from "react-swipeable";

// entzy config
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { serviceGraphCall } from "services/graphql/call";
import { useMediaImage } from "hooks/media/mediaImage";

// entzy components
import { ActionLoader } from "components/utils/common/CommonLoaders";
import { ActionBoxButton } from "components/utils/common/CommonButtons";
import { HeaderTag, TagLine } from "components/utils/common/CommonTags";

// entzy assets
import DefaultBanner from "assets/banners/crowd-t50.png";
import DefaultAvatar from "assets/logos/entzy-arty.png";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle as iconInfo } from "@fortawesome/pro-thin-svg-icons";
import { faChevronCircleLeft as iconSelectLeft } from "@fortawesome/pro-duotone-svg-icons";
import { faChevronCircleRight as iconSelectRight } from "@fortawesome/pro-duotone-svg-icons";
// import { faLightbulb as iconSpotlight } from "@fortawesome/pro-solid-svg-icons";
import { faTimes as iconClose } from "@fortawesome/pro-thin-svg-icons";
// import {
//   faChevronLeft as iconLeft,
//   faChevronRight as iconRight,
// } from "@fortawesome/pro-duotone-svg-icons";

// spotlight cards configuration
const fullCardList = configEntzy.SPOTLIGHT_CARDS;

const LIST_EXAMPLE_POPUP_EVENTS = [
  "Secret Suppers",
  "Brunches",
  "Dinner Parties",
  "Wine Tastings",
  "Cocktail Parties",
  "Hidden Speakeasies",
  "Movie Nights",
  "Live Music",
  "Outdoor Adventures",
  "Game Nights",
  "Book Clubs",
  "Fitness Groups",
  "Art Classes",
  "Cooking Classes",
  "Tech Talks",
  "Networking Events",
  "Workshops",
  "Conferences",
  "Hackathons",
  "Pop-up Shops",
  "Charity Events",
  "Fundraisers",
  "Product Launches",
  "Fashion Shows",
  "Art Exhibitions",
  "Theatre Shows",
  "Comedy Nights",
  "Warehouse Parties",
  "Rooftop Parties",
  "Pool Parties",
  "Beach Parties",
  "Boat Parties",
];

function HomeSpotlight(props) {
  const user = props.user;
  const mainContext = useContext(MainContext);

  const [loading, setLoading] = useState(false);
  const [hydrated, setHydrated] = useState(false);
  const [eventuators, setEventuators] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [manualOverride, setManualOverride] = useState(false);
  const [showList, setShowList] = useState(false);

  const handleList = () => {
    setLoading(true);
    setManualOverride(!showList);
    setTimeout(() => {
      setShowList(!showList);
      setLoading(false);
    }, 200);
  };

  const handlePrev = (manual) => {
    if (manual) {
      setManualOverride(true);
    }
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? fullCardList.length - 1 : prevIndex - 1
    );
  };

  const handleNext = (manual) => {
    if (manual) {
      setManualOverride(true);
    }
    setCurrentIndex((prevIndex) =>
      prevIndex === fullCardList.length - 1 ? 0 : prevIndex + 1
    );
  };

  const [springs, api] = useSprings(fullCardList.length, (index) => ({
    transform: `translateX(${(index - currentIndex) * 100}%)`,
    opacity: index === currentIndex ? 1 : 0.5,
    config: { tension: 200, friction: 30 },
  }));

  useEffect(() => {
    const interval = setInterval(() => {
      if (!manualOverride) {
        handleNext();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex, manualOverride]);

  useEffect(() => {
    api.start((index) => ({
      transform: `translateX(${(index - currentIndex) * 100}%)`,
      opacity: index === currentIndex ? 1 : 0.5,
    }));
  }, [currentIndex, api]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNext(true),
    onSwipedRight: () => handlePrev(true),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // fetchs spotlight eventuators
  useEffect(() => {
    const fetchEventuators = async () => {
      setLoading(true);
      const promises = fullCardList.map((card) =>
        serviceGraphCall("query", "publicViewEvent", {
          Url: card.url,
        })
      );
      try {
        const responses = await Promise.all(promises);
        const updatedEventuators = responses.map((response, index) => {
          return {
            id: fullCardList[index].id,
            url: fullCardList[index].url,
            title: fullCardList[index].title,
            data: response.success ? response.data : null,
            owner: false,
            manager: false,
            success: response.success,
            response: response,
          };
        });
        setEventuators(updatedEventuators);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
      setHydrated(true);
    };

    if (!hydrated && !loading) {
      fetchEventuators();
    }
  }, [loading, hydrated, eventuators]);

  const cbHandleNext = useCallback(handleNext, []);

  useEffect(() => {
    const interval = setInterval(() => {
      cbHandleNext();
    }, 3000);
    if (manualOverride) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [manualOverride, cbHandleNext]);

  // nested components
  const EventuatorCard = ({ eventuator }) => {
    const eventLogo = useMediaImage(
      eventuator.data?.Logo
        ? {
            user: user,
            context: mainContext,
            image: {
              id: "event:logo:" + eventuator.data.EventId,
              key: eventuator.data.Logo,
            },
          }
        : null
    );
    const openerUrl = `${configEntzy.APP_URL}${configEntzy.URL_POINTERS.MAIN}${eventuator.url}`;

    return (
      <CardActionArea
        className={
          currentIndex + 1 === eventuator.id && !showList ? "border-glow" : ""
        }
        onClick={() => {
          window.open(openerUrl, "_blank", "noopener,noreferrer");
        }}
        sx={{
          borderRadius: configEntzy.BORDER_SIZE_XL,
        }}
      >
        <Card
          sx={{
            width: configEntzy.AVATAR_CONTAINER_LG,
            height: configEntzy.AVATAR_CONTAINER_XL,
            borderRadius: configEntzy.BORDER_SIZE_XL,
          }}
        >
          <CardMedia
            component="img"
            alt={eventuator.title}
            width="100%"
            height="200px"
            image={
              eventLogo.hydrated && eventLogo.images.lg
                ? eventLogo.images.lg.data
                : DefaultBanner
            }
            sx={{
              height: configEntzy.AVATAR_SIZE_MD,
            }}
          />
          <CardContent>
            <Box className="box-default">
              <Typography variant="h5">{eventuator.title}</Typography>
              <Typography variant="h6" color="primary">
                {configEntzy.URL_POINTERS.MAIN}
                {eventuator.url}
              </Typography>
            </Box>
            {eventuator.success ? (
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                }}
              >
                <Box className="box-default">
                  <Box className="box-inline nooverflow">
                    {/* <UserAvatar
                      user={{ identity: eventuator.data?.UserId }}
                      imageOverride={DefaultAvatar}
                      size="md"
                      nameHideInfo
                      nameHideGuestInfo
                    /> */}

                    <Avatar
                      variant="rounded"
                      className="bg-black-t50 shadow-default rounded"
                      src={DefaultAvatar}
                      alt="Avatar"
                      sx={{
                        width: configEntzy.AVATAR_SIZE_MD,
                        height: configEntzy.AVATAR_SIZE_MD,
                        backgroundColor: "transparent",
                      }}
                    />
                  </Box>
                  <Box
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <Typography variant="subtitle2" color="secondary">
                      @entzyevents
                    </Typography>
                  </Box>
                </Box>
                <Box className="box-default">
                  <Typography variant="subtitle1" color="tertiary">
                    Offering to make this happen
                  </Typography>
                  <Typography variant="subtitle1" color="tertiary">
                    {eventuator.id} of {fullCardList.length}
                  </Typography>
                </Box>
                {/* <Box
                  className="box-default text-fade"
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                    height: configEntzy.AVATAR_CONTAINER_MD,
                    borderTop: "1px solid #444",
                  }}
                >
                  <WidgetRichText content={eventuator.data.Details} />
                </Box> */}
              </Box>
            ) : (
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_LG,
                }}
              >
                <Typography variant="h6" color="error">
                  <FontAwesomeIcon icon={iconInfo} size="2x" fixedWidth />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="error"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD,
                  }}
                >
                  Try again later
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="tertiary"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD,
                  }}
                >
                  {eventuator.id} of {fullCardList.length}
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </CardActionArea>
    );
  };

  return (
    <Box
      className="box-default"
      // sx={{
      //   mt: configEntzy.APP_SPACING_LG,
      // }}
    >
      {loading && (
        <Box className="box-default">
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_LG,
              height: configEntzy.AVATAR_CONTAINER_XL2X,
            }}
          >
            <ActionLoader />
          </Box>
        </Box>
      )}
      <Grow in={!loading} timeout={2000}>
        <Box className="box-default">
          {hydrated && eventuators.length > 0 && (
            <Box className="box-default">
              <Box
                className="box-default relative nooverflow"
                sx={{
                  mt: configEntzy.APP_SPACING_NLG,
                  pt: configEntzy.APP_SPACING_XL,
                  pb: configEntzy.APP_SPACING_XL,
                  borderRadius: configEntzy.BORDER_SIZE_XL,
                }}
                hidden={showList}
              >
                <Box
                  {...swipeHandlers}
                  className="box-default relative"
                  sx={{
                    height: configEntzy.AVATAR_CONTAINER_XL2X,
                    mt: configEntzy.APP_SPACING_LG,
                    mb: configEntzy.APP_SPACING_LG,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {springs.map((springStyle, index) => (
                    <animated.div
                      key={index}
                      style={{
                        ...springStyle,
                        position: "absolute",
                      }}
                    >
                      <Box
                        className="box-inline"
                        sx={{ p: configEntzy.APP_SPACING_SM }}
                      >
                        <EventuatorCard eventuator={eventuators[index]} />
                      </Box>
                    </animated.div>
                  ))}
                </Box>
              </Box>

              <Box
                className="box-default"
                sx={{
                  mt: showList
                    ? configEntzy.APP_SPACING_MD
                    : configEntzy.APP_SPACING_NHL,
                }}
              >
                <Box
                  className="box-inline pop-up"
                  sx={{
                    p: configEntzy.APP_SPACING_MD2X,
                  }}
                >
                  {/* <Box
                    className="box-default"
                    sx={{
                      p: configEntzy.APP_SPACING_MD,
                    }}
                    hidden={!showList}
                  >
                    <Typography variant="h6" color="tertiary">
                      <FontAwesomeIcon
                        icon={iconSpotlight}
                        size="4x"
                        fixedWidth
                        color="purple"
                      />
                    </Typography>
                  </Box> */}

                  <Box
                    className="box-default"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {!showList && (
                      <FontAwesomeIcon
                        icon={iconSelectLeft}
                        size="4x"
                        color="orange"
                        className="action-pointer pop-up"
                        onClick={() => {
                          handlePrev(true);
                        }}
                        fixedWidth
                        style={{ marginRight: "1rem" }}
                      />
                    )}

                    <ActionBoxButton
                      text="Spotlight Ideas: Zürich"
                      subText={
                        showList
                          ? "25 Ideas in 2025 to bring us together"
                          : "Tap to see all " +
                            fullCardList.length +
                            " Eventuators"
                      }
                      bgColor="black"
                      color="white"
                      borderColor="purple"
                      onClick={handleList}
                      endIcon={
                        showList ? (
                          <FontAwesomeIcon
                            icon={iconClose}
                            size="2x"
                            color="white"
                            fixedWidth
                          />
                        ) : undefined
                      }
                    />

                    {!showList && (
                      <FontAwesomeIcon
                        icon={iconSelectRight}
                        size="4x"
                        color="orange"
                        className="action-pointer pop-up"
                        onClick={() => {
                          handleNext(true);
                        }}
                        fixedWidth
                        style={{ marginLeft: "1rem" }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>

              <Box
                className="box-default"
                // sx={{
                //   mt: configEntzy.APP_SPACING_LG,
                // }}
              >
                <Box
                  className="box-default"
                  sx={{
                    pt: configEntzy.APP_SPACING_MD,
                  }}
                  hidden={!showList}
                >
                  {eventuators.map((eventuator) => (
                    <Box
                      key={eventuator.id}
                      className="box-inline"
                      sx={{ p: configEntzy.APP_SPACING_SM }}
                    >
                      <EventuatorCard
                        key={eventuator.id}
                        eventuator={eventuator}
                      />
                    </Box>
                  ))}
                  <Box
                    className="box-default"
                    sx={{
                      mt: configEntzy.APP_SPACING_LG,
                    }}
                  >
                    <TagLine text="Other things to do for insipiration" />
                  </Box>
                  <Box
                    className="box-default"
                    sx={{
                      mt: configEntzy.APP_SPACING_LG,
                    }}
                  >
                    {LIST_EXAMPLE_POPUP_EVENTS.map((item, index) => (
                      <Box
                        key={index}
                        className="box-inline bg-black-t75"
                        sx={{
                          pt: configEntzy.APP_SPACING_SM,
                          pb: configEntzy.APP_SPACING_SM,
                          pl: configEntzy.APP_SPACING_MD,
                          pr: configEntzy.APP_SPACING_MD,
                          m: configEntzy.APP_SPACING_SM,
                          borderRadius: configEntzy.BORDER_SIZE_XL,
                          border: "1px solid #333",
                          color: "white",
                        }}
                      >
                        {item}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_HL,
                  mb: configEntzy.APP_SPACING_XL,
                }}
              >
                <HeaderTag
                  text="Ideate to Eventuate"
                  bgColor="primary.main"
                  color="black"
                  // noSpacing={true}
                />
                <TagLine text="Propose ideas for the calendar" />
                <TagLine text="That trigger only when everyone matches" />
                <TagLine
                  text={
                    user && user.connected
                      ? "See below how to run ideas where you are"
                      : "Find out below how to run ideas where you are"
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
      </Grow>
    </Box>
  );
}

export default HomeSpotlight;

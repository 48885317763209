// react core
import { createContext } from "react";

// date handling
import dayjs from "dayjs";

// entzy models
import { EventPinnedRunnerStates, EventPinnedRiderStates } from "models/Event";

// event state changing actions
export const mainActions = {
  SELECT_MENU: "SELECT_MENU",
  PULL_NOTIFICATIONS: "PULL_NOTIFICATIONS",
  NEW_NOTIFICATION: "NEW_NOTIFICATION",
  MARK_READ_NOTIFICATION: "MARK_READ_NOTIFICATION",
  REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
  PULL_MEMBER_MESSAGES: "PULL_MEMBER_MESSAGES",
  PULL_MEMBER_CHAT_SETTINGS: "PULL_MEMBER_CHAT_SETTINGS",
  UPDATE_MEMBER_CHAT_SETTINGS: "UPDATE_MEMBER_CHAT_SETTINGS",
  POST_MEMBER_MESSAGE: "POST_MEMBER_MESSAGE",
  REMOVE_MEMBER_MESSAGE: "REMOVE_MEMBER_MESSAGE",
  SET_MEMBER_CONTACT_VIEWER: "SET_MEMBER_CONTACT_VIEWER",
  SET_MEMBER_MESSAGE_VIEWER: "SET_MEMBER_MESSAGE_VIEWER",
  SET_MEMBER_TRANSACTION_VIEWER: "SET_MEMBER_TRANSACTION_VIEWER",
  SET_PAY_DIRECT_VIEWER: "SET_PAY_DIRECT_VIEWER",
  SEND_PAYMENT: "SEND_PAYMENT",
  PULL_PRODUCT_LIST: "PULL_PRODUCT_LIST",
  CREATE_PRODUCT: "CREATE_PRODUCT",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  PULL_CONTACT_LIST: "PULL_CONTACT_LIST",
  CREATE_CONTACT: "CREATE_CONTACT",
  DELETE_CONTACT: "DELETE_CONTACT",
  INTERACT_WITH_USER: "INTERACT_WITH_USER",
  INTERACT_WITH_IMAGE: "INTERACT_WITH_IMAGE",
  ADD_EXTERNAL_CALENDAR: "ADD_EXTERNAL_CALENDAR",
  GET_EXTERNAL_CALENDAR_ID: "GET_EXTERNAL_CALENDAR_ID",
  PULL_EXTERNAL_CALENDAR: "PULL_EXTERNAL_CALENDAR",
  PULL_VIEWER_CALENDAR: "PULL_VIEWER_CALENDAR",
  PULL_VIEWER_EVENTS: "PULL_VIEWER_EVENTS",
  RESET_VIEWER_CALENDAR: "RESET_VIEWER_CALENDAR",
  SET_EXTERNAL_CALENDARS: "SET_EXTERNAL_CALENDARS",
  SET_EXTERNAL_CALENDAR_META: "SET_EXTERNAL_CALENDAR_META",
  UPDATE_EXTERNAL_CALENDAR: "UPDATE_EXTERNAL_CALENDAR",
  UPDATE_CALENDAR_MONTH_DATA: "UPDATE_CALENDAR_MONTH_DATA",
  ADD_EVENT: "ADD_EVENT",
  ARCHIVE_EVENT: "ARCHIVE_EVENT",
  REMOVE_EVENT: "REMOVE_EVENT",
  UPDATE_VIEWER_TRAIL: "UPDATE_VIEWER_TRAIL",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_ALERT: "UPDATE_ALERT",
  SET_TOKEN: "SET_TOKEN",
  SET_OBSERVABLES_TRACKER: "SET_OBSERVABLES_TRACKER",
  SET_CALLBACK_LOADER: "SET_CALLBACK_LOADER",
  SET_ACTION_DRAWER: "SET_ACTION_DRAWER",
};

const newObservables = function (props) {
  return {
    list: ["notifications"],
    notifications: {
      active: false,
      field: "onNewNotification",
      subscription: null,
      menus: [],
      action: {
        type: props.mainActions.NEW_NOTIFICATION,
        property: "data",
      },
    },
    memberMessaging: {
      active: false,
      manualActivation: true,
      field: "onPostChatDirectContent",
      subscription: null,
      menus: ["memberMessaging"],
      action: {
        type: props.mainActions.POST_MEMBER_MESSAGE,
        property: "message",
      },
    },
  };
};

const newNotifications = function () {
  return {
    hydrated: false,
    data: {
      combined: [],
      activity: [],
      messages: [],
      combinedCount: 0,
      activityCount: 0,
      messagesCount: 0,
      nextToken: null,
    },
  };
};

const newViewerOpener = function () {
  return {
    viewer: {
      open: false,
    },
  };
};
const newListSet = function () {
  return {
    hydrated: false,
    listId: "",
    data: {
      items: [],
      itemsCount: 0,
    },
  };
};

const newInteraction = function () {
  return {
    users: [],
    images: [],
  };
};

const newViewer = function () {
  return {
    calendar: {
      hydrated: false,
      externalHydrated: false,
      external: [
        {
          name: "google",
          connected: false,
        },
      ],
      externalMeta: {},
      viewDate: dayjs().format("YYYY-MM-DD"),
    },
    events: {
      hydrated: false,
      data: [],
      runners: [],
      riders: [],
      active: [],
      archived: [],
    },
    trail: {
      joinTab: 1,
      joinSubTab: 0,
      memberTab: 0,
      launchpadTab: 0,
      sourcePage: null,
      controlPage: null,
      calendarView: true,
      calendarCompactView: true,
      memberInvites: false,
      editingEventFeed: false,
      editingMessageFeed: false,
      editingActivityFeed: false,
      showArchivedEvents: false,
    },
    tokens: {},
  };
};

const newObservablesTracker = function () {
  return { active: false };
};

const newDrawer = function () {
  return {
    open: false,
    params: {},
  };
};

const newAlert = function () {
  return { show: false, message: null };
};

// const newMainCalendar = function () {
//   return {
//     calendars: ["entzy", "google"],
//     entzy: {
//       active: true,
//       id: "main",
//       events: [],
//     },
//     google: {
//       active: false,
//       id: null,
//       events: [],
//     },
//   };
// };

export const initialState = {
  notifications: newNotifications(),
  memberContact: newViewerOpener(),
  memberMessaging: newViewerOpener(),
  memberTransactions: newViewerOpener(),
  payDirect: newViewerOpener(),
  productList: newListSet(),
  contactList: newListSet(),
  interaction: newInteraction(),
  viewer: newViewer(),
  alert: newAlert(),
  observablesTracker: newObservablesTracker(),
  actionDrawer: newDrawer(),
  callbackLoader: false,
  callbackState: null,
};

export const mainObservables = newObservables({ mainActions });

export const mainReducer = (state, action) => {
  // state capture for ephemeral actions
  let result = {};
  // id for any context change
  state.id = Date.now();

  switch (action.type) {
    // select menu
    case mainActions.SELECT_MENU:
      return {
        ...state,
        menuSelected: action.menuId,
      };
    // pull and update notifications
    case mainActions.PULL_NOTIFICATIONS:
      result.notificationData = generateNotifications({
        pullData: action.notifications.data,
        pullMore: action.notifications.more,
        currentState: state.notifications,
      });
      return {
        ...state,
        notifications: {
          hydrated: true,
          expires: dayjs().add(10, "minutes"),
          data: result.notificationData,
        },
      };
    // update after new notifications
    case mainActions.NEW_NOTIFICATION:
      result.notificationData = generateNotifications({
        currentState: state.notifications,
        newItem: action.data,
      });
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: result.notificationData,
        },
      };
    // update after marking notification as read
    case mainActions.MARK_READ_NOTIFICATION:
      result.notificationData = generateNotifications({
        currentState: state.notifications,
        newItem: action.notification.data,
      });
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: result.notificationData,
        },
      };
    // permanently remove notification before expiry
    case mainActions.REMOVE_NOTIFICATION:
      action.notification.data.removed = true;
      result.notificationData = generateNotifications({
        currentState: state.notifications,
        newItem: action.notification.data,
      });
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: result.notificationData,
        },
      };
    // new user interactions add to list
    case mainActions.INTERACT_WITH_USER:
      return {
        ...state,
        interaction: {
          ...state.interaction,
          users: [
            ...state.interaction.users.filter(
              (obj) => obj.identity !== action.user.data.identity
            ),
            action.user.data,
          ],
        },
      };
    case mainActions.INTERACT_WITH_IMAGE:
      return {
        ...state,
        interaction: {
          ...state.interaction,
          images: [
            ...state.interaction.images.filter(
              (obj) => obj.id !== action.image.data.id
            ),
            action.image.data,
          ],
        },
      };
    // add external calendar
    case mainActions.ADD_EXTERNAL_CALENDAR:
      return {
        ...state,
        // mainCalendar: {
        //   ...state.mainCalendar,
        //   [action.calendar.name]: {
        //     active: action.calendar.active,
        //     id: state.mainCalendar[action.calendar.name].id,
        //     events: state.mainCalendar[action.calendar.name].events,
        //   },
        // },
      };
    // get external calendar id
    case mainActions.GET_EXTERNAL_CALENDAR_ID:
      return {
        ...state,
        // mainCalendar: {
        //   ...state.mainCalendar,
        //   [action.calendar.name]: {
        //     active: state.mainCalendar[action.calendar.name].active,
        //     id: action.calendar.id,
        //     events: state.mainCalendar[action.calendar.name].events,
        //   },
        // },
      };
    // pull and refresh external calendar events
    case mainActions.PULL_EXTERNAL_CALENDAR:
      return {
        ...state,
        // mainCalendar: {
        //   ...state.mainCalendar,
        //   [action.calendar.name]: {
        //     active: state.mainCalendar[action.calendar.name].active,
        //     id: state.mainCalendar[action.calendar.name].id,
        //     events: action.calendar.events,
        //   },
        // },
      };
    // read and refresh messages
    case mainActions.PULL_MEMBER_MESSAGES:
      return {
        ...state,
        memberMessaging: {
          ...state.memberMessaging,
          [action.content.roomId]: {
            // ...state.memberMessaging[action.content.roomId],
            hydrated: true,
            updated: dayjs(),
            data: action.content.data,
          },
          lastActiveRoomId: action.content.roomId,
        },
      };
    // read and manage notifications
    case mainActions.PULL_MEMBER_CHAT_SETTINGS:
      return {
        ...state,
        memberMessaging: {
          ...state.memberMessaging,
          [action.settings.roomId]: {
            ...state.memberMessaging[action.settings.roomId],
            settingsHydrated: true,
            settingsData: action.settings.data,
          },
        },
      };
    case mainActions.UPDATE_MEMBER_CHAT_SETTINGS:
      return {
        ...state,
        memberMessaging: {
          ...state.memberMessaging,
          [action.settings.roomId]: {
            ...state.memberMessaging[action.settings.roomId],
            settingsData: {
              ...state.memberMessaging[action.settings.roomId].settingsData,
              ...action.settings.data,
            },
          },
        },
      };
    // post and add new message to stack
    case mainActions.POST_MEMBER_MESSAGE:
      result.updatedCombined = state.memberMessaging[action.message.RoomId]
        ? [
            action.message,
            ...state.memberMessaging[
              action.message.RoomId
            ].data.combined.filter(
              (obj) => obj.PostId !== action.message.PostId
            ),
          ]
        : [action.message];
      // loop through messages to set category based on user view
      result.updatedCombined.forEach((item, index, data) => {
        data[index].Category =
          data[index].UserId === action.message.UserId ? "runner" : "rider";
      });
      // loop through messages and flag if previous message is from same user
      result.updatedCombined.forEach((item, index, data) => {
        if (index === 0) {
          data[index].PriorUserId = result.updatedCombined[0].UserId;
          data[index].PriorCategory = result.updatedCombined[0].Category;
          data[index].PriorPostDeleted = result.updatedCombined[0].PostDeleted;
        } else {
          data[index].PriorUserId = result.updatedCombined[index - 1].UserId;
          data[index].PriorCategory =
            result.updatedCombined[index - 1].Category;
          data[index].PriorPostDeleted = data[index].PriorCategory =
            result.updatedCombined[index - 1].PostDeleted;
          if (item.UserId !== result.updatedCombined[index - 1].UserId) {
            data[index].PriorUserChanged = true;
          }
        }
        if (index === data.length - 1) {
          data[index].PriorUserEnd = true;
        }
      });
      // return full updated messages
      result.updatedMessages = {
        combined: result.updatedCombined,
        runners: result.updatedCombined.filter(
          (obj) => obj.Category === "runner"
        ),
        riders: result.updatedCombined.filter(
          (obj) => obj.Category === "rider"
        ),
      };
      return {
        ...state,
        memberMessaging: {
          ...state.memberMessaging,
          [action.message.RoomId]: {
            ...state.memberMessaging[action.message.RoomId],
            updated: dayjs(),
            data: result.updatedMessages,
          },
        },
      };
    // remove message set deleted flag to true on message
    case mainActions.REMOVE_MEMBER_MESSAGE:
      result.updatedCombined = state.memberMessaging[
        action.message.roomId
      ].data.combined.map((item) => {
        if (item.PostId === action.message.data.PostId) {
          item.PostDeleted = true;
        }
        return item;
      });
      // return full updated messages
      result.updatedMessages = {
        combined: result.updatedCombined,
        runners: result.updatedCombined.filter(
          (obj) => obj.Category === "runner"
        ),
        riders: result.updatedCombined.filter(
          (obj) => obj.Category === "rider"
        ),
      };
      return {
        ...state,
        memberMessaging: {
          ...state.memberMessaging,
          [action.message.roomId]: {
            ...state.memberMessaging[action.message.roomId],
            updated: dayjs(),
            data: result.updatedMessages,
          },
        },
      };
    // set member contact viewer
    case mainActions.SET_MEMBER_CONTACT_VIEWER:
      return {
        ...state,
        memberContact: {
          ...state.memberContact,
          viewer: action.viewer,
        },
      };
    // set member message viewer
    case mainActions.SET_MEMBER_MESSAGE_VIEWER:
      return {
        ...state,
        memberMessaging: {
          ...state.memberMessaging,
          viewer: action.viewer,
        },
      };
    // set pay direct viewer
    case mainActions.SET_PAY_DIRECT_VIEWER:
      return {
        ...state,
        payDirect: {
          ...state.payDirect,
          viewer: action.viewer,
        },
      };
    // set transaction viewer
    case mainActions.SET_MEMBER_TRANSACTION_VIEWER:
      return {
        ...state,
        memberTransactions: {
          ...state.memberTransactions,
          viewer: action.viewer,
        },
      };
    // send direct payments
    case mainActions.SEND_PAYMENT:
      return {
        ...state,
        payDirect: {
          ...state.payDirect,
        },
      };
    // read and refresh product list
    case mainActions.PULL_PRODUCT_LIST:
      result.updatedProductData = action.products.data
        ? action.products.data
        : newListSet().data;
      result.updatedProductItems = action.products.more
        ? state.productList.data.items.concat(result.updatedProductData.items)
        : result.updatedProductData.items;
      result.updatedProductData.items = result.updatedProductItems;
      result.updatedProductData.itemsCount = result.updatedProductItems.length;
      return {
        ...state,
        productList: {
          hydrated: true,
          listId: action.products.data.listId,
          data: result.updatedProductData,
        },
      };
    // create new product
    case mainActions.CREATE_PRODUCT:
      result.updatedProducts = state.productList.data.items.filter(
        (obj) => obj.ProductId !== action.product.ProductId
      );
      result.updatedProducts.push(action.product);
      return {
        ...state,
        productList: {
          ...state.productList,
          data: {
            ...state.productList.data,
            items: result.updatedProducts,
            itemsCount: result.updatedProducts.length,
          },
        },
      };
    // delete product
    case mainActions.DELETE_PRODUCT:
      result.updatedProducts = state.productList.data.items.filter(
        (obj) => obj.ProductId !== action.product.data.ProductId
      );
      return {
        ...state,
        productList: {
          ...state.productList,
          data: {
            ...state.productList.data,
            items: result.updatedProducts,
            itemsCount: result.updatedProducts.length,
          },
        },
      };
    // read and refresh contact list
    case mainActions.PULL_CONTACT_LIST:
      result.updatedContactData = action.contacts.data
        ? action.contacts.data
        : newListSet().data;
      result.updatedContactItems = action.contacts.more
        ? state.contactList.data.items.concat(result.updatedContactData.items)
        : result.updatedContactData.items;
      result.updatedContactData.items = result.updatedContactItems;
      result.updatedContactData.itemsCount = result.updatedContactItems.length;
      return {
        ...state,
        contactList: {
          hydrated: true,
          data: result.updatedContactData,
        },
      };
    // create new contact
    case mainActions.CREATE_CONTACT:
      result.updatedContacts = state.contactList.data.items.filter(
        (obj) => obj.ContactUserId !== action.contact.data.ContactUserId
      );
      result.updatedContacts.push(action.contact.data);
      return {
        ...state,
        contactList: {
          ...state.contactList,
          data: {
            ...state.contactList.data,
            items: result.updatedContacts,
            itemsCount: result.updatedContacts.length,
          },
        },
      };
    // delete contact
    case mainActions.DELETE_CONTACT:
      result.updatedContacts = state.contactList.data.items.filter(
        (obj) => obj.ContactUserId !== action.contact.data.ContactUserId
      );
      return {
        ...state,
        contactList: {
          ...state.contactList,
          data: {
            ...state.contactList.data,
            items: result.updatedContacts,
            itemsCount: result.updatedContacts.length,
          },
        },
      };
    // read and refresh viewer calendar
    case mainActions.PULL_VIEWER_CALENDAR:
      // result.updatedMonth = action.calendar.more
      //   ? state.viewer.calendar[action.calendar.monthId].data.concat(
      //       action.calendar.data
      //     )
      //   : action.calendar.data;
      // result.updatedMonth = [
      //   ...new Map(
      //     result.updatedMonth.map((item) => [item["DateId"], item])
      //   ).values(),
      // ];
      result.updatedMonth = action.calendar.data;
      return {
        ...state,
        viewer: {
          ...state.viewer,
          calendar: {
            ...state.viewer.calendar,
            hydrated: true,
            viewDate: action.calendar.viewDate,
            [action.calendar.monthId]: {
              hydrated: true,
              data: result.updatedMonth,
              nextToken: action.calendar.nextToken,
              expires: action.calendar.expires
                ? action.calendar.expires
                : dayjs().add(10, "minutes"),
            },
          },
        },
      };
    // read and refresh viewer calendar
    case mainActions.RESET_VIEWER_CALENDAR:
      result.updatedCalendar = newViewer().calendar;
      result.updatedCalendar.hydrated = true;
      result.updatedCalendar.viewDate = state.viewer.calendar.viewDate;
      result.updatedCalendar.externalHydrated =
        state.viewer.calendar.externalHydrated;
      result.updatedCalendar.external = state.viewer.calendar.external;
      return {
        ...state,
        viewer: {
          ...state.viewer,
          calendar: result.updatedCalendar,
        },
      };
    // set external calendars
    case mainActions.SET_EXTERNAL_CALENDARS:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          calendar: {
            ...state.viewer.calendar,
            externalHydrated: true,
            external: action.calendars,
          },
        },
      };
    case mainActions.SET_EXTERNAL_CALENDAR_META:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          calendar: {
            ...state.viewer.calendar,
            externalMeta: {
              ...state.viewer.calendar.externalMeta,
              [action.calendar.name]: action.calendar.meta,
            },
          },
        },
      };
    // update external calendar
    case mainActions.UPDATE_EXTERNAL_CALENDAR:
      result.updatedExternal = state.viewer.calendar.external.map((obj) =>
        obj.name === action.calendar.name
          ? {
              ...obj,
              connected: action.calendar.connected,
            }
          : obj
      );
      return {
        ...state,
        viewer: {
          ...state.viewer,
          calendar: {
            ...state.viewer.calendar,
            external: result.updatedExternal,
          },
        },
      };
    // update external calendar entry
    case mainActions.UPDATE_CALENDAR_MONTH_DATA:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          calendar: {
            ...state.viewer.calendar,
            [action.update.monthId]: {
              ...state.viewer.calendar[action.update.monthId],
              data: action.update.data,
            },
          },
        },
      };
    // read and refresh viewer event inbox
    case mainActions.PULL_VIEWER_EVENTS:
      result.updatedEvents = action.events.more
        ? state.viewer.events.data.concat(action.events.data)
        : action.events.data;
      result.updatedEvents = [
        ...new Map(
          result.updatedEvents.map((item) => [item["EventId"], item])
        ).values(),
      ];
      return {
        ...state,
        viewer: {
          ...state.viewer,
          events: generateViewerEvents(
            result.updatedEvents,
            action.events.nextToken
          ),
        },
      };
    // archive or unarchive events in viewer event inbox
    case mainActions.ARCHIVE_EVENT:
      result.updatedEvents = state.viewer.events.data.map((obj) =>
        obj.EventId === action.event.EventId
          ? {
              ...obj,
              SubCategory: action.event.unarchive
                ? obj.SubCategory.replace("-archived", "").replace(
                    "archived",
                    "restored"
                  )
                : ["owner", "manager"].includes(obj.SubCategory)
                ? obj.SubCategory + "-archived"
                : "archived",
            }
          : obj
      );
      return {
        ...state,
        viewer: {
          ...state.viewer,
          events: generateViewerEvents(
            result.updatedEvents,
            state.viewer.events.nextToken
          ),
        },
      };
    // remove or add events from viewer event inbox
    case mainActions.REMOVE_EVENT:
      result.updatedEvents = state.viewer.events.data.filter(
        (obj) => obj.EventId !== action.event.EventId
      );
      return {
        ...state,
        viewer: {
          ...state.viewer,
          events: generateViewerEvents(
            result.updatedEvents,
            state.viewer.events.nextToken
          ),
        },
      };
    case mainActions.ADD_EVENT:
      result.updatedEvents = state.viewer.events.data.filter(
        (obj) => obj.EventId !== action.event.EventId
      );
      result.addEventData = JSON.parse(JSON.stringify(action.event));
      result.addEventData.SubCategory = action.owner
        ? "owner"
        : action.manager
        ? "manager"
        : "pinned";
      result.updatedEvents.push(result.addEventData);
      return {
        ...state,
        viewer: {
          ...state.viewer,
          events: generateViewerEvents(
            result.updatedEvents,
            state.viewer.events.nextToken
          ),
        },
      };
    // connected user ownership
    case mainActions.UPDATE_USER:
      return {
        ...state,
        user: action.user,
      };
    // capture any viewer trail activity
    case mainActions.UPDATE_VIEWER_TRAIL:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          trail: {
            ...state.viewer.trail,
            ...action.trail,
          },
        },
      };
    // alert messages
    case mainActions.UPDATE_ALERT:
      return {
        ...state,
        alert: action.alert,
      };
    // set access token
    case mainActions.SET_TOKEN:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          tokens: {
            ...state.viewer.tokens,
            [action.token.name]: action.token,
          },
        },
      };
    // set observables
    case mainActions.SET_OBSERVABLES_TRACKER:
      return {
        ...state,
        observablesTracker: {
          ...state.observablesTracker,
          active: action.observablesTracker.active,
        },
      };
    // set observable callback loader
    case mainActions.SET_CALLBACK_LOADER:
      result.callbackState = action.callbackState
        ? action.callbackState
        : action.callbackState === null
        ? null
        : state.callbackState;
      return {
        ...state,
        callbackLoader: action.callbackLoader,
        callbackState: result.callbackState,
      };
    // set action drawer
    case mainActions.SET_ACTION_DRAWER:
      return {
        ...state,
        actionDrawer: action.drawer,
      };
    default:
      return state;
  }
};

// derived state internal functions
const generateNotifications = (params) => {
  const pullData = params.pullData;
  const pullMore = params.pullMore;
  const currentState = params.currentState;
  const newItem = params.newItem;
  let result = {
    combined: pullData
      ? pullMore
        ? currentState.data.combined.concat(pullData.items)
        : pullData.items
      : currentState.data.combined,
    combinedCount: pullData
      ? pullMore
        ? pullData.itemsCount + currentState.data.combinedCount
        : pullData.itemsCount
      : currentState.data.combinedCount,
    nextToken: pullData ? pullData.nextToken : currentState.data.nextToken,
  };
  // sort by item NotificationId using the timestamp element
  // example push:message:access:T8305895442858
  result.combined.sort((a, b) =>
    a.NotificationId.split(":").pop() > b.NotificationId.split(":").pop()
      ? 1
      : -1
  );
  // new items replace existing if it exists or add to top if new
  if (newItem) {
    let existingItem = result.combined.find(
      (obj) => obj.NotificationId === newItem.NotificationId
    );
    if (existingItem) {
      existingItem = newItem;
    } else {
      result.combined = [newItem, ...result.combined];
    }
  }
  result.combined = result.combined.filter((obj) => !obj.removed);
  result.combinedCount = result.combined.length;
  // created filtered lists based on category
  result.activity = result.combined.filter((obj) =>
    obj.MessageCategory.startsWith("activity")
  );
  result.activityCount = result.activity.length;
  result.activityUnreadCount = result.activity.filter(
    (obj) => !obj.MessageRead
  ).length;
  result.messages = result.combined.filter((obj) =>
    obj.MessageCategory.startsWith("message")
  );
  result.messagesCount = result.messages.length;
  result.messagesUnreadCount = result.messages.filter(
    (obj) => !obj.MessageRead
  ).length;
  return result;
};

const generateViewerEvents = (events, nextToken) => {
  // sort events by Url
  events.sort((a, b) => (a.Url > b.Url ? 1 : -1));
  // filter events
  const activeEvents = events.filter(
    (obj) =>
      !obj.SubCategory ||
      (obj.SubCategory && !obj.SubCategory.includes("archived"))
  );
  const archivedEvents = events.filter(
    (obj) => obj.SubCategory && obj.SubCategory.includes("archived")
  );
  // filter for any subcategories in runner states
  const runnerEvents = activeEvents.filter((obj) => {
    let subCategories = obj.SubCategory.split(":");
    return subCategories.some((sub) => EventPinnedRunnerStates().includes(sub));
  });
  // filter for any subcategories in rider states but not runner states
  const riderEvents = activeEvents.filter((obj) => {
    let subCategories = obj.SubCategory.split(":");
    return (
      subCategories.some((sub) => EventPinnedRiderStates().includes(sub)) &&
      !subCategories.some((sub) => EventPinnedRunnerStates().includes(sub))
    );
  });

  return {
    hydrated: true,
    data: events,
    active: activeEvents,
    runners: runnerEvents,
    riders: riderEvents,
    archived: archivedEvents,
    nextToken: nextToken,
  };
};

export const MainContext = createContext();

// react core
import React, { useState, useEffect } from "react";

// material design
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
// import Slide from "@mui/material/Slide";
import Switch from "@mui/material/Switch";
import NativeSelect from "@mui/material/NativeSelect";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
// import CircularProgress from "@mui/material/CircularProgress";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTag as iconRiderTag } from "@fortawesome/pro-solid-svg-icons";
import { faUsers as iconRiders } from "@fortawesome/pro-solid-svg-icons";
import { faCoins as iconPrice } from "@fortawesome/pro-solid-svg-icons";
import { faChevronLeft as iconArrowLeft } from "@fortawesome/pro-thin-svg-icons";
import { faChevronRight as iconArrowRight } from "@fortawesome/pro-thin-svg-icons";
import { faPlusCircle as iconIncrement } from "@fortawesome/pro-solid-svg-icons";
import { faMinusCircle as iconDecrement } from "@fortawesome/pro-solid-svg-icons";
import { faRocketLaunch as iconTicking } from "@fortawesome/pro-duotone-svg-icons";
import { faExplosion as iconLaunched } from "@fortawesome/pro-duotone-svg-icons";
import { faTimes as iconClose } from "@fortawesome/pro-solid-svg-icons";
import { faEdit as iconEdit } from "@fortawesome/pro-solid-svg-icons";
import { faCircle as iconCircle } from "@fortawesome/pro-solid-svg-icons";
import { faCheckCircle as iconTicked } from "@fortawesome/pro-solid-svg-icons";
import { faCheckCircle as iconHolderTicked } from "@fortawesome/pro-thin-svg-icons";
import { faInfoCircle as iconHelp } from "@fortawesome/pro-solid-svg-icons";

// entzy config and models
import configEntzy from "components/config/ConfigEntzy";
import { UserAvatar } from "components/utils/common/CommonAvatars";
import { ActionBoxButton } from "components/utils/common/CommonButtons";
import { ActionAlert } from "components/utils/common/CommonLoaders";

// action hooks
import { useSwipeable } from "react-swipeable";

export function DatePicker(props) {
  const groups = props.options.reduce(
    (groups, item) => ({
      ...groups,
      [item.groupBy]: [...(groups[item.groupBy] || []), item],
    }),
    {}
  );
  const optionsDecorate = props.optionsDecorate;
  const optionsDisabled = props.optionsDisabled ? props.optionsDisabled : [];
  const optionsSoldOut =
    optionsDecorate && optionsDecorate.soldOut ? optionsDecorate.soldOut : [];

  const [selectedGroup, setSelectedGroup] = useState(props.options[0].groupBy);

  const handleGroupChange = (value) => {
    setSelectedGroup(value);
  };
  const handleGroupNext = (out) => {
    const groupKeys = Object.keys(groups);
    const currentIndex = groupKeys.indexOf(selectedGroup);
    // const nextIndex = (currentIndex + 1) % groupKeys.length;
    const nextIndex =
      currentIndex === groupKeys.length - 1 ? currentIndex : currentIndex + 1;
    if (out.monthName) return groupKeys[nextIndex].split(" ")[0];
    if (out.isDisabled) return nextIndex === currentIndex;

    setSelectedGroup(groupKeys[nextIndex]);
  };
  const handleGroupPrev = (out) => {
    const groupKeys = Object.keys(groups);
    const currentIndex = groupKeys.indexOf(selectedGroup);
    // const prevIndex = (currentIndex - 1 + groupKeys.length) % groupKeys.length;
    const prevIndex = currentIndex === 0 ? 0 : currentIndex - 1;
    if (out.monthName) return groupKeys[prevIndex].split(" ")[0];
    if (out.isDisabled) return prevIndex === currentIndex;
    setSelectedGroup(groupKeys[prevIndex]);
  };

  // swipe handlers for module switching
  const swipeHandlers = useSwipeable({
    onSwipedLeft: (e) => handleGroupNext(e),
    onSwipedRight: (e) => handleGroupPrev(e),
  });

  return (
    <Box
      className="box-default"
      sx={{
        borderBottom: "1px solid #999",
      }}
    >
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_MD,
          pb: configEntzy.APP_SPACING_MD2X,
        }}
      >
        <Typography variant="subtitle2">{props.label}</Typography>
        <Typography variant="subtitle1">
          <em>{props.placeholder}</em>
        </Typography>
      </Box>
      <Box
        className="box-default nooverflow"
        sx={{
          // height: configEntzy.AVATAR_CONTAINER_XL3X,
          pb: configEntzy.APP_SPACING_LG,
        }}
      >
        <Box className="box-default" {...swipeHandlers}>
          {Object.entries(groups).map(([header, options], index) => (
            // <Slide
            //   key={header}
            //   direction="up"
            //   in={selectedGroup === header}
            //   mountOnEnter
            //   unmountOnExit
            // >
            <Box
              key={header}
              className="box-default"
              hidden={selectedGroup !== header}
            >
              <Box
                className="box-inline"
                sx={{
                  width: configEntzy.AVATAR_SIZE_XL,
                  mr: configEntzy.APP_SPACING_MD,
                  pr: configEntzy.APP_SPACING_XS3X,
                  pb: configEntzy.APP_SPACING_XS3X,
                  borderRadius: configEntzy.BORDER_SIZE_XL,
                }}
              >
                <Box
                  className="box-default"
                  sx={{
                    pt: configEntzy.APP_SPACING_XS3X,
                    pl: configEntzy.APP_SPACING_XS3X,
                  }}
                >
                  <Box
                    className="box-default bg-white shadow-default"
                    sx={{
                      mt: configEntzy.APP_SPACING_XS3X,
                      p: configEntzy.APP_SPACING_SM,
                      borderRadius: configEntzy.BORDER_SIZE_XL,
                    }}
                  >
                    <Box className="box-default">
                      <FormControl fullWidth>
                        <NativeSelect
                          id={"group-select-" + header}
                          name={"group-select-" + header}
                          value={selectedGroup}
                          label="Calendar Selector"
                          onChange={(e) => {
                            handleGroupChange(e.target.value);
                          }}
                          variant="outlined"
                          inputProps={{
                            style: {
                              padding: "5px",
                              fontWeight: "bold",
                              textAlign: "center",
                              textTransform: "uppercase",
                            },
                          }}
                          sx={{
                            p: configEntzy.APP_SPACING_MD,
                          }}
                          fullWidth={true}
                        >
                          {Object.keys(groups).map((group, index) => (
                            <option key={group} value={group}>
                              {group}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box
                    className="box-default half-width text-left action-pointer"
                    sx={{
                      pt: configEntzy.APP_SPACING_XS3X,
                      pr: configEntzy.APP_SPACING_XS2X,
                    }}
                  >
                    <ActionBoxButton
                      size="small"
                      bgColor="#555"
                      color="orange"
                      text={
                        <span
                          style={{
                            visibility: handleGroupPrev({ isDisabled: true })
                              ? "hidden"
                              : "visible",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {handleGroupPrev({ monthName: true })}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      }
                      onClick={handleGroupPrev}
                      disabled={handleGroupPrev({ isDisabled: true })}
                      startIcon={
                        <FontAwesomeIcon
                          icon={iconArrowLeft}
                          transform="grow-8"
                          color="gray"
                          fixedWidth
                        />
                      }
                    />
                  </Box>
                  <Box
                    className="box-default half-width text-right action-pointer"
                    sx={{
                      pt: configEntzy.APP_SPACING_XS3X,
                      pl: configEntzy.APP_SPACING_XS2X,
                    }}
                  >
                    <ActionBoxButton
                      size="small"
                      bgColor="#555"
                      color="orange"
                      text={
                        <span
                          style={{
                            visibility: handleGroupNext({ isDisabled: true })
                              ? "hidden"
                              : "visible",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {handleGroupNext({ monthName: true })}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      }
                      onClick={handleGroupNext}
                      disabled={handleGroupNext({ isDisabled: true })}
                      endIcon={
                        <FontAwesomeIcon
                          icon={iconArrowRight}
                          transform="grow-8"
                          color="gray"
                          fixedWidth
                        />
                      }
                    />
                  </Box>
                </Box>
                {options.map((option, index) => (
                  <Box
                    className="box-default"
                    key={option.value}
                    sx={{
                      width: "50%",
                      pt: configEntzy.APP_SPACING_XS3X,
                      pl: configEntzy.APP_SPACING_XS3X,
                    }}
                  >
                    <Box
                      className="box-default bg-white-t50 shadow-default action-pointer"
                      sx={{
                        p: configEntzy.APP_SPACING_XS3X,
                        backgroundColor: props.value.includes(option.value)
                          ? "black"
                          : optionsDecorate &&
                            optionsDecorate.otherHolderTicks &&
                            optionsDecorate.otherHolderTicks.includes(option.id)
                          ? "green"
                          : ["Sat", "Sun"].includes(
                              option.display.split("|")[1]
                            )
                          ? "tertiary.main"
                          : "white",
                        borderRadius: configEntzy.BORDER_SIZE_XL,
                      }}
                      onClick={
                        optionsDisabled.includes(option.id)
                          ? undefined
                          : () =>
                              props.onChange(
                                {
                                  target: {
                                    id: props.id,
                                    name: props.name,
                                    value: option.value,
                                    display: option.display,
                                  },
                                },
                                option.value,
                                "multi-select"
                              )
                      }
                    >
                      {props.value.includes(option.value) && (
                        <Box className="box-default zero-height relative pop-up text-right">
                          <Typography variant="body2">
                            <span className="fa-layers fa-fw">
                              <FontAwesomeIcon
                                icon={iconCircle}
                                transform="grow-2 right-9 up-9"
                                color="white"
                              />
                              <FontAwesomeIcon
                                icon={iconTicked}
                                transform="grow-4 right-9 up-9"
                                color="green"
                              />
                            </span>
                          </Typography>
                        </Box>
                      )}
                      {!props.value.includes(option.value) &&
                        optionsDecorate &&
                        optionsDecorate.otherHolderTicks &&
                        optionsDecorate.otherHolderTicks.includes(
                          option.id
                        ) && (
                          <Box className="box-default zero-height relative pop-up text-right">
                            <Typography variant="body2">
                              <span className="fa-layers fa-fw">
                                <FontAwesomeIcon
                                  icon={iconCircle}
                                  transform="grow-2 right-9 up-9"
                                  color="white"
                                />
                                <FontAwesomeIcon
                                  icon={iconHolderTicked}
                                  transform="grow-4 right-9 up-9"
                                  color="green"
                                />
                              </span>
                            </Typography>
                          </Box>
                        )}
                      {optionsDecorate &&
                      optionsDecorate.launched &&
                      optionsDecorate.launched.includes(option.id) ? (
                        <Box
                          className="box-default"
                          sx={{
                            p: configEntzy.APP_SPACING_XS2X,
                            borderRadius: configEntzy.BORDER_SIZE_XL,
                            backgroundColor: optionsSoldOut.includes(option.id)
                              ? "primaryDisabled.dark"
                              : "primary.dark",
                            opacity: 0.8,
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Typography variant="body2" color="white">
                            {optionsSoldOut.includes(option.id) && (
                              <span>SOLD&nbsp;OUT</span>
                            )}
                            <FontAwesomeIcon icon={iconLaunched} />
                          </Typography>
                          <Typography variant="body2" color="white">
                            <span>{option.display.replace("|", " ")}:</span>
                            <span>&nbsp;</span>
                            <span>ON!</span>
                            <span>&nbsp;</span>
                            <span>
                              {
                                optionsDecorate.ticking.find(
                                  (obj) => obj.id === option.id
                                ).progress.remaining.capacity
                              }
                            </span>
                            <span>&nbsp;left</span>
                          </Typography>
                        </Box>
                      ) : optionsDecorate &&
                        optionsDecorate.ticking &&
                        optionsDecorate.ticking.filter(
                          (obj) => obj.id === option.id
                        ).length > 0 ? (
                        <Box
                          className="box-default"
                          sx={{
                            p: configEntzy.APP_SPACING_XS2X,
                            borderRadius: configEntzy.BORDER_SIZE_XL,
                            backgroundColor: optionsSoldOut.includes(option.id)
                              ? "primaryDisabled.light"
                              : "secondary.light",
                            opacity: 0.8,
                          }}
                        >
                          <Box className="box-default text-right nooverflow">
                            <Box
                              className="box-default"
                              sx={{
                                borderTopLeftRadius: configEntzy.BORDER_SIZE_XL,
                                borderBottomLeftRadius:
                                  configEntzy.BORDER_SIZE_XL,
                                width:
                                  optionsDecorate.ticking.find(
                                    (obj) => obj.id === option.id
                                  ).progress.trigger.total + "%",
                                backgroundColor: optionsSoldOut.includes(
                                  option.id
                                )
                                  ? "primaryDisabled.main"
                                  : "secondary.main",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <Typography variant="body2" color="white">
                                {optionsSoldOut.includes(option.id) ? (
                                  <span style={{ color: "white" }}>
                                    <span>&nbsp;FULL&nbsp;</span>
                                  </span>
                                ) : (
                                  optionsDecorate.ticking.find(
                                    (obj) => obj.id === option.id
                                  ).progress.trigger.total > 50 && (
                                    <span style={{ color: "orange" }}>
                                      <span>&nbsp;</span>
                                      {optionsDecorate.ticking.find(
                                        (obj) => obj.id === option.id
                                      ).progress.trigger.total + "%"}
                                      <span>&nbsp;</span>
                                    </span>
                                  )
                                )}
                                <FontAwesomeIcon
                                  icon={iconTicking}
                                  transform="rotate-45"
                                />
                              </Typography>
                            </Box>
                            <Box
                              className="box-default"
                              sx={{
                                width:
                                  100 -
                                  optionsDecorate.ticking.find(
                                    (obj) => obj.id === option.id
                                  ).progress.trigger.total +
                                  "%",
                                whiteSpace: "nowrap",
                              }}
                              hidden={
                                optionsDecorate.ticking.find(
                                  (obj) => obj.id === option.id
                                ).progress.trigger.total > 50
                              }
                            >
                              <Typography variant="body2" color="orange">
                                <span>
                                  {optionsDecorate.ticking.find(
                                    (obj) => obj.id === option.id
                                  ).progress.trigger.total + "%"}
                                </span>
                                <span>&nbsp;</span>
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="box-default">
                            <Typography variant="body2" noWrap>
                              <span>&nbsp;</span>
                              {option.display.replace("|", " ")}:
                              <span>&nbsp;</span>
                              {
                                optionsDecorate.ticking.find(
                                  (obj) => obj.id === option.id
                                ).progress.count
                              }
                              <span>&nbsp;</span>
                              {optionsDecorate.ticking.find(
                                (obj) => obj.id === option.id
                              ).progress.count === 1
                                ? "offer"
                                : "offers"}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          className="box-default bg-white-t50"
                          sx={{
                            p: configEntzy.APP_SPACING_XS2X,
                            borderRadius: configEntzy.BORDER_SIZE_XL,
                          }}
                        >
                          <Typography variant="body2">
                            {option.display.replace("|", " ")}
                          </Typography>
                          <Typography
                            variant="body2"
                            className="content-disabled"
                          >
                            <em>No offers yet</em>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
                {/* map out invisible cells to make a grid size always 36 */}
                {/* {35 - options.length > 0 &&
                  [...Array(35 - options.length)].map((e, i) => (
                    <Box
                      className="box-default"
                      key={i}
                      sx={{
                        width: "20%",
                        pt: configEntzy.APP_SPACING_XS3X,
                        pl: configEntzy.APP_SPACING_XS3X,
                      }}
                    >
                      <Box
                        className="box-default bg-black-t10"
                        sx={{
                          p: configEntzy.APP_SPACING_XS3X,
                          borderRadius: configEntzy.BORDER_SIZE_XL,
                        }}
                      >
                        <Box
                          className="box-default"
                          sx={{
                            p: configEntzy.APP_SPACING_XS2X,
                            borderRadius: configEntzy.BORDER_SIZE_XL,
                          }}
                        >
                          <Typography variant="body2">
                            <span>&nbsp;</span>
                          </Typography>
                          <Typography variant="body2">
                            <span>&nbsp;</span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))} */}
              </Box>
            </Box>
            // </Slide>
          ))}
        </Box>
      </Box>
      {/* <Box
        className="box-default"
        sx={{
          pb: configEntzy.APP_SPACING_MD2X,
        }}
      >
        <Box
          className="box-default half-width text-left"
          sx={{
            p: configEntzy.APP_SPACING_XS2X,
          }}
        >
          <ActionBoxButton
            size="small"
            text="Earlier"
            endIcon={
              <FontAwesomeIcon
                icon={iconArrowLeft}
                transform="grow-8"
                fixedWidth
              />
            }
            onClick={handleGroupPrev}
          />
        </Box>
        <Box
          className="box-default half-width text-right"
          sx={{
            p: configEntzy.APP_SPACING_XS2X,
          }}
        >
          <ActionBoxButton
            size="small"
            text="Later"
            startIcon={
              <FontAwesomeIcon
                icon={iconArrowRight}
                transform="grow-8"
                fixedWidth
              />
            }
            onClick={handleGroupNext}
          />
        </Box>
      </Box> */}
    </Box>
  );
}

export function MemberPickerNumberRocker(props) {
  const ticketPriceState = props.formState.find(
    (item) => item.id === "ticket-type"
  ).value;
  const ticketGroupState = props.formState.find(
    (item) => item.id === "ticket-group"
  ).value;
  const ticketPriceTotal = ticketPriceState ? ticketPriceState.price : 0;
  const ticketGroupTotal = ticketGroupState ? ticketGroupState.value : 0;

  const [submitted, setSubmitted] = useState(false);
  const [addNamedGuests, setAddNamedGuests] = useState(false);
  const [sharePayment, setSharePayment] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [ticketNamedTotal, setTicketNamedTotal] = useState(0);
  const [totals, setTotals] = useState({});
  const [userMap, setUserMap] = useState({});
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");

  const handleAddNamedGuests = () => {
    setSubmitted(false);
    setAddNamedGuests(!addNamedGuests);
  };
  const handleSharePayment = () => {
    setSharePayment(!sharePayment);
  };
  const handleShowHelp = () => {
    setShowHelp(!showHelp);
  };

  const handleIncrement = (option) => {
    if (ticketNamedTotal < ticketGroupTotal - 1) {
      setTotals({
        ...totals,
        [option.identity]: totals[option.identity]
          ? totals[option.identity] + 1
          : 1,
      });
      setTicketNamedTotal(ticketNamedTotal + 1);
      setUserMap({
        ...userMap,
        [option.identity]: option,
      });
    }
  };
  const handleDecrement = (option) => {
    setTotals({
      ...totals,
      [option.identity]: totals[option.identity]
        ? totals[option.identity] === 0
          ? 0
          : totals[option.identity] - 1
        : 0,
    });
    setTicketNamedTotal(
      totals[option.identity]
        ? totals[option.identity] === 0
          ? ticketNamedTotal
          : ticketNamedTotal - 1
        : ticketNamedTotal
    );
  };

  const submitResults = () => {
    const results = {};
    results.named = Object.entries(userMap).length
      ? Object.entries(totals).map(([key, value]) => {
          return { identity: key, name: userMap[key].name, total: value };
        })
      : [];
    results.totals = {
      named: ticketNamedTotal,
      unnamed: ticketGroupTotal - ticketNamedTotal,
      group: ticketGroupTotal,
    };
    results.sharePayment = sharePayment;
    setAddNamedGuests(false);
    setSubmitted(true);
    if (ticketNamedTotal + ticketGroupTotal === 0) {
      setAlert(true);
      setMessage("Please select at a group size of at least 1");
      return;
    } else {
      setAlert(false);
      setMessage("");
      return props.onChange(
        null,
        {
          id: "custom-member-data",
          value: results,
          name: "Member Data",
          display: "Member Data",
          icon: null,
        },
        "submitCustomSelect"
      );
    }
  };

  // reset form when ticket group changes
  useEffect(() => {
    setTotals({});
    setTicketNamedTotal(0);
    setAddNamedGuests(false);
    setSharePayment(false);
    setSubmitted(false);
    setAlert(false);
    setMessage("");
  }, [ticketPriceTotal, ticketGroupTotal]);

  return (
    <Box
      className="box-default"
      sx={{
        pb: configEntzy.APP_SPACING_MD3X,
        borderBottom: "1px solid #999",
      }}
    >
      <Box className="box-default">
        <Typography variant="subtitle2">
          <FontAwesomeIcon icon={iconRiderTag} size="2x" fixedWidth />
        </Typography>
        <Typography
          variant="subtitle2"
          color="primary"
          sx={{ mt: configEntzy.APP_SPACING_MD }}
        >
          {props.label}
        </Typography>
      </Box>
      {ticketGroupTotal === 1 ? (
        <Box className="box-default">
          <Typography variant="subtitle1">
            <em>Ticket holder is You</em>
          </Typography>
        </Box>
      ) : (
        <Box className="box-default">
          <Box className="box-default action-pointer" onClick={handleShowHelp}>
            <Typography variant="subtitle1" color="secondary">
              <em>Allocate riders or leave all in your name?</em>
            </Typography>
            <Typography variant="subtitle1">
              <span>Named riders will need to accept.</span>
              <span>&nbsp;More Info&nbsp;</span>
              <FontAwesomeIcon
                icon={showHelp ? iconClose : iconHelp}
                fixedWidth
              />
            </Typography>
            <Typography variant="subtitle1">{props.placeholder}</Typography>
          </Box>
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
            }}
          >
            <Box
              className="box-default bg-black-t75 text-left"
              sx={{
                p: configEntzy.APP_SPACING_MD,
                mb: configEntzy.APP_SPACING_MD,
                borderRadius: configEntzy.BORDER_SIZE_XL,
              }}
              hidden={!showHelp}
            >
              <Typography variant="subtitle2" color="primary">
                <FontAwesomeIcon icon={iconHelp} fixedWidth />
                <span>&nbsp;&nbsp;</span>
                Named Riders in Groups
              </Typography>
              <Typography
                variant="subtitle1"
                color="white"
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                }}
              >
                Within a grouped ticket holding you can allocate places to named
                riders by selecting from your contacts. This means they will be
                able to access the ticket in their Entzy account, contribute to
                date offers and show the ticket separately at the point of
                entry.
              </Typography>
              <Typography
                variant="subtitle1"
                color="white"
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                }}
              >
                Alternatively if you leave place holdings in your name then you
                control date offers and the other riders must be present with
                you as a group when showing tickets for entry.
              </Typography>
              <Typography
                variant="subtitle1"
                color="white"
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                }}
              >
                <span style={{ color: "orange" }}>Note:</span> If you allocate
                named riders then date matching will happen between all of you
                automatically. Only when everyone aligns on the same dates is an
                overall offer presented to the run team.
              </Typography>
              <Typography
                variant="subtitle1"
                color="white"
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                }}
              >
                Tap the edit icon to easily add or remove named riders from your
                group. Contacts will be notified to accept ownership of their
                holding. For chargeable tickets you have the additional option
                to split the price on launch.
              </Typography>
            </Box>
          </Box>
          <Box
            className="box-default shadow-light action-pointer"
            sx={{
              p: configEntzy.APP_SPACING_MD,
              borderRadius: configEntzy.BORDER_SIZE_XL,
            }}
            onClick={handleAddNamedGuests}
          >
            <Box className="box-default">
              <Box
                className="box-default upper-case"
                sx={{
                  width: "25%",
                  // pr: configEntzy.APP_SPACING_MD,
                }}
              >
                <Typography variant="body2">In Your</Typography>
                <Typography variant="body2">Name</Typography>
              </Box>
              <Box
                className="box-default"
                sx={{
                  width: "50%",
                  pt: configEntzy.APP_SPACING_SM,
                }}
              >
                <Typography variant="h6" color="primary">
                  <span>&nbsp;&nbsp;</span>
                  <span
                    style={{
                      backgroundColor:
                        ticketGroupTotal - ticketNamedTotal === 0
                          ? "grey"
                          : "orange",
                      color: "white",
                      padding: "2px",
                      borderRadius: "5px",
                    }}
                  >
                    &nbsp;{ticketGroupTotal - ticketNamedTotal}&nbsp;
                  </span>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <FontAwesomeIcon
                    icon={addNamedGuests ? iconClose : iconEdit}
                    fixedWidth
                  />
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <span
                    style={{
                      backgroundColor:
                        ticketNamedTotal === 0 ? "grey" : "orange",
                      color: "white",
                      padding: "2px",
                      borderRadius: "5px",
                    }}
                  >
                    &nbsp;{ticketNamedTotal}&nbsp;
                  </span>
                  <span>&nbsp;&nbsp;</span>
                </Typography>
              </Box>

              <Box
                className="box-default upper-case"
                sx={{
                  width: "25%",
                  // pl: configEntzy.APP_SPACING_MD,
                }}
              >
                <Box className="box-default">
                  <Typography variant="body2">Named</Typography>
                  <Typography variant="body2">Riders</Typography>
                </Box>
              </Box>
            </Box>
            {/* <Box
          className="box-default half-width text-right"
          sx={{
            pr: configEntzy.APP_SPACING_MD2X,
          }}
        >
          <Box className="box-default">
            <Box className="box-inline right">
              <UserAvatar size="sm" user={props.user} nameHideInfo={true} />
            </Box>
          </Box>
        </Box>
        <Box
          className="box-default half-width text-left"
          sx={{
            pl: configEntzy.APP_SPACING_MD2X,
            borderLeft: "1px solid #ccc",
          }}
        >
          <Box className="box-default">
            <TextTag
              size="lg"
              text={
                <span>
                  <FontAwesomeIcon icon={iconRiderTag} size="2x" fixedWidth />
                  <span>&nbsp;</span>
                  {ticketNamedTotal}
                  <span>&nbsp;</span>
                </span>
              }
              bgColor={
                ticketNamedTotal === 0 ? "tertiary.dark" : "primary.dark"
              }
              color="white"
              rounded={true}
            />
          </Box>
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_SM,
            }}
          >
            <Typography variant="subtitle1">
              <em>Named Riders</em>
            </Typography>
          </Box>
        </Box> */}
          </Box>

          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD,
            }}
            hidden={!addNamedGuests}
          >
            <Box
              className="box-default shadow-light"
              hidden={ticketPriceTotal === 0}
              sx={{
                mb: configEntzy.APP_SPACING_MD,
                borderRadius: configEntzy.BORDER_SIZE_XL,
              }}
            >
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD3X,
                }}
              >
                <Typography variant="subtitle2">
                  <FontAwesomeIcon
                    icon={iconPrice}
                    size="2x"
                    transform="up-10"
                    fixedWidth
                  />
                </Typography>
                <Typography variant="subtitle2">
                  Should each rider also be charged
                </Typography>
                <Typography variant="subtitle2">
                  their share of ticket price on launch?
                </Typography>
              </Box>
              <Box
                className="box-default"
                sx={{
                  pl: configEntzy.APP_SPACING_MD,
                  pb: configEntzy.APP_SPACING_MD,
                  mb: configEntzy.APP_SPACING_XS2X,
                }}
              >
                <FormControlLabel
                  control={<Switch />}
                  id="share-payment"
                  name="share-payment"
                  label={"Split Charges" + (sharePayment ? " ACTIVE" : "")}
                  checked={sharePayment}
                  onChange={handleSharePayment}
                  labelPlacement="end"
                />
              </Box>
            </Box>

            <Box
              className="box-default bg-black-t10 shadow-light"
              sx={{
                height: configEntzy.AVATAR_CONTAINER_XL2X,
                p: configEntzy.APP_SPACING_MD,
                borderRadius: configEntzy.BORDER_SIZE_XL,
                overflowY: "auto",
                overflowX: "hidden",
                scrollBehavior: "smooth",
              }}
            >
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD3X,
                }}
              >
                <Typography variant="subtitle2">
                  <FontAwesomeIcon
                    icon={iconRiders}
                    size="2x"
                    transform="up-10"
                    fixedWidth
                  />
                </Typography>
                <Typography variant="subtitle2">
                  Select riders to add to your ticket
                </Typography>
              </Box>
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD2X,
                }}
              >
                {props.options.length ? (
                  props.options.map((option, index) => (
                    <Box
                      key={option.identity}
                      className="box-inline bg-white shadow-default nooverflow"
                      sx={{
                        width: configEntzy.AVATAR_CONTAINER_MD,
                        mr: configEntzy.APP_SPACING_SM2X,
                        mb: configEntzy.APP_SPACING_SM2X,
                        pt: configEntzy.APP_SPACING_MD,
                        borderRadius: configEntzy.BORDER_SIZE_XL,
                      }}
                    >
                      <Box className="box-inline">
                        <UserAvatar user={option} />
                      </Box>
                      <Box
                        className="box-default"
                        sx={{
                          pt: configEntzy.APP_SPACING_MD,
                          pb: configEntzy.APP_SPACING_MD,
                        }}
                      >
                        <Box
                          className="box-default quarter-width text-right action-pointer"
                          onClick={() => {
                            handleDecrement(option);
                          }}
                        >
                          <Typography variant="h6">
                            <FontAwesomeIcon
                              icon={iconDecrement}
                              color="black"
                              fixedWidth
                            />
                          </Typography>
                        </Box>
                        <Box className="box-default half-width">
                          <Typography variant="h6">
                            {totals[option.identity] ? (
                              <span
                                style={{
                                  backgroundColor: "orange",
                                  color: "white",
                                  padding: "2px",
                                  borderRadius: "5px",
                                }}
                              >
                                &nbsp;{totals[option.identity]}&nbsp;
                              </span>
                            ) : (
                              0
                            )}
                          </Typography>
                        </Box>
                        <Box
                          className="box-default quarter-width text-left action-pointer"
                          onClick={() => {
                            handleIncrement(option);
                          }}
                        >
                          <Typography variant="h6">
                            <FontAwesomeIcon
                              icon={iconIncrement}
                              color="black"
                              fixedWidth
                            />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box
                    className="box-default"
                    sx={{
                      p: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <Typography variant="subtitle2">
                      <em>No riders available for selection</em>
                    </Typography>
                    <Typography variant="subtitle2">
                      <em>To update add people to your contacts</em>
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              className="box-default content-disabled"
              sx={{
                pt: configEntzy.APP_SPACING_LG,
                pb: configEntzy.APP_SPACING_MD3X,
              }}
            >
              <Typography variant="subtitle2">
                <em>If the member you need is not listed</em>
              </Typography>
              <Typography variant="subtitle2">
                <em>add to your contacts to update the list</em>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      {!submitted && (
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_MD,
            pb: configEntzy.APP_SPACING_MD,
          }}
        >
          <ActionBoxButton
            size="medium"
            text="Confirm Riders & Continue"
            bgColor="primary.main"
            onClick={submitResults}
            endIcon={<FontAwesomeIcon icon={iconArrowRight} />}
            fullWidth={true}
          />
        </Box>
      )}
      {alert && message && (
        <Box
          className="box-default"
          sx={{
            p: configEntzy.APP_SPACING_MD,
          }}
        >
          <ActionAlert severity="error" message={message} />
        </Box>
      )}
    </Box>
  );
}

// react core
import { Fragment, useContext, useState, useEffect, useCallback } from "react";

// date handling
import dayjs from "dayjs";

// material design
import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// fonts icons and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode as iconTicketCode } from "@fortawesome/pro-duotone-svg-icons";
import { faSpinnerThird as iconSpinner } from "@fortawesome/pro-duotone-svg-icons";
import { faCheckCircle as iconCheck } from "@fortawesome/pro-solid-svg-icons";
import { faClock as iconPending } from "@fortawesome/pro-duotone-svg-icons";
import { faRocketLaunch as iconLaunched } from "@fortawesome/pro-regular-svg-icons";
import { faUser as iconUser } from "@fortawesome/pro-solid-svg-icons";
import { faUsers as iconUsers } from "@fortawesome/pro-solid-svg-icons";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { UserAvatar } from "components/utils/common/CommonAvatars";
import {
  ActionLoader,
  ActionAlert,
} from "components/utils/common/CommonLoaders";
import { MoreButton } from "components/utils/common/CommonButtons";
import { HeaderTag } from "components/utils/common/CommonTags";

function RoomDatesTicking(props) {
  const user = props.user;
  const viewDate = props.viewDate;
  const triggered = viewDate.ticking.TickStatus === "triggered";
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [hydrating, setHydrating] = useState(false);
  const [showOffers, setShowOffers] = useState(false);

  const handleShowOffers = () => {
    setShowOffers(!showOffers);
  };

  const handlePullTicks = async (more, reloading) => {
    if (more) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }
    const response = await eventContext.preparePullTicks({
      EventId: eventContext.state.event.data.EventId,
      DateKey: viewDate.id,
      nextToken: eventContext.state.ticks.dates[viewDate.id]
        ? eventContext.state.ticks.dates[viewDate.id].nextToken
        : null,
      more,
    });
    if (response.alert) {
      mainContext.updateAlert(response);
    } else {
      eventContext.pullTicks(response);
    }
    setLoadingMore(false);
    setLoading(false);
  };
  const cbHandlePullTicks = useCallback(handlePullTicks, [
    mainContext,
    eventContext,
    viewDate,
  ]);

  // add effect to pull ticks on page load if not already hydrated and on a regular basis
  useEffect(() => {
    if (
      user.connected &&
      !hydrating &&
      !(
        eventContext.state.ticks.dates[viewDate.id] &&
        eventContext.state.ticks.dates[viewDate.id].hydrated
      )
    ) {
      setHydrating(true);
      cbHandlePullTicks(false, false);
    } else {
      if (eventContext.state.ticks.dates[viewDate.id]) {
        const now = dayjs();
        const lastUpdated = now.diff(
          eventContext.state.ticks.dates[viewDate.id].updated,
          "minutes"
        );
        if (
          !reloading &&
          user.connected &&
          lastUpdated > eventContext.state.constants.events.cacheExpiry
        ) {
          setReloading(true);
          cbHandlePullTicks(false, true);
        }
      }
    }
  }, [
    hydrating,
    user.connected,
    reloading,
    viewDate,
    eventContext.state.ticks.dates,
    eventContext.state.constants.events.cacheExpiry,
    cbHandlePullTicks,
  ]);

  const TickingBadge = (props) => {
    return (
      <Box
        className="box-default bg-black-t50 action-pointer"
        sx={{
          p: configEntzy.APP_SPACING_MD,
          borderRadius: configEntzy.BORDER_SIZE_XL,
          border: triggered ? "1px dashed orange" : "none",
          color: "white",
        }}
        onClick={() => eventContext.focusModule("entry")}
      >
        <Typography variant="subtitle2">
          <FontAwesomeIcon
            icon={iconTicketCode}
            style={{ color: "orange" }}
            size="2x"
            // transform="down-3 left-3"
          />
          <span
            style={{
              float: "right",
            }}
          >
            <span className="fa-layers fa-fw">
              {!triggered && (
                <FontAwesomeIcon
                  icon={iconSpinner}
                  color="orange"
                  spin={true}
                  transform="grow-20"
                />
              )}
              <span className="fa-layers-text">{props.count}</span>
            </span>
          </span>
        </Typography>
        <Typography
          variant="subtitle2"
          className="upper-case"
          sx={{ mt: configEntzy.APP_SPACING_MD }}
        >
          {props.tick.TicketDescription}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      className="box-default"
      sx={{
        // pt: configEntzy.APP_SPACING_MD,
        borderRadius: configEntzy.BORDER_SIZE_XL,
        color: "white",
      }}
    >
      <Container maxWidth="md" disableGutters>
        <Box
          className="box-default bg-white-t25 nooverflow"
          sx={{
            height: configEntzy.AVATAR_CONTAINER_MD,
            mb: configEntzy.APP_SPACING_MD,
            borderRadius: configEntzy.BORDER_SIZE_XL,
          }}
        >
          <Box
            className="box-default half-width flex-center"
            sx={{
              borderRight: "1px solid black",
              backgroundColor: "#444",
            }}
          >
            {triggered ? (
              <HeaderTag
                text={
                  "Date Launched " +
                  configEntzy.URL_POINTERS.BOT_STYLED_LAUNCHED
                }
                noSpacing={true}
                bgColor="orange"
                color="black"
              />
            ) : (
              <HeaderTag text="Date Status" noSpacing={true} />
            )}
          </Box>
          <Box
            className="box-default half-width flex-center"
            sx={{
              borderLeft: "1px solid black",
            }}
          >
            <Box className="box-inline text-left">
              <Typography variant="subtitle2">
                <FontAwesomeIcon icon={iconUser} color="orange" fixedWidth />
                <span>&nbsp;&nbsp;&nbsp;</span>
                {eventContext.state.event.calendar.triggers.default}
                <span>&nbsp;</span>
                <span>
                  {eventContext.state.event.calendar.triggers.default === 1
                    ? "person"
                    : "people"}
                </span>
                <span>&nbsp;</span>
                <span>to launch</span>
              </Typography>
              <Typography variant="subtitle2">
                <FontAwesomeIcon icon={iconUsers} color="red" fixedWidth />
                <span>&nbsp;&nbsp;&nbsp;</span>
                {eventContext.state.event.calendar.limits.capacity}
                <span>&nbsp;</span>
                <span>person capacity</span>
              </Typography>
              {triggered ? (
                <Fragment>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: configEntzy.APP_SPACING_MD }}
                  >
                    <FontAwesomeIcon
                      icon={iconLaunched}
                      color="darkorange"
                      // transform="rotate--45"
                      fixedWidth
                    />
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <span>Date is Live!</span>
                    {/* <span>&nbsp;&nbsp;&nbsp;</span>
                    <FontAwesomeIcon
                      icon={iconLaunched}
                      color="darkorange"
                      // transform="rotate--45"
                      fixedWidth
                    /> */}
                  </Typography>
                  <Typography variant="subtitle2">
                    <Box
                      component="span"
                      sx={{
                        color: viewDate.isPast ? "white" : "orange",
                        opacity: viewDate.isPast ? "0.5" : "1",
                      }}
                    >
                      {viewDate.isPast > 0 ? (
                        <em>Run date in the past</em>
                      ) : viewDate.progress.remaining.capacity > 0 ? (
                        <em>
                          {viewDate.progress.remaining.nearlyFull
                            ? "ONLY "
                            : ""}
                          {viewDate.progress.remaining.capacity} place
                          {viewDate.progress.remaining.capacity !== 1 &&
                            "s"}{" "}
                          left
                        </em>
                      ) : (
                        <em>Sold Out</em>
                      )}
                    </Box>
                  </Typography>
                </Fragment>
              ) : (
                <Fragment>
                  <Typography variant="subtitle2">
                    <FontAwesomeIcon
                      icon={iconTicketCode}
                      color="orange"
                      fixedWidth
                    />
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <span>{viewDate.progress.count} offers received</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: configEntzy.APP_SPACING_MD }}
                  >
                    <span>
                      {viewDate.progress.trigger.total}% towards target
                    </span>
                  </Typography>
                  <Typography variant="subtitle2">
                    <Box
                      component="span"
                      sx={{
                        color: viewDate.isExpired ? "white" : "orange",
                        opacity: viewDate.isExpired ? "0.5" : "1",
                      }}
                    >
                      {viewDate.isExpired ? (
                        <em>Notice period has past</em>
                      ) : viewDate.isRunnerOffer ? (
                        <em>
                          {viewDate.progress.count > 0 &&
                          eventContext.state.event.data.LaunchPerTicket ? (
                            <span>
                              A ticket type needs to hit{" "}
                              {
                                eventContext.state.event.calendar.triggers
                                  .default
                              }
                            </span>
                          ) : viewDate.progress.count > 0 ? (
                            <span>
                              {viewDate.progress.remaining.trigger} more to
                              launch
                            </span>
                          ) : (
                            <span>
                              {
                                eventContext.state.event.calendar.triggers
                                  .default
                              }{" "}
                              riders needed to launch
                            </span>
                          )}
                        </em>
                      ) : viewDate.isRunnerLaunchable ? (
                        <em>Enough for instant launch</em>
                      ) : (
                        <em>Needs runner buy in</em>
                      )}
                    </Box>
                  </Typography>
                </Fragment>
              )}
            </Box>
          </Box>
        </Box>

        <Box
          className="box-default bg-white-t25 nooverflow"
          sx={{
            height: configEntzy.AVATAR_CONTAINER_SM2X,
            mb: configEntzy.APP_SPACING_MD,
            borderRadius: configEntzy.BORDER_SIZE_XL,
          }}
        >
          <Box
            className="box-default half-width flex-center"
            sx={{
              borderRight: "1px solid black",
              backgroundColor: "#444",
            }}
          >
            <HeaderTag text="Runner Status" noSpacing={true} />
          </Box>
          <Box
            className="box-default half-width flex-center"
            sx={{
              borderLeft: "1px solid black",
            }}
          >
            <Box className="box-inline text-center">
              {viewDate.isRunnerOffer ? (
                <Fragment>
                  <Typography variant="subtitle2">
                    <FontAwesomeIcon
                      icon={iconCheck}
                      color="lightgreen"
                      transform="grow-4"
                      fixedWidth
                    />
                  </Typography>
                  <Typography variant="subtitle2">
                    <span>Run team offered</span>
                  </Typography>
                </Fragment>
              ) : (
                <Fragment>
                  <Typography variant="subtitle2">
                    <FontAwesomeIcon
                      icon={iconPending}
                      color="black"
                      transform="grow-4"
                      fixedWidth
                    />
                  </Typography>
                  <Typography variant="subtitle2">
                    <span>No run team offer yet</span>
                  </Typography>
                </Fragment>
              )}
            </Box>
          </Box>
        </Box>

        <Box
          className="box-default bg-white-t25 nooverflow"
          sx={{
            height: configEntzy.AVATAR_CONTAINER_SM2X,
            mb: configEntzy.APP_SPACING_MD,
            borderRadius: configEntzy.BORDER_SIZE_XL,
          }}
        >
          <Box
            className="box-default half-width flex-center"
            sx={{
              borderRight: "1px solid black",
              backgroundColor: "#444",
            }}
          >
            <HeaderTag text="Rider Status" noSpacing={true} />
          </Box>
          <Box
            className={
              "box-default half-width flex-center" +
              (viewDate.progress.count > 0 ? " action-pointer" : "")
            }
            sx={{
              borderLeft: "1px solid black",
            }}
            onClick={viewDate.progress.count > 0 ? handleShowOffers : null}
          >
            <Box className="box-inline text-center">
              {viewDate.progress.count > 0 ? (
                <Fragment>
                  <Typography variant="subtitle2">
                    <FontAwesomeIcon
                      icon={iconCheck}
                      color={triggered ? "lightgreen" : "orange"}
                      transform="grow-4"
                      fixedWidth
                    />
                  </Typography>
                  <Typography variant="subtitle2">
                    <span>{viewDate.progress.count}</span>
                    <span>&nbsp;</span>
                    <span>rider</span>
                    <span>&nbsp;</span>
                    <span>
                      {viewDate.progress.count === 1 ? "ticket" : "tickets"}
                    </span>
                    <span>&nbsp;</span>
                    <span>{triggered ? "issued" : "offered"}</span>
                  </Typography>
                  <Typography variant="subtitle2">
                    {showOffers ? (
                      <em>Hide offers X</em>
                    ) : (
                      <em style={{ color: "orange" }}>Tap to view offers</em>
                    )}
                  </Typography>
                </Fragment>
              ) : (
                <Fragment>
                  <Typography variant="subtitle2">
                    <FontAwesomeIcon
                      icon={iconPending}
                      color="black"
                      transform="grow-4"
                      fixedWidth
                    />
                  </Typography>
                  <Typography variant="subtitle2">
                    <span>No rider offers yet</span>
                  </Typography>
                </Fragment>
              )}
            </Box>
          </Box>
        </Box>

        <Zoom in={showOffers}>
          <Box
            className="box-default bg-white-t25"
            sx={{
              mb: configEntzy.APP_SPACING_MD,
              borderRadius: configEntzy.BORDER_SIZE_XL,
            }}
            hidden={!showOffers}
          >
            <Box
              className="box-default bg-black-t50"
              sx={{
                p: configEntzy.APP_SPACING_MD,
              }}
            >
              {loading ? (
                <Box className="box-default">
                  <ActionLoader />
                </Box>
              ) : (
                <Box className="box-default">
                  <Box className="box-default">
                    <HeaderTag
                      text="Rider Offers"
                      bgColor="white"
                      color="black"
                      noSpacing={true}
                    />
                  </Box>
                  {eventContext.state.ticks.dates[viewDate.id] ? (
                    <Box className="box-default">
                      {eventContext.state.ticks.dates[viewDate.id].data.map(
                        (tick, index) => {
                          return (
                            <Box
                              key={tick.TickId}
                              className="box-default"
                              sx={{
                                mt: configEntzy.APP_SPACING_MD,
                                // p:
                                //   tick.Quantity === "0"
                                //     ? "0px"
                                //     : configEntzy.APP_SPACING_MD,
                                width: tick.Quantity === "0" ? "0px" : "100%",
                                overflow: "hidden",
                                // borderTop:
                                //   tick.Quantity === "0"
                                //     ? "none"
                                //     : "1px solid black",
                              }}
                            >
                              <Box
                                className="box-default shadow-dark"
                                sx={{
                                  height: configEntzy.AVATAR_CONTAINER_MD,
                                  p: configEntzy.APP_SPACING_MD,
                                  borderRadius: configEntzy.BORDER_SIZE_XL,
                                  opacity: viewDate.isExpired ? "0.5" : "1",
                                }}
                              >
                                <Box
                                  className="box-default half-width flex-center"
                                  sx={{
                                    // mb: configEntzy.APP_SPACING_MD,
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  <Box className="box-inline">
                                    <UserAvatar
                                      user={{ identity: tick.UserId }}
                                      size="sm"
                                      light={true}
                                    />
                                  </Box>
                                </Box>
                                <Box
                                  className="box-default half-width text-left flex-center"
                                  sx={{
                                    pl: configEntzy.APP_SPACING_MD,
                                    borderLeft: "1px solid black",
                                  }}
                                >
                                  <TickingBadge
                                    count={tick.Quantity}
                                    tick={tick}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          );
                        }
                      )}
                    </Box>
                  ) : (
                    <Box
                      className="box-default"
                      sx={{ mt: configEntzy.APP_SPACING_LG }}
                    >
                      {!user.connected && (
                        <ActionAlert
                          severity="info"
                          title="View Rider Offers"
                          message="Connect to view riders and offers in play"
                          onClick={props.drawerConnectToggle}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              )}
              {eventContext.state.ticks.dates[viewDate.id] &&
                eventContext.state.ticks.dates[viewDate.id].nextToken && (
                  <Box
                    className="box-default"
                    sx={{ mt: configEntzy.APP_SPACING_LG }}
                  >
                    <MoreButton
                      variant="contained"
                      size="large"
                      type="button"
                      color="bright"
                      text="More"
                      loading={loadingMore}
                      onClick={() => handlePullTicks(true, false)}
                    />
                  </Box>
                )}
            </Box>
          </Box>
        </Zoom>
      </Container>
    </Box>
  );
}

export default RoomDatesTicking;

// react core
import { useContext, useState } from "react";

// date handling
import dayjs from "dayjs";

// material design
import Box from "@mui/material/Box";
// import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
// import Divider from "@mui/material/Divider";
// import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faText as iconReason } from "@fortawesome/pro-solid-svg-icons";
import { faSync as iconRefresh } from "@fortawesome/pro-solid-svg-icons";
import { faCircleUpLeft as iconClose } from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt as iconDelete } from "@fortawesome/pro-duotone-svg-icons";

// entzy context and services
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import {
  ActionBoxButton,
  BigButton,
} from "components/utils/common/CommonButtons";
import {
  ActionLoader,
  ActionCheck,
} from "components/utils/common/CommonLoaders";
import { jsonTryParse, scrollSnapTop } from "models/Tools";
import { TextTag } from "components/utils/common/CommonTags";

function AdminApprovals(props) {
  const mainContext = useContext(MainContext);

  const user = props.user;
  const adminMenu = props.adminMenu;
  const adminSection = mainContext.state.viewer.trail.controlPage.split(":")[0];
  const adminSectionMenu =
    adminMenu && adminSection
      ? adminMenu.filter((menu) => menu.id === adminSection)[0].subSections
      : [];

  const [approvals, setApprovals] = useState(null);
  const [loading, setLoading] = useState(false);
  const [actionId, setActionId] = useState(null);
  const [actionStatus, setActionStatus] = useState(null);
  const [actionMessage, setActionMessage] = useState(null);
  const [rejectReason, setRejectReason] = useState("");
  const [dialog, setDialog] = useState({
    visible: false,
  });

  const handleConfirmUpdateApproval = async (event, confirm, item, action) => {
    if (confirm === true) {
      setDialog({ visible: false });
      await handleUpdateApproval(item, action);
    } else if (confirm === false) {
      setDialog({ visible: false });
    } else {
      setDialog({
        visible: true,
        title: action.delete
          ? "Confirm Delete Approval"
          : action.reject
          ? "Confirm Reject Approval"
          : "Confirm Action",
        description: "Are you sure you want to perform this action?",
        cancel: (e) => handleConfirmUpdateApproval(e, false, item, action),
        confirm: (e) => handleConfirmUpdateApproval(e, true, item, action),
      });
    }
  };

  const handleAdminMenuSelect = async (params) => {
    const controlPage =
      adminSection + (params.selection ? ":" + params.selection : "");

    mainContext.updateViewerTrail({
      controlPage: controlPage,
    });
    if (params.selection !== null) {
      await handlePullApprovals(params.selection);
    }
  };

  const handlePullApprovals = async (type) => {
    if (user.admin) {
      setLoading(true);
      setActionId(null);
      const response = await mainContext.prepareUserSetting({
        admin: true,
        list: true,
        HashCategory1: "approvals",
        HashCategory2: type,
      });
      setLoading(false);
      if (response.alert) {
        mainContext.updateAlert(response);
      } else {
        setApprovals(response.data.items);
      }
    }
  };

  const handleRefreshApprovals = async () => {
    const subSection = mainContext.state.viewer.trail.controlPage
      .split(":")
      .pop();
    await handlePullApprovals(subSection);
  };

  const handleReasonSelect = (event) => {
    setRejectReason(event.target.value);
  };

  const handleUpdateApproval = async (item, action) => {
    if (user.admin) {
      scrollSnapTop();
      setLoading(true);
      const rejectReasonSuffix = rejectReason ? ":" + rejectReason : "";
      const rejectSettingValue = "rejected" + rejectReasonSuffix;
      const newSettingValue = action.approve
        ? "approved"
        : action.reject
        ? rejectSettingValue
        : action.reset
        ? "pending"
        : undefined;
      const response = await mainContext.prepareUserSetting({
        admin: true,
        update: action.update,
        delete: action.delete,
        SettingId: item.SettingId,
        SettingUserId: item.UserId,
        SettingValue: newSettingValue,
      });
      setLoading(false);
      if (response.alert) {
        mainContext.updateAlert(response);
      } else {
        const updatedApprovals = approvals.filter(
          (approval) => approval.UserId !== item.UserId
        );
        if (action.delete) {
          setActionStatus("success");
          setActionMessage("Approval deleted");
        } else {
          const responseData = jsonTryParse(
            response.data ? response.data : "{}",
            {}
          );
          item.SettingValue = newSettingValue;
          updatedApprovals.unshift(item);
          setActionStatus(responseData.status);
          setActionMessage(responseData.message);
        }
        setActionId(item.UserId);
        setApprovals(updatedApprovals);
      }
    } else {
      mainContext.updateAlert({
        alert: true,
        message: "Admin access required for this action",
      });
    }
  };

  const rejectReasonList = [
    "No Reason",
    "Not enough event details",
    "Unsuitable content",
    "Unsuitable media gallery",
  ];
  const rejectReasonSelectable = rejectReasonList.map((reason, index) => {
    const selectable = {};
    selectable.id = index;
    selectable.name = reason;
    selectable.value = reason;
    selectable.display = reason;
    selectable.icon = iconReason;
    selectable.selected = index === 0 ? true : false;
    selectable.label = reason;
    return selectable;
  });

  return (
    <Box className="box-default">
      {loading ? (
        <Box
          className="box-abs left full-width pop-up"
          sx={{
            pt: configEntzy.APP_SPACING_LG,
          }}
        >
          <ActionLoader />
        </Box>
      ) : (
        <Box className="box-default">
          {adminSection === "approvals" && (
            <Fade in={true} timeout={1000}>
              <Box className="box-default">
                <Box
                  className="box-default"
                  hidden={
                    mainContext.state.viewer.trail.controlPage.split(":")
                      .length > 1
                      ? true
                      : false
                  }
                >
                  {adminSectionMenu.map((menu, index) => (
                    <Box key={menu.id} className="box-default">
                      <BigButton
                        text={menu.id}
                        subText="Tap to view"
                        size="lg"
                        icon={menu.icon}
                        bgColor="dusk.main"
                        color="primary"
                        iconColor="primary"
                        onClick={() =>
                          handleAdminMenuSelect({ selection: menu.id })
                        }
                      />
                    </Box>
                  ))}
                </Box>
                <Box
                  className="box-default"
                  hidden={
                    mainContext.state.viewer.trail.controlPage.split(":")
                      .length > 1
                      ? false
                      : true
                  }
                  sx={{
                    mt: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <Box
                    className="box-default text-left"
                    sx={{
                      mt: configEntzy.APP_SPACING_NMD3X,
                      pt: configEntzy.APP_SPACING_SM,
                      pb: configEntzy.APP_SPACING_MD,
                      pl: configEntzy.APP_SPACING_SM,
                    }}
                  >
                    <Typography variant="h6">
                      <TextTag
                        size="md"
                        rounded={true}
                        bgColor="black"
                        text={
                          <span>
                            <span>&nbsp;</span>
                            <FontAwesomeIcon icon={iconClose} />
                            <span>&nbsp;&nbsp;&nbsp;BACK&nbsp;&nbsp;</span>
                          </span>
                        }
                        onClick={() =>
                          handleAdminMenuSelect({ selection: null })
                        }
                      />
                      <span>&nbsp;&nbsp;</span>
                      <TextTag
                        size="md"
                        rounded={true}
                        bgColor="black"
                        text={
                          <span>
                            <span>&nbsp;</span>
                            <FontAwesomeIcon
                              icon={iconRefresh}
                              className={loading ? "spin" : ""}
                            />
                            <span>&nbsp;&nbsp;&nbsp;REFRESH&nbsp;&nbsp;</span>
                          </span>
                        }
                        onClick={handleRefreshApprovals}
                      />
                    </Typography>
                  </Box>
                  <Box
                    className="box-default bg-white-t25"
                    sx={{
                      borderRadius: configEntzy.BORDER_SIZE_XL,
                    }}
                  >
                    <Box
                      className="box-default"
                      sx={{
                        pt: configEntzy.APP_SPACING_MD2X,
                        pb: configEntzy.APP_SPACING_MD2X,
                        borderBottom: "1px solid #000000",
                      }}
                    >
                      <Typography variant="h6" color="white">
                        Socials
                      </Typography>
                      <Typography variant="subtitle2">
                        Manage the Entzy Socials
                      </Typography>
                    </Box>

                    <Box
                      className="box-default"
                      sx={{
                        p: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      {approvals && approvals.length ? (
                        approvals.map((item, index) => {
                          const string = item.UserId + ":::";
                          const parts = string.split(":");
                          const url =
                            configEntzy.APP_URL +
                            configEntzy.URL_POINTERS.MAIN +
                            parts[0];
                          const social = parts[1];
                          const action = parts[2];
                          return (
                            <Box
                              key={index}
                              className="box-default bg-black-t25"
                              sx={{
                                p: configEntzy.APP_SPACING_MD,
                                borderRadius: configEntzy.BORDER_SIZE_XL,
                                mt: configEntzy.APP_SPACING_SM,
                              }}
                            >
                              <Box
                                className="box-default"
                                sx={{
                                  p: configEntzy.APP_SPACING_SM,
                                }}
                              >
                                <Box className="box-default end-width">
                                  &nbsp;
                                </Box>
                                <Box className="box-default shelf-width">
                                  <Typography variant="subtitle2">
                                    <a
                                      href={url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {url}
                                    </a>
                                  </Typography>
                                </Box>
                                <Box className="box-default end-width text-right">
                                  <Typography
                                    variant="subtitle2"
                                    color="red"
                                    className="action-pointer"
                                  >
                                    <FontAwesomeIcon
                                      icon={iconDelete}
                                      transform="grow-5"
                                      onClick={() =>
                                        handleConfirmUpdateApproval(
                                          null,
                                          null,
                                          item,
                                          {
                                            delete: true,
                                          }
                                        )
                                      }
                                    />
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="box-default">
                                <Typography
                                  variant="h6"
                                  color="white"
                                  className="upper-case"
                                  sx={{ mt: configEntzy.APP_SPACING_MD }}
                                >
                                  {social} {action}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ mt: configEntzy.APP_SPACING_SM }}
                                >
                                  {dayjs(item.LastUpdated).format(
                                    "DD MMM YYYY HH:mm"
                                  )}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ mt: configEntzy.APP_SPACING_SM }}
                                >
                                  <TextTag
                                    size="sm"
                                    rounded={true}
                                    padding={true}
                                    bgColor={
                                      item.SettingValue === "approved"
                                        ? "success.dark"
                                        : item.SettingValue === "pending"
                                        ? "black"
                                        : "danger.dark"
                                    }
                                    color="white"
                                    text={
                                      <span>
                                        &nbsp;{item.SettingValue}&nbsp;
                                      </span>
                                    }
                                  />
                                </Typography>
                              </Box>
                              {actionId === item.UserId && (
                                <Box
                                  className="box-default bg-black-t50"
                                  sx={{
                                    mt: configEntzy.APP_SPACING_MD,
                                    p: configEntzy.APP_SPACING_MD,
                                    borderRadius: configEntzy.BORDER_SIZE_XL,
                                    border: configEntzy.BORDER_DASH_OMD,
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    color="primary"
                                    className="upper-case"
                                    sx={{ mt: configEntzy.APP_SPACING_SM }}
                                  >
                                    Done. Status shown to user: {actionStatus}
                                  </Typography>
                                  {actionMessage &&
                                    actionMessage
                                      .split(".")
                                      .map((line, index) => {
                                        return (
                                          <Typography
                                            key={index}
                                            variant="body2"
                                            color="primary"
                                            sx={{
                                              mt: configEntzy.APP_SPACING_SM,
                                            }}
                                          >
                                            {line}
                                          </Typography>
                                        );
                                      })}
                                </Box>
                              )}
                              <Box
                                className="box-default"
                                sx={{
                                  mt: configEntzy.APP_SPACING_MD,
                                }}
                              >
                                <Box
                                  className="box-default half-width"
                                  sx={{
                                    p: configEntzy.APP_SPACING_SM,
                                  }}
                                >
                                  <ActionBoxButton
                                    text="Approve"
                                    size="small"
                                    color="white"
                                    bgColor="success.main"
                                    onClick={() =>
                                      handleUpdateApproval(item, {
                                        update: true,
                                        approve: true,
                                      })
                                    }
                                  />
                                </Box>
                                <Box
                                  className="box-default half-width"
                                  sx={{
                                    p: configEntzy.APP_SPACING_SM,
                                  }}
                                >
                                  <ActionBoxButton
                                    text="Reject"
                                    size="small"
                                    color="white"
                                    bgColor="danger.main"
                                    onClick={() =>
                                      handleUpdateApproval(item, {
                                        update: true,
                                        reject: true,
                                      })
                                    }
                                  />
                                </Box>
                                <Box
                                  className="box-default"
                                  sx={{
                                    p: configEntzy.APP_SPACING_SM,
                                  }}
                                >
                                  <Box
                                    className="box-default bg-white"
                                    sx={{
                                      mt: configEntzy.APP_SPACING_SM,
                                      p: configEntzy.APP_SPACING_MD,
                                      borderRadius: configEntzy.BORDER_SIZE_LG,
                                    }}
                                  >
                                    <FormControl fullWidth>
                                      <InputLabel
                                        id="reject-reason-label"
                                        htmlFor="reject-reason"
                                        sx={{
                                          backgroundColor: "white",
                                        }}
                                      >
                                        Reject Reason&nbsp;&nbsp;
                                      </InputLabel>
                                      <Select
                                        labelId="reject-reason-label"
                                        id="reject-reason"
                                        value={rejectReason}
                                        label="Age"
                                        onChange={handleReasonSelect}
                                      >
                                        {rejectReasonSelectable.map(
                                          (reason, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={reason.value}
                                                defaultChecked={reason.selected}
                                              >
                                                {reason.display}
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })
                      ) : (
                        <Box
                          className="box-default"
                          sx={{
                            pt: configEntzy.APP_SPACING_XL,
                            pb: configEntzy.APP_SPACING_HL,
                          }}
                        >
                          <Typography variant="subtitle2">
                            Queue is Clear
                          </Typography>
                          <Typography variant="subtitle1">
                            Nothing to approve
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box className="box-default">
                  <ActionCheck
                    dialog={dialog.visible}
                    title={dialog.title}
                    description={dialog.description}
                    cancel={dialog.cancel}
                    confirm={dialog.confirm}
                  />
                </Box>
              </Box>
            </Fade>
          )}
        </Box>
      )}
    </Box>
  );
}

export default AdminApprovals;

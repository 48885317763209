// material design
import Box from "@mui/material/Box";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

// entzy config and models
import configEntzy from "components/config/ConfigEntzy";
import { APP_MENU_TREE } from "models/Structure";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignIn as iconLogin } from "@fortawesome/pro-duotone-svg-icons";
import { faBars as iconMenu } from "@fortawesome/pro-duotone-svg-icons";
import { faTimes as iconClose } from "@fortawesome/pro-duotone-svg-icons";

function MenuBox(props) {
  const menuUser = props.user;
  const menuSelected = props.menuSelected;
  // const menuParentItem = menuSelected.parent
  //   ? props.menuSelected
  //   : APP_MENU_TREE.filter((obj) => obj.id === menuSelected.parentId)[0];
  const menuFullList = APP_MENU_TREE.filter((obj) => !obj.bookend);

  return (
    <Box
      className="box-default"
      sx={{ p: configEntzy.APP_SPACING_MD, color: "white" }}
    >
      <Box className="box-default half-width text-left">
        <Typography variant="h6" noWrap>
          <span
            className="action-pointer"
            onClick={() => props.drawerMenuToggle(false)}
          >
            <FontAwesomeIcon icon={iconMenu} fixedWidth />
            &nbsp;&nbsp;MENU
          </span>
        </Typography>
      </Box>
      <Box className="box-default half-width text-right">
        <Typography variant="h6" noWrap>
          <span
            className="action-pointer"
            onClick={() => props.drawerMenuToggle(false)}
          >
            <FontAwesomeIcon icon={iconClose} transform="grow-4" fixedWidth />
          </span>
        </Typography>
      </Box>

      <Box
        className="box-default"
        sx={{
          textAlign: "left",
          mt: configEntzy.APP_SPACING_MD,
        }}
      >
        <MenuList variant="selectedMenu">
          {menuFullList.map(function (item, index) {
            return (
              <Box
                key={item.id}
                className="box-default"
                hidden={
                  (item.connected && !menuUser.connected) ||
                  (item.admin && !menuUser.admin)
                }
              >
                {item.titleOnly ? (
                  <Box className="box-default">
                    <Box
                      key={item.id}
                      className="box-default content-disabled"
                      sx={{
                        mt: configEntzy.APP_SPACING_LG,
                        pb: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      <ListItemText>
                        <Typography variant="h6">
                          &nbsp;&nbsp;{item.name}
                        </Typography>
                      </ListItemText>
                    </Box>
                    <Divider />
                  </Box>
                ) : (
                  <MenuItem
                    key={item.id}
                    divider={true}
                    sx={{
                      pt: configEntzy.APP_SPACING_MD,
                      pb: configEntzy.APP_SPACING_MD,
                      pl: item.parent
                        ? configEntzy.APP_SPACING_MD
                        : configEntzy.APP_SPACING_LG,
                    }}
                    onClick={() => props.menuSelect(item)}
                    selected={menuSelected && menuSelected.id === item.id}
                  >
                    {/* {!item.parent && (
                      <span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    )} */}
                    {/* {!item.parent &&
                      Array.from(
                        Array(item.nav.split("/").length - 2).keys()
                      ).map(function (n) {
                        return (
                          <span key={"indent" + n}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                        );
                      })} */}
                    <ListItemIcon>
                      <Typography variant="subtitle2">
                        <FontAwesomeIcon icon={item.icon} fixedWidth />
                      </Typography>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="subtitle2">
                        <span className="proper-case">{item.name}</span>
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                )}
              </Box>
            );
          })}
        </MenuList>
      </Box>
      <Box
        className="box-default text-left"
        sx={{
          pt: configEntzy.APP_SPACING_XL,
          pl: configEntzy.APP_SPACING_MD,
        }}
      >
        <Box className="box-default">
          <Typography variant="subtitle1">BACKDROP</Typography>
        </Box>
        <Box className="box-default">
          {Array.from(Array(configEntzy.BACKDROP_COUNT).keys()).map(function (
            n
          ) {
            return (
              <Box
                key={"backdrop" + n}
                className={"box-default action-pointer backdrop drip" + (n + 1)}
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                  mb: configEntzy.APP_SPACING_MD,
                  mr: configEntzy.APP_SPACING_MD,
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  display: "inline-block",
                  boxShadow:
                    props.backdropActive === "backdrop drip" + (n + 1)
                      ? "0 0 0 3px #fff"
                      : "inset 0 0 0 3px #000",
                  overflow: "hidden",
                }}
                onClick={() => {
                  props.setBackdrop(n + 1);
                  props.drawerMenuToggle(false);
                }}
              >
                <Box
                  className="box-default bg-black-t50"
                  sx={{
                    width: "38px",
                    height: "38px",
                    margin: "6px",
                    borderRadius: "50%",
                  }}
                ></Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      {!menuUser.connected && (
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_XL,
            mb: configEntzy.APP_SPACING_XL,
          }}
        >
          <Typography
            variant="subtitle1"
            onClick={() => {
              props.drawerMenuToggle(false);
              props.drawerConnectToggle(true);
            }}
          >
            <em>
              <span
                className="shadow-light rounded action-pointer"
                style={{ padding: "16px" }}
              >
                <span>Connect to access all services</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>
                  <FontAwesomeIcon
                    icon={iconLogin}
                    transform="grow-5"
                    fixedWidth
                  />
                </span>
              </span>
            </em>
          </Typography>
        </Box>
      )}
      <Box
        className="box-default text-left"
        sx={{
          pt: configEntzy.APP_SPACING_XL,
          pl: configEntzy.APP_SPACING_MD,
        }}
      >
        <Typography variant="subtitle1">
          <span
            className="action-pointer"
            onClick={() => {
              props.drawerMenuToggle(false);
              props.navigate("/legal/terms");
            }}
          >
            TERMS
          </span>
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            mt: configEntzy.APP_SPACING_MD,
          }}
        >
          <span
            className="action-pointer"
            onClick={() => {
              props.drawerMenuToggle(false);
              props.navigate("/legal/privacy");
            }}
          >
            PRIVACY
          </span>
        </Typography>
      </Box>
      <Box
        className="box-default text-left"
        sx={{
          pt: configEntzy.APP_SPACING_LG,
          pl: configEntzy.APP_SPACING_MD,
          pr: configEntzy.APP_SPACING_MD,
        }}
      >
        <Typography variant="subtitle1" color="primary">
          &copy; ENTZY {new Date().getFullYear()}
          {/* <span>&nbsp;&nbsp;</span>
          <span
            style={{
              float: "right",
              color: "#ccc",
            }}
          >
            {configEntzy.APP_NAME} {configEntzy.APP_VERSION}
          </span> */}
        </Typography>
      </Box>
    </Box>
  );
}

export default MenuBox;

// <BigButton
//   color="tertiary.light"
//   icon={item.icon}
//   text={item.name}
//   onClick={() => props.menuSelect(item)}
// />

// // react core
// // import { Fragment } from "react";

// // entzy config and components
// import configEntzy from "components/config/ConfigEntzy";

// // material theme
// import mainTheme from "components/main/MainTheme";
// import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// // material design
// import Box from "@mui/material/Box";
// // import Grid from "@mui/material/Grid";
// // import Divider from "@mui/material/Divider";
// // import Paper from "@mui/material/Paper";
// // import LinearProgress from "@mui/material/LinearProgress";

// // fonts and icons
// // import IconButton from "@mui/material/IconButton";
// // import IconMenu from "@mui/icons-material/Menu";
// // import IconClose from "@mui/icons-material/Close";
// // import IconEvent from "@mui/icons-material/Event";
// // import IconProfile from "@mui/icons-material/Person";
// // import IconPayments from "@mui/icons-material/CreditCard";
// // import IconIncome from "@mui/icons-material/AccountBalance";

// // fonts and colors
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import { faCalendarAlt as iconEvents } from "@fortawesome/pro-duotone-svg-icons";
// // import { faBarcodeRead as iconTickets } from "@fortawesome/pro-duotone-svg-icons";
// // import { faUserAlien as iconProfile } from "@fortawesome/pro-duotone-svg-icons";
// // import { faCreditCard as iconPayments } from "@fortawesome/pro-duotone-svg-icons";
// // import { faBank as iconIncome } from "@fortawesome/pro-duotone-svg-icons";
// // import { faCaretSquareRight as iconSubItem } from "@fortawesome/pro-duotone-svg-icons";
// // import { faCaretSquareRight as iconSubItemSelected } from "@fortawesome/pro-duotone-svg-icons";

// // entzy models
// // import { APP_MENU_TREE } from "models/Structure";

// // const renderIcon = (icon) => {
// //   const transform = "grow-6 left-3";
// //   switch (icon) {
// //     case "events":
// //       return <FontAwesomeIcon icon={iconEvents} transform={transform} />;
// //     case "tickets":
// //       return <FontAwesomeIcon icon={iconTickets} transform={transform} />;
// //     case "profile":
// //       return <FontAwesomeIcon icon={iconProfile} transform={transform} />;
// //     case "payments":
// //       return <FontAwesomeIcon icon={iconPayments} transform={transform} />;
// //     case "income":
// //       return <FontAwesomeIcon icon={iconIncome} transform={transform} />;
// //     case "sub-item":
// //       return <FontAwesomeIcon icon={iconSubItem} />;
// //     case "sub-item-selected":
// //       return (
// //         <FontAwesomeIcon
// //           icon={iconSubItemSelected}
// //           style={{ color: "orange" }}
// //         />
// //       );
// //     default:
// //       return <Fragment />;
// //   }
// // };

// function MenuBox(props) {
//   return (
//     <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
//       <Box className="box-default" sx={{ p: configEntzy.APP_SPACING_MD }}></Box>
//       {/* <Box className="box-default" sx={{ p: configEntzy.APP_SPACING_MD }}>
//         <Grid container spacing={2}>
//           <Grid item xs={6} sx={{ textAlign: "left" }}>
//             <IconButton
//               variant="square"
//               aria-label="close"
//               disableRipple={true}
//               onClick={() => props.drawerMenuToggle(false)}
//             >
//               <Grid container direction="row" alignItems="center">
//                 <IconMenu />
//                 &nbsp;&nbsp;MENU
//               </Grid>
//             </IconButton>
//           </Grid>
//           <Grid item xs={6} sx={{ textAlign: "right" }}>
//             <IconButton
//               aria-label="close"
//               onClick={() => props.drawerMenuToggle(false)}
//             >
//               <IconClose />
//             </IconButton>
//           </Grid>
//         </Grid>

//         <Box
//           className="box-default"
//           sx={{ mt: configEntzy.APP_SPACING_LG, textAlign: "left" }}
//         >
//           <MenuList variant="selectedMenu">
//             {APP_MENU_TREE.map((item) => {
//               return (
//                 <Box
//                   key={item.id}
//                   className="box-default"
//                   hidden={item.bookend}
//                 >
//                   <MenuItem
//                     key={item.id}
//                     divider={true}
//                     sx={{
//                       pt: configEntzy.APP_SPACING_MD,
//                       pb: configEntzy.APP_SPACING_MD,
//                     }}
//                     onClick={() => props.menuSelect(item)}
//                     selected={props.menuSelected.id === item.id}
//                   >
//                     <ListItemIcon>{renderIcon(item.id)}</ListItemIcon>
//                     <ListItemText>
//                       <span className="proper-case">&nbsp;&nbsp;{item.id}</span>
//                     </ListItemText>
//                   </MenuItem>
//                   {props.menuSelected.nav.startsWith(item.nav) &&
//                     item.nodes.map((subItem) => {
//                       return (
//                         <MenuItem
//                           key={"sub" + subItem.id}
//                           divider={true}
//                           sx={{
//                             pt: configEntzy.APP_SPACING_MD,
//                             pb: configEntzy.APP_SPACING_MD,
//                             pl: configEntzy.APP_SPACING_LG,
//                           }}
//                           onClick={() => props.menuSelect(subItem)}
//                         >
//                           <ListItemIcon>
//                             {renderIcon(
//                               props.menuSelected.id === subItem.id
//                                 ? "sub-item-selected"
//                                 : "sub-item"
//                             )}
//                           </ListItemIcon>
//                           <ListItemText>
//                             <span className="proper-case">
//                               {subItem.id.replace(/-/g, " ")}
//                             </span>
//                           </ListItemText>
//                         </MenuItem>
//                       );
//                     })}
//                 </Box>
//               );
//             })}
//           </MenuList>
//         </Box>
//       </Box> */}
//     </ThemeProvider>
//   );
// }

// export default MenuBox;
